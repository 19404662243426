import firebase from "firebase/app";
const db = firebase.firestore();
import Axios from 'axios'
export default {
  actions: {
    async getUsers() {
      const snapshot = await db.collection("users").get()
      const docs = snapshot.docs
      const users = docs.map((doc)=> doc.data())
      return users
    },
    async getUser({commit},uid) {
      const snapshot = await db.collection("users").doc(uid).get()
      if(snapshot.exists){
        let data = snapshot.data()
        data.uid = snapshot.id
        return data
      }
      return null
    },
    async putUser({}, item) {
      await Axios.put("v1/admin/users", item);
    },
    async deleteUser({}, item) {
      await Axios.delete(`v1/admin/users/${item.uid}`);
    },
    async postUser({}, item) {
      await Axios.post("v1/admin/users", item);
    },
  },
};
