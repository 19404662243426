<template lang="pug">
v-container.px-8.c-business-order-action
  v-form(v-model="valid" ref="form")
    v-row
      v-col(cols='12')
        v-toolbar(flat)
          .d-flex.align-center
            v-btn(icon @click="$router.push('/corporativo/ordenes')")
              v-icon mdi-chevron-left
            v-toolbar-title.title NOTA DE CRÉDITO
          v-spacer 
          v-btn(color="primary" :loading="loading" @click="emitirNotaCredito" :disabled="!valid") Emitir nota de crédito
        v-divider
    v-overlay(color='white' :value='generalLoading')
      v-progress-circular(color='primary' indeterminate size='64')
    v-row(wrap)
      v-col.py-1(cols="12")
        h4 Información de facturación
    v-row(v-if="item.detalle_nota_credito")
      v-col.py-1.align-center.d-flex(cols="3")      
        v-select(:items="pay_ways"  v-model="item.detalle_nota_credito.formaPago" :rules="[rules.required]" dense outlined hide-details label="Forma de pago")
      v-col.py-1.align-center.d-flex(cols="3")
        v-select(:items="payment_methods"  v-model="item.detalle_nota_credito.metodoPago" :rules="[rules.required]" dense outlined hide-details label="Método de pago")
      v-col.py-1.align-center.d-flex(cols="3")
        v-select(:items="motivos"  v-model="item.detalle_nota_credito.motivo" :rules="[rules.required]" dense outlined hide-details label="Motivo")
      v-col.py-1.align-center.d-flex(cols="3")
        v-text-field(outlined type="text" rounded hide-details dense counter="80" v-model='item.detalle_nota_credito.glosa' label='Glosa')
      
    v-row(wrap)
        v-col.py-1(cols="12")
          h4 Productos
        v-col
          v-simple-table
            template(v-slot:default='')
              thead
                tr
                  th.text-left
                    | Producto
                  th.text-center(style="width:200px")
                    | P. Unitario
                  th.text-center(style="width:100px")
                    | Cantidad ordenada
                  th.text-center(style="width:100px")
                    | Cantidad devuelta
                  th.text-center(style="width:200px")
                    | Cantidad nota de crédito
              tbody
                order-nota-credito-row(v-for='(p,i) in item.products' v-if="item" :item="p" :key='i')
  </template>
  
  <script>
  import DialogConfirm from '../../../components/shared/DialogConfirm.vue'
  import DialogInfo from '../../../components/shared/DialogInfo.vue'
  import OrderNotaCreditoRow from '../../../components/admin/business/OrderNotaCreditoRow.vue'
  import moment from 'moment'
  
  export default {
    components: { DialogInfo, DialogConfirm, OrderNotaCreditoRow },
    data () {
      return {
        payment_methods:[
          {value: 'CONTADO', text: 'Contado'},
          {value: 'CREDITO', text: 'Crédito'}
        ], 
        pay_ways: [
          {value:'DEPOSITO_CUENTA', text: 'Depósito en cuenta'},
          {value:'TRANSFERENCIA_FONDOS', text: 'Transferencia bancaria'},
          {value:'TARJETA_DEBITO', text: 'Tarjeta de débito'},
          {value:'EFECTIVO', text: 'Efectivo'},
          {value:'OTROS_MEDIO_PAGO', text: 'Otros medios de pago'},
        ],
        motivos: [
          {value:'ANULACION_OPERACION',text:'01 Anulación de la operación'},
          {value:'ANULACION_ERROR_RUC',text:'02 Anulación por Error en el Ruc'},
          {value:'CORRECCION_ERROR_DESC RIPCION',text:'03 Corrección por Error en la Descripción'},
          {value:'DESCUENTO_GLOBAL',text:'04 Descuento Global'},
          {value:'DESCUENTO_POR_ITEM',text:'05 Descuento por Ítem'},
          {value:'DEVOLUCION_TOTAL',text:'06 Devolución Total'},
          {value:'DEVOLUCION_POR_ITEM',text:'07 Devolución por Ítem'},
          {value:'BONIFICACION',text:'08 Bonificación'},
          {value:'DISMINUCION_DEL_VALOR',text:'09 Disminución en el Valor'},
          {value:'OTROS_CONCEPTOS',text:'10 Otros Conceptos'},
          {value:'AJUSTES_MONTOS_FECHA_PAGO',text:'13 Ajustes Montos Fecha Pago Cuotas'},
        ],
        loading: false,
        generalLoading: false,
        loadingTable: false,
        businesses: [],
        template: [],
        hubs: [],
        text: '',
        routes: [],
        valid: false, 
        action: '',
        rules: {
          required_with_delivery: 
            v => {
              if(this.item.has_delivery_info){
                return !!v || 'Campo requerido'
              } else {
                return true
              }
            },
          required: v => !!v || 'Ingrese este campo',
          payment_method: [
            v => !!v || 'Ingrese la forma de pago',
          ],
          days_of_credit: [
            v => v > 0 || 'Ingrese la cantidad de días del crédito',
          ],
          
        },
        item:{
          id: null,
          order_number: 0,
          business_order_number: "",
          business: null,
          delivery_cost: 0,
          total_cost: 0,
          payment_method: '',
          products: [],
          way_pay:'',
          status: '',
          declared_at: null,
          tefacturo_response: null,
          created_at: null,
          assigned_at: null,
          reviewed_at: null,
          completed_at: null,
          use_hub_as_glosa: false,
          glosa:'',
          has_delivery_info: false,
          route_id: null,
          hub_id: null,
          peso_bruto: '',
          days_of_credit: 0,
        }
      }
    },
    methods: {
      revalidateForm(){
        this.$refs.form.validate()
      },
      async emitirNotaCredito(){
        this.generalLoading = true
        try {       
          this.item.products.forEach((p)=>{
            p.qty_ordered = Number(p.qty_ordered)
            p.sell_price = Number(p.sell_price)
            p.qty_rejected = Number(p.qty_rejected)
            p.qty_canceled = Number(p.qty_canceled)
          })
          await this.$store.dispatch('postEmitirNotaCredito', this.item)
          this.$router.push('/corporativo/ordenes')
        } catch (error) {
          console.log(error)
        }
        this.generalLoading = false
      },  
      routeTextFunction(value){
        return `${value.carrier.fullname} - ${moment(value.init_time).format('DD/MM HH:mm')}`
      }
    },
    async beforeMount () {
      this.action = this.$route.query.action
      this.generalLoading = true      
      const data = await this.$store.dispatch('getBusinessOrder', this.$route.params.id)
      if(!Boolean(data.detalle_nota_credito)){
        data.detalle_nota_credito = {
          formaPago: '',
          medioPago: '',
          glosa: '',
          motivo: '',
        }
      }
      console.log(data)
      this.item = data
      this.generalLoading = false
    },
    computed: {
    }
  }
  </script>
  
  <style lang="scss">
  .c-business-order-action {
    .v-input--selection-control {
      margin-top: 0 !important;
    }
  }
  </style>
  