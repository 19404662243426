<template lang="pug">
quenco-drawer.drawer-route(ref='actionDrawer' :title='title' @actionPerformed='save' :disableActionButton="!valido || item.status !== 'available'" actionButton='GUARDAR')
  .px-0.px-sm-10(slot='content')
    v-form(v-model='valido' ref='form')
      v-row
        v-col(cols='6')
          h5 ESTADO: {{$decoders.routeStatus(item.status)}}
        v-col.py-0(cols='6')
          v-autocomplete(outlined :disabled="item.status !== 'available'" rounded dense :items="carriers" v-model='item.carrier_id' item-value="id" :rules="[rules.empty]" item-text="fullname" label='Seleccione un transportista')
        v-col.py-0(cols='6')
          v-autocomplete(outlined :disabled="item.status !== 'available'" rounded dense :items="warehouses" v-model='item.init_warehouse' :rules="[rules.empty]" item-value="id" item-text="name" label='Almacén de inicio')
        v-col.py-0(cols='6')
          v-autocomplete(outlined :disabled="item.status !== 'available'" rounded dense :items="warehouses" v-model='item.end_warehouse' :rules="[rules.empty]" item-value="id" item-text="name" label='Almacén de llegada')
        v-col.py-0(cols="6")
          v-text-field(v-model="date" :disabled="item.status !== 'available'" type="date" :rules="[rules.empty]" outlined dense rounded :label="item.status === 'available'?'Día de partida estimado':'Día de partida'")
        v-col.py-0(cols="6")
          v-text-field(v-model="time" :disabled="item.status !== 'available'" type="time" :rules="[rules.empty]" outlined dense rounded :label="item.status === 'available'?'Hora de partida estimada':'Hora de partida'")
        v-col.py-0(cols="12" v-if="item.orders")
          h5 ÓRDENES ASOCIADAS
          v-simple-table
            template(v-slot:default='')
              thead
                tr
                  th.text-left
                    | Información
                  th.text-center
                    | Total pedido
                  th.text-center
                    | Guía
              tbody
                tr(v-for='item in item.orders' :key='item.id')
                  td {{ item.business.business_name }} - {{item.hub.name}}
                  td S/ {{calcTotal(item)}}
                  td.text-center
                    v-btn(small icon v-if="canDownloadGuia(item)" @click="downloadGuia(item)" )
                      v-icon(small) mdi-file

    .red--text {{ errorMessage }}

</template>

<script>
import QuencoDrawer from '../shared/QuencoDrawer.vue'
import moment from 'moment'
export default {
  props:{
    carriers:{
      type: Array,
      default: []
    },
    warehouses:{
      type: Array,
      default: []
    }
  },
  components: { QuencoDrawer },
  computed:{
    title(){
      return this.action === 'create'?'NUEVA RUTA':'EDITAR RUTA'
    }
  },
  methods: {
    revalidate(){
      this.$refs.form.validate()
    },
    canDownloadGuia(order){
      if(!order.guia_tefacturo_response){
        return false
      }
      return Boolean(order.guia_tefacturo_response.ok_response)
    },
    calcTotal(order){
      let sum = 0
      order.products.forEach(p => sum+=(p.sell_price * p.qty_ordered))
      return Number(sum).toFixed(2)
    },
    async downloadGuia(order){
      const response = await this.$store.dispatch('postConsultarPdfTeFacturo', order.guia_tefacturo_response.ok_response)
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      const now = moment().format("DD-MM-YYYY")
      link.setAttribute('download', `${order.business.business_name }_${order.hub.name}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    open (action, item) {
      this.clearForm()
      this.action = action
      this.time = null
      this.date = null
      if(action === 'create') {
        this.item = {
          id: this.$uid(),
          status: 'available',
          carrier_id: null,
          order_ids:[],
          orders: [],
          init_warehouse: null,
          end_warehouse: null,
          init_time: null,
          end_time: null,
        }
      }
      if(action === 'edit'){
        this.item = JSON.parse(JSON.stringify(item))
        console.log(this.item.init_time)
        this.date = moment(this.item.init_time).format('YYYY-MM-DD')
        this.time = moment(this.item.init_time).format('HH:mm')
      }
      
      this.loading = false
      this.errorMessage = ''
      this.$refs.actionDrawer.setVisible(true)
    },
    clearForm () {
      this.item = {
          id: null,
          status: 'available',
          carrier_id: null,
          order_ids:[],
          init_warehouse: null,
          end_warehouse: null,
          init_time: null,
          end_time: null,
        }
      
      if (this.$refs.form){
        this.$refs.form.resetValidation()
      }
    },
    validateEmail (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    async save () {
      this.loading = true
      this.errorMessage = ''
      try {
        // this.item.init_time = this.$moment(``)
        this.item.init_time = moment(`${this.date} ${this.time}`).toDate()
        let response = await this.$store.dispatch(
          'putRoute',
          this.item
        )
        if (response.has_error) {
          this.errorMessage = response.error_message
          this.$refs.actionDrawer.stopLoading()
        } else {
          this.$refs.actionDrawer.setVisible(false)
          this.$refs.actionDrawer.stopLoading()
          this.$emit('done')
        }

        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
        this.errorMessage = error.response.data.error_message
      }
      this.loading = false
    }
  },
  data () {
    let empty = {
        id: null,
        status: 'available',
        carrier_id: null,
        order_ids:[],
        init_warehouse: null,
        end_warehouse: null,
        init_time: null,
        end_time: null,
      }
    return {
      show: false,
      repeatPassword: '',
      valido: false,
      errorMessage: '',
      departmentID: '15',
      provinceID: '1501',
      action: 'create',
      loading: false,
      date:null,
      time: null,
      emptyItem: empty,
      rules: {
        empty: v => !!v || 'Debe ingresar este campo',
        business_name: [
          v => !!v || 'Ingrese el nombre de la empresa',
          v => v.length <= 100 || 'Ingrese máximo 100 caracteres'
        ],
        razon_social: [
          v => !!v || 'Ingrese la razón social',
          v => v.length <= 100 || 'Ingrese máximo 100 caracteres'
        ],
        phone: [
          v => !!v || 'Ingrese su número de teléfono',
          v => v.length <= 9 || 'Ingrese máximo 9 caracteres'
        ],
        email: [
          v =>
            this.validateEmail(v) ||
            'Ingrese un formato de correo electrónico correcto'
        ],
        doc_number: [
          v => !!v || 'Ingrese el RUC',
          v => v.length <= 11 || 'Ingrese máximo 11 caracteres'
        ],
      },
      item: {
        id: null,
        business_name: '',
        phone: '',
        email: '',
        doc_number:'',
        razon_social:''
      }
    }
  }
}
</script>

<style lang="scss">
.drawer-route{
  width: 580px !important;
  .divider{
    border-bottom: 2px dashed #9CD6BF;
    width: 100%;
  }
}
.quenco-card {
  .form-title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #1f3333;
  }
  .v-text-field--rounded {
    border-radius: 12px;
  }
}
</style>
