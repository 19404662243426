<template>
  <v-container fluid class="px-10">
    <v-row wrap>
      <v-col cols="12">
        <v-toolbar flat>
          <v-toolbar-title class="title">Pedidos</v-toolbar-title>
        </v-toolbar>
        <v-divider />
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab
            v-if="hasSectionPermissions('list')"
            ><span class="mr-2">Nuevos pedidos</span>
            <v-chip color="primary" small v-if="receiveds.length > 0">{{
              receiveds.length
            }}</v-chip></v-tab
          >
          <v-tab
            v-if="hasSectionPermissions('list')"
            ><span class="mr-2">En preparación</span>
            <v-chip color="primary" small v-if="accepteds.length > 0">{{
              accepteds.length
            }}</v-chip></v-tab
          >
          <v-tab
            v-if="hasSectionPermissions('list')"
            ><span class="mr-2">En camino</span>
            <v-chip color="primary" small v-if="inDeliveries.length > 0">{{
              inDeliveries.length
            }}</v-chip></v-tab
          >
          <v-tab @click="moveToHistory" v-if="hasSectionPermissions('history')">Historial de pedidos</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="hasSectionPermissions('list')">
            <received-order-card
              :order="o"
              v-for="o in receiveds"
              :key="o.id"
            />
          </v-tab-item>
          <v-tab-item v-if="hasSectionPermissions('list')">
            <div class="d-flex justify-end mb-3">
              <v-btn @click="downloadExcel" color="info">
                <v-icon size="18" class="mr-1">mdi-file-excel</v-icon>
                Descargar excel</v-btn>
            </div>
            <accepted-order-card
              :order="o"
              v-for="o in accepteds"
              :key="o.id"
            />
          </v-tab-item>
          <v-tab-item v-if="hasSectionPermissions('list')">
            <in-delivery-order-card
              :order="o"
              v-for="o in inDeliveries"
              :key="o.id"
            ></in-delivery-order-card>
          </v-tab-item>
          <v-tab-item v-if="hasSectionPermissions('history')">
            <history-card ref="historyCard"/>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AcceptedOrderCard from '../../components/admin/orders/cards/AcceptedOrderCard.vue'
import HistoryCard from '../../components/admin/orders/cards/HistoryCard.vue'
import InDeliveryOrderCard from '../../components/admin/orders/cards/InDeliveryOrderCard.vue'
import ReceivedOrderCard from '../../components/admin/orders/cards/ReceivedOrderCard.vue'
import moment from 'moment'
export default {
  components: { ReceivedOrderCard, AcceptedOrderCard, InDeliveryOrderCard, HistoryCard },
  data () {
    return {
      tab: 0
    }
  },
  computed: {    
    orders () {
      return this.$store.state.orders.orders
    },
    receiveds () {
      return this.orders.filter(o => o.status == 'received')
    },
    accepteds () {
      return this.orders.filter(o => o.status == 'accepted')
    },
    inDeliveries () {
      return this.orders.filter(o => o.status == 'in_delivery')
    }
  },
  methods: {
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('pedidos',permissions)
    },
    moveToHistory(){
      if(this.$refs.historyCard){
        this.$refs.historyCard.loadHistory()
      }      
    },
    async downloadExcel(){
      const response = await this.$store.dispatch('excelAcceptedOrders')
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const now = moment().format("DD-MM-YYYY")
      link.setAttribute('download', `aceptados_hasta_${now}.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  }
}
</script>

<style lang="scss">
.order-card {
  background-color: white;
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #b9c3c2;
  border-radius: 12px;
  .new-order-tag {
    background: #ff4d5c;
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    padding: 0 10px 0 10px;
    .text {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
    }
    .timer {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
    }
  }
  .business-name {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;

    color: #561fdb;
  }
  .customer-name {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #303060;
  }
  .normal-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #262626;
  }
  .link {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;

    color: #303060;
    &:hover {
      cursor: pointer;
    }
  }
  .total-price {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    /* Texto-Oscuro2 */

    color: #303060;
  }
}
</style>
