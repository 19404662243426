<template lang="pug">
v-container.px-10.category-view(fluid='')
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate='' size='64')
  v-row(wrap='')
    v-col(cols='12')
      v-toolbar(flat='')
        v-toolbar-title.title
          span MATERIA PRIMA
      v-divider(style='border-color: #B9C3C2;')
    v-col(cols="12")
      .d-flex
          h3 Valor total de inventario: S/ {{ totalInventoryCost.toFixed(2) }}
    v-col.d-flex.justify-space-between.align-center(cols='12' v-if="hasSectionPermissions('list')")
      div(v-if='!showEmptyCard')
        v-text-field(hide-details='' style='width:450px; display:inline-block;' v-model="search.text" outlined='' label='Buscar producto por nombre')
        // v-select.ml-5(hide-details='' style='width:180px; display:inline-block;' outlined='' v-model='search.active' @change='loadProducts' label='Filtrar por estado' :items='filterItems')
        //v-select.ml-5(hide-details='' style='width:180px; display:inline-block;' outlined='' v-model='search.offer_type' @change='loadProducts' label='Filtrar por promoción' :items='filterOffers')
      v-btn(v-if="!showEmptyCard && hasSectionPermissions('edit')" color='primary' elevation='4' @click='moveToCreateProduct') CREAR PRODUCTO
    v-col(cols='12' v-if="!showEmptyCard && hasSectionPermissions('list')")
      v-data-table(:options.sync='dataTableOptions' :headers='headers' :items='products' :loading='loadingTable' :search="search.text" locale='es-ES' @click:row='clickOnRow' no-data-text='Aún no ha creado un producto' loading-text='Cargando...' :footer-props="{\
      'items-per-page-text': 'Productos por página',\
      'items-per-page-options': [20, 50, 100]\
      }")
        //template(v-slot:[`item.thumbnail_url`]='{ item }')
          v-avatar(tile='')
            img(width='64px' height='64px' :src='item.thumbnail_url')
        template(v-slot:[`item.stock`]='{ item }')
          div {{ item.stock }} {{ item.unit }}
        template(v-slot:[`item.cost_unit`]='{ item }')
          div S/ {{ item.cost_unit.toFixed(2) }}
        template(v-slot:[`item.total_cost`]='{ item }')
          div S/ {{ item.total_cost.toFixed(2) }}
        template(v-slot:[`item.actions`]="{ item }")
          div(v-if="!item.sent")
            v-menu(offset-y)
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon v-bind="attrs" v-on="on")
                  v-icon mdi-dots-vertical
              v-list
                v-list-item(v-if="hasSectionPermissions('edit')" @click="openEdit(item)")
                  v-list-item-title Editar
                v-list-item(v-if="hasSectionPermissions('delete')" @click="openDeleteDialog(item)")
                  v-list-item-title Eliminar
                v-list-item(@click="$router.push(`/materia-prima/${item.sku}/inventario`)")
                  v-list-item-title Kardex

    v-col.d-flex.flex-column.pt-15.align-center(style='height:80vh;' cols='12' v-if="showEmptyCard && hasSectionPermissions('list')")
      img(width='250px' height='200px' :src="require('../../assets/NoDocuments.png')")
      div
        | A&uacute;n no tienes productos creados en la lista, empieza a crearlos
      div de manera simple y r&aacute;pida.
      v-btn.mt-5(color='primary' width='200' v-if="hasSectionPermissions('edit')" @click='moveToCreateProduct') CREAR PRODUCTO
  dialog-info(ref='dialogInfo')
    dialog-confirm(@actionpressed='confirmDelete' ref='dialogConfirm')
</template>

<script>
import DialogConfirm from '../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../components/shared/DialogInfo.vue'
export default {
  components: { DialogInfo, DialogConfirm },
  data () {
    return {
      loading: false,
      generalLoading: false,
      dataTableOptions: {},
      loadingTable: false,
      products: [],
      showEmptyCard: false,
      toDelete: null,
      total_cost: 0,
      totalItems: 0,
      headers: [
        /*{
          text: '',
          align: 'start',
          sortable: false,
          width: '80px',
          value: 'thumbnail_url',
          class: 'primary--text'
        },*/
        {
          text: 'Producto',
          align: 'start',
          width: '250px',
          value: 'name',
          class: 'primary--text'
        },
        {
          text: 'SKU',
          align: 'start',
          value: 'sku',
          class: 'primary--text'
        },
        {
          text: 'Stock actual',
          align: 'start',
          value: 'stock',
          class: 'primary--text'
        },
        {
          text: 'Valor unitario',
          align: 'start',
          value: 'cost_unit',
          class: 'primary--text'
        },
        {
          text: 'Valor Total Stock',
          align: 'start',
          value: 'total_cost',
          class: 'primary--text'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'actions',
          class: 'primary--text'
        }
      ],
      filterItems: [
        { text: 'Todos', value: null },
        { text: 'Activos', value: true },
        { text: 'Inactivos', value: false }
      ],
      filterOffers: [
        { text: 'Todos', value: null },
        { text: 'Temporada', value: "season" },
        { text: 'Ofertas', value: "offer" }
      ],
      search: {
        text: '',
        active: null,
        docsPerPage: 20,
        page: 1,
        categoryID: null,
        offer_type: null
      },
      category: {
        name: '',
        id: '',
        subcategories: []
      }
    }
  },
  methods: {
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('productos',permissions)
    },
    updatePage (value) {
      this.search.page = value
      this.loadProducts()
    },
    updateItems (items) {
      this.search.docsPerPage = items
      this.loadProducts()
    },
    clickOnRow (product) {
      // let categoryID = this.$route.params.categoryID
      this.$router.push({
        path: `/materia-prima/${product.id}`,
        query: { action: this.$enums.ACTIONS.EDIT }
      })
    },
    openEdit (item) {
      this.$router.push({
        path: `/materia-prima/${item.id}`,
        query: { action: this.$enums.ACTIONS.EDIT }
      })
    },
    async confirmDelete () {
      try {
        await this.$store.dispatch('deleteProduct', this.toDelete)
        this.$refs.dialogConfirm.stopLoading()
        this.$refs.dialogConfirm.close()
        await this.loadProducts()
      } catch (error) {
        this.$refs.dialogConfirm.stopLoading()
        this.$refs.dialogConfirm.close()
        this.$refs.dialogInfo.open(
          'Error al eliminar el producto',
          error.response.data.error_message
        )
      }
    },
    openDeleteDialog (item) {
      this.$refs.dialogConfirm.open(
        'Eliminar producto',
        'Si elimina el producto su información se borrará permanentemente del sistema.'
      )
      this.toDelete = item
    },
    async changeState (item) {
      try {
        this.loadingTable = true
        await this.$store.dispatch('changeProductState', {
          id: item.id,
          active: item.active
        })
        this.loadingTable = false
      } catch (error) {
        this.$refs.dialogInfo(
          'Error al actualizar el estado del producto',
          error.response.data.error_message
        )
        this.loadingTable = false
      }
    },
    moveToCreateProduct () {
      this.$router.push({
        path: `/materia-prima/${this.$uid()}`,
        query: { action: this.$enums.ACTIONS.CREATE }
      })
    },
    async loadProducts (firstLoad) {
      if (!this.loadingTable) {
        try {
          this.loadingTable = true
          this.products = await this.$store.dispatch('loadProductsSimple', this.search)
          if(firstLoad){
            if(this.products.length == 0 ){
              this.showEmptyCard = true
            }
          }
          else{
            this.showEmptyCard = false
          }
          this.loadingTable = false
          this.generalLoading = false
        } catch (error) {
          this.$refs.dialogInfo.open(
            'No se pudo listar los productos',
            error.response.data.error_message
          )
          this.loadingTable = false
          this.generalLoading = false
        }
      }
    }
  },
  computed:{
    totalInventoryCost(){
      let cost = 0
      this.products.forEach((i)=> cost += i.total_cost)
      return cost
    },
  },
  async beforeMount () {
    this.generalLoading = true
    await this.loadProducts(true)
    this.generalLoading = false
  }
}
</script>

<style lang="scss">
.category-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>
