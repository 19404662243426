import firebase from "firebase/app";
import Axios from "axios";
import utilities from "./utilities";
import moment from "moment";
import Vue from "vue";
const db = firebase.firestore();
export default {
  state: {
    orders: [],
  },
  mutations: {
    setOrders(state, value) {
      state.orders = value;
    },
  },
  actions: {
    async loadHistory({ commit, rootState }, payload) {
      let result = await Axios.get("v1/admin/orders/history", {
        params: payload,
      });
      return result.data;
    },
    loadOrders({ commit, rootState }) {
      db.collection("orders")
        .where("tracked", "==", true)
        .orderBy("ordered_at", "desc")
        .onSnapshot((snapshot) => {
          let docs = snapshot.docs;
          let orders = [];
          docs.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            orders.push(data)
          });
          console.log(orders)
          commit("setOrders", orders);
        });
    },
    async changeStatus({}, payload) {
      return await utilities.wrapResponseAndGetData(await Axios.put("v1/admin/orders/status", payload));
    },
  },
  getters: {
    filterOrders(state) {
      return (status) => state.orders.filter((order) => order.status == status);
    },
    qty(state) {
      return (status) => {
        let filtered = state.orders.filter((order) => order.status == status);
        if (filtered.length < 10) {
          return `0${filtered.length}`;
        } else {
          return filtered.length;
        }
      };
    },
  },
};
