<template lang="pug">
dialog-base(ref='dialogBase' maxwidth='420px')
  div(slot='content')
    v-menu(v-model='menu' :close-on-content-click='false' transition='scale-transition' offset-y='' max-width='290px' min-width='auto')
      template(v-slot:activator='{ on, attrs }')
        v-text-field.mt-3(outlined='' dense='' v-model='computedDateFormatted' label='Seleccione una fecha de despacho' hide-details='' prepend-inner-icon='mdi-calendar' readonly='' v-bind='attrs' v-on='on')
      v-date-picker(v-model='date' locale='es-es' no-title='' @input='menu = false')
  .d-flex.justify-center.align-center.text-center(style='width:100%;' slot='actions')
    v-btn(:loading='loading' @click='close' color='primary' outlined='' primary='') Salir
    v-btn.ml-10(:loading='loading' @click='actionPress' color='primary' :disabled='!date' primary='') DESCARGAR

</template>

<script>
import DialogBase from '../../shared/DialogBase.vue'
import moment from 'moment'
export default {
  components: { DialogBase },
  data () {
    return {
      show: false,
      title: '',
      content: '',
      loading: false,
      menu: false,
      date: null
    }
  },
  computed:{
    computedDateFormatted () {
      if(!this.date){
        return null
      }
      return this.formatDate(this.date)
    },
  },
  methods: {
    formatDate(date) {
      let _date = date
      // Asegúrate de que 'date' es una instancia de Date
      if (!(_date instanceof Date)) {
        _date = new Date(_date);
        _date.setMinutes(_date.getMinutes() + _date.getTimezoneOffset());
      }

      // Usa Intl.DateTimeFormat para formatear la fecha al estilo peruano
      return new Intl.DateTimeFormat('es-PE').format(_date);
    },

    open () {
      this.loading = false
      this.$refs.dialogBase.open('Descargar Órdenes de compra', null)
    },
    close () {
      this.$refs.dialogBase.close()
    },
    async actionPress(){
      this.loading = true
      const response = await this.$store.dispatch('excelBusinessOrdersByDispatchDate', this.date)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const now = moment(this.date).format("DD-MM-YYYY")
      link.setAttribute('download', `ordenes_compra_${now}.xlsx`); //or any other extension
      link.click();
      // this.$emit('actionPressed')
      this.loading = false
    },
    stopLoading(){
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.dialog-base-card {
    border-radius: 12px !important;
  .v-card__title {
    font-size: 24px !important;
    line-height: 36px;
    color: #1f3333;
    display: flex;
    justify-content: center;
    padding-top: 40px !important;
  }
  .content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1f3333 !important;
  }
  .v-card__actions {
    padding-bottom: 40px !important;
  }
}
</style>
