<template lang="pug">
v-container
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title Corporativo
      v-divider
    v-col(cols='12')
      v-tabs(v-model="tab")
        v-tab(v-if="hasSectionPermissions('list')" @click="$router.push('/corporativo/empresas')")
          span.mr-2 Empresas
        v-tab(v-if="hasSectionPermissions('list')" @click="$router.push('/corporativo/plantillas')")
          span.mr-2 Plantilla
        v-tab(v-if="hasSectionPermissions('list')" @click="$router.push('/corporativo/hubs')")
          span.mr-2 Hubs
        v-tab(v-if="hasSectionPermissions('list')" @click="$router.push('/corporativo/almacenes')")
          span.mr-2 Almacenes
        //v-tab(v-if="hasSectionPermissions('list')" @click="$router.push('/corporativo/rutas')")
          span.mr-2 Rutas
    v-col(cols="12")
      router-view

</template>

<script>
export default {
  methods:{
    hasSectionPermissions(...permissions){
      return true // this.$store.getters.hasSectionPermissions('corporativo',permissions)
    },
  },
  data(){
    return {
      tab: 0
    }
  }
}
</script>

<style>

</style>