import Axios from "axios";
import utilities from "./utilities";
import moment from "moment";

export default {
  state: {
    currentPickingData: null
  },
  mutations: {
    setCurrentPickingData(state, data){
      state.currentPickingData = data
    }
  },
  actions: {
    async getPickingSummary({commit}, date) {
      let { data } = await Axios.get(`v1/admin/picking-summary/${date}`)
      commit('setCurrentPickingData', data)
      return data
    },
    async changePickingStatus({}, payload) {
      return await Axios.patch('v1/admin/picking-summary', payload)
    },
    async getBusinessOrderOfPicking({}, date) {
      return await utilities.wrapResponseAndGetData(
        await Axios.get(`v1/admin/picking-summary/${date}/business-orders`)
      );
    },
    async postPickingSummary({}, data) {
      return await Axios.post(`v1/admin/picking-summary`, data);
    },
    async puttPickingSummary({}, data) {
      return await Axios.put(`v1/admin/picking-summary`, data);
    },
    async patchChangePicker({}, payload){
      return await Axios.patch('v1/admin/business-orders/change-picker', payload)
    }
  },
  getters: {},
};
