import Vue from "vue";
import moment from "moment";

const __shippingTime = (shippingInfo) => {
  let date = moment(shippingInfo.delivery_date);
  return date.format("ddd DD MMMM");
};

const __stringToTime = (dateString) => {
  let date = moment(dateString);
  return date.format("DD/MM/YYYY HH:mm");
};

const __minutesToTime = (n) => {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  rhours = rhours < 10 ? '0'+rhours : rhours
  rminutes = rminutes < 10 ? '0'+rminutes : rminutes
  return rhours + ":" + rminutes;
};

const __normalizeKeyValue = (key) =>{
  if(key == undefined){
    return key
  }
  return key.split("_").join(" ").toUpperCase()
}
Vue.use({
  install(Vue) {
    Vue.filter("__shippingTime", __shippingTime);
    Vue.filter("__minutesToTime", __minutesToTime);
    Vue.filter("__normalizeKeyValue", __normalizeKeyValue)
    Vue.filter("__stringToTime", __stringToTime)
  },
});
