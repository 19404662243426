<template>
  <v-container fluid class="px-10 customer-view">
    <v-overlay color="white" :value="generalLoading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row wrap>
      <v-col cols="12">
        <v-toolbar flat>
          <v-toolbar-title class="title">
            <span class="ml-4">CLIENTES</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-divider style="border-color: #B9C3C2;" class="" />
      </v-col>
      <v-col v-if="hasSectionPermissions('list')" cols="12" class="d-flex justify-space-between align-center pl-12">
        <div>
          <v-text-field
            hide-details
            style="width:450px; display:inline-block;"
            v-debounce:200ms="rebote"
            outlined
            label="Buscar cliente por nombre, RUC, Nombre del negocio"
          ></v-text-field>
          <v-select
            hide-details
            style="width:180px; display:inline-block;"
            class="ml-5"
            outlined
            v-model="search.business_type"
            @change="loadCustomers"
            label="Filtrar por estado"
            :items="businessTypes"
          ></v-select>
        </div>
      </v-col>
      <v-col cols="12" v-if="hasSectionPermissions('list')">
        <v-data-table
          :options.sync="dataTableOptions"
          :headers="headers"
          :server-items-length="totalItems"
          :items="customers"
          :loading="loadingTable"
          @update:page="updatePage"
          @update:items-per-page="updateItems"
          locale="es-ES"
          no-data-text="Aún no hay clientes registrados"
          loading-text="Cargando..."
          :footer-props="{
            'items-per-page-text': 'Clientes por página',
            'items-per-page-options': [20, 50, 100]
          }"
        >
          <template v-slot:[`item.contact_name`]="{ item }">
            <span class="font-weight-black">{{item.contact_name}}</span>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            {{item.email}}
          </template>
          <template v-slot:[`item.phone`]="{ item }">
              
              {{item.phone}}
          </template>
          <template v-slot:[`item.business_name`]="{ item }">
            <div>{{ item.business_name }}</div>
          </template>
          <template v-slot:[`item.doc_number`]="{ item }">
            <div>{{ item.doc_number }}</div>
          </template>
          <template v-slot:[`item.business_type`]="{ item }">
            <div>{{ item.business_type }}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="!item.sent">
              <v-btn v-if="hasSectionPermissions('edit')" icon @click.stop="openEdit(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn v-if="hasSectionPermissions('delete')" icon @click.stop="openDeleteDialog(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <dialog-info ref="dialogInfo" />
    <dialog-confirm @actionPressed="confirmDelete" ref="dialogConfirm" />
    <edit-customer-drawer ref="editCustomer" @done="loadCustomers"/>
  </v-container>
</template>

<script>
import EditCustomerDrawer from '../../components/admin/customers/EditCustomerDrawer.vue'
import DialogConfirm from '../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../components/shared/DialogInfo.vue'
export default {
  components: { DialogInfo, DialogConfirm, EditCustomerDrawer },
  data () {
    return {
      loading: false,
      generalLoading: false,
      dataTableOptions: {},
      loadingTable: false,
      customers: [],
      toDelete: null,
      totalItems: 0,
      businessTypes: [
        { text: "Todos", value: null},
        { text: 'Restaurante', value: 'restaurante' },
        { text: 'Hotel', value: 'hotel' },
        { text: 'Minimarket', value: 'minimarket' },
        { text: 'Bodega', value: 'bodega' },
        { text: 'Otros', value: 'otros' }
      ],
      headers: [
        {
          text: 'Nombres y apellidos',
          align: 'start',
          sortable: false,
          width: '250px',
          value: 'contact_name',
          class: 'primary--text'
        },
        {
          text: 'Correo de contacto',
          align: 'start',
          sortable: false,
          value: 'email',
          class: 'primary--text'
        },
        {
          text: 'Teléfono',
          align: 'start',
          sortable: false,
          value: 'phone',
          class: 'primary--text'
        },
        {
          text: 'Nombre del negocio',
          align: 'start',
          sortable: false,
          value: 'business_name',
          class: 'primary--text'
        },
        {
          text: 'RUC/DNI',
          align: 'start',
          sortable: false,
          value: 'doc_number',
          class: 'primary--text'
        },
        {
          text: 'Tipo de negocio',
          align: 'start',
          sortable: false,
          value: 'business_type',
          class: 'primary--text'
        },    {
          text: '',
          align: 'start',
          sortable: false,
          value: 'actions',
          class: 'primary--text'
        }
      ],
      search: {
        text: '',
        docsPerPage: 20,
        page: 1,
        business_type: null,
      }
    }
  },
  methods: {
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('clientes',permissions)
    },
    updatePage (value) {
      this.search.page = value
      this.loadCustomers()
    },
    updateItems (items) {
      this.search.docsPerPage = items
      this.loadCustomers()
    },
    openEdit (item) {
      this.$refs.editCustomer.open(item)
        /*
      let categoryID = this.$route.params.categoryID
      this.$router.push({
        path: `/categorias/${categoryID}/products`,
        query: { id: item.id, action: this.$enums.ACTIONS.EDIT }
      })*/
    },
    async confirmDelete () {
      try {
        await this.$store.dispatch('deleteCustomer', this.toDelete.uid)
        this.$refs.dialogConfirm.stopLoading()
        this.$refs.dialogConfirm.close()
        await this.loadCustomers()
      } catch (error) {
        this.$refs.dialogConfirm.stopLoading()
        this.$refs.dialogConfirm.close()
        this.$refs.dialogInfo.open(
          'Error al eliminar el cliente',
          error.response.data.error_message
        )
      }
    },
    openDeleteDialog (item) {
      this.$refs.dialogConfirm.open(
        'Eliminar cliente',
        'Si elimina el cliente su información se borrará permanentemente del sistema.'
      )
      this.toDelete = item
    },
    async rebote (text) {
      this.search.text = text
      await this.loadCustomers()
    },
    async loadCustomers (firstLoad) {
      if (!this.loadingTable) {
        this.loadingTable = true
        try {
          this.loadingTable = true
          let result = await this.$store.dispatch('listCustomers', this.search)
          this.totalItems = result.total_items    
          this.customers = result.items
          this.loadingTable = false
          this.generalLoading = false
        } catch (error) {
          this.$refs.dialogInfo.open(
            'No se pudo listar los clientes',
            error.response.data.error_message
          )
          this.loadingTable = false
          this.generalLoading = false
        }
      }
    }
  },
  async beforeMount () {
    this.generalLoading = true
    await this.loadCustomers(true)
    this.generalLoading = false
  }
}
</script>

<style lang="scss">
.customer-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>
