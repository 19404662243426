import utilities from './utilities'
import Axios from 'axios'
export default {
    actions:{
        async updateCustomer({},payload){
            return await utilities.wrapResponseAndGetData(await Axios.put("v1/admin/customers",payload))
        },
        async listCustomers({},payload){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/customers`,{params:payload}))
        },
        async deleteCustomer({},customerUID){
            await Axios.delete(`v1/admin/customers/${customerUID}`)
        }
    }
}