<template>
  <div class="day-column">
      <div class="dayname mt-5 mb-4 py-0">{{ weekdayName }}</div>
      <range-time-input :rangeTime="rangeTime" @deleteRange="removeRange(index)" v-for="(rangeTime,index) in day.ranges" :key="index" />
      <v-btn @click="addNewRange" block elevation="0" color="white" class="btn-add">
        <v-icon color="green">mdi-plus</v-icon>
      </v-btn>
  </div>
</template>

<script>
import RangeTimeInput from './RageTimeInput.vue'
export default {
  components:{RangeTimeInput},
  props: {
    day: {
      type: Object,
      required: true
    }
  },
  methods: {
    removeRange(index){
      this.day.ranges.splice(index,1)
    },
    addNewRange(){
      this.day.ranges.push({start_minutes: 0,end_minutes: 0})
    }
  },
  computed: {
    weekdayName () {
      switch (this.day.weekday) {
        case 0:
          return 'Domingo'
        case 1:
          return 'Lunes'
        case 2:
          return 'Martes'
        case 3:
          return 'Miércoles'
        case 4:
          return 'Jueves'
        case 5:
          return 'Viernes'
        case 6:
          return 'Sábado'
        default:
          ''
      }
    }
  }
}
</script>

<style lang="scss">
.day-column{
  width: 14.2%;
  border-right: 1px dashed #9CD6BF;
  padding: 0 10px 30px 10px;
  .btn-add{
    border-radius: 4px;
  }
  .dayname {
    color: #262626;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
  }
  
  
}

</style>
