import Axios from "axios";
import utilities from "./utilities";
export default {
  actions: {
    async listSuppliers({}, search) {
      return utilities.wrapResponseAndGetData(
        await Axios.get("v1/admin/suppliers", {
          params: search,
        })
      );
    },
    async getSupplier({}, id) {
      return utilities.wrapResponseAndGetData(
        await Axios.get(`v1/admin/suppliers/${id}`)
      );
    },
    async putSupplier({}, supplier) {
      await Axios.put("v1/admin/suppliers", supplier);
    },
    async removeSupplier({}, supplier) {
      await Axios.delete(`v1/admin/suppliers/${supplier.id}`);
    },
  },
};
