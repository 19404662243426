import utilities from './utilities'
import Axios from 'axios'
export default {
    actions:{
        async putWarehouse({},payload){
            return await utilities.wrapResponseAndGetData(await Axios.put("v1/admin/warehouses",payload))
        },
        async listWarehouses({}){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/warehouses`))
        },
    }
}