<template lang="pug">
v-card.card-category
  v-img(height='225' :src='category.photo.photo_url')
    v-menu(offset-y='' v-if="hasSectionPermissions('edit') || hasSectionPermissions('delete')")
      template(v-slot:activator='{ on, attrs }')
        v-btn(elevation='0' fab='' small='' v-bind='attrs' v-on='on' style='position:absolute;right:10px; top:10px;')
          v-icon(color='#1F3333') mdi-dots-vertical
      v-list
        v-list-item(v-if="hasSectionPermissions('edit')" @click="$emit('editCategory',category)")
          v-list-item-title Editar categor&iacute;a
        v-list-item(v-if="hasSectionPermissions('delete')" @click='deleteCategory')
          v-list-item-title Eliminar categor&iacute;a
  v-card-title.pb-0 {{ category.name }}
  v-card-text
    .subcategories-text.mt-0(style='z-index:9999 !important;' @click.stop='showSubcategories')
      | {{ category.subcategories.length }} SUBCATEGOR&Iacute;AS
    .products-text.mt-5
      | Total de productos {{ category.num_products }}
  //.footer-card.text-center(v-if='category.num_products_roos > 0')
    .helper-class
      .background
      .inner-text
        | Productos 
        b sin stock {{ category.num_products_roos }}
        img(:src="require('../../../assets/ic-warning.svg')")
  dialog-info(ref='dialogInfo')
    dialog-confirm(@actionpressed='confirmDelete' ref='dialogConfirm')

</template>

<script>
import DialogConfirm from '../../shared/DialogConfirm.vue'
import DialogInfo from '../../shared/DialogInfo.vue'
export default {
  components: { DialogInfo, DialogConfirm },
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  methods:{
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('categorias',permissions)
    },
      showSubcategories(evt){
          this.$emit('showSubcategories', this.category)
      },
      goToCategory(evt){
        if(this.$store.getters.hasSectionPermissions('productos', ['list'])){
          this.$router.push(`/categorias/${this.category.id}`)
        }
      },
      async deleteCategory(){
          if(this.category.num_products>0){
              this.$refs.dialogInfo.open('',"No puede eliminar esta categoría porque tiene productos creados en ella. Primero debe eliminar los productos para eliminar la categoría")
          }
          else{
              this.$refs.dialogConfirm.open('Eliminar categoría','¿Está seguro que desea eliminar esta categoría?')
          }
      },
      async confirmDelete(){
          try {
              await this.$store.dispatch('deleteCategory',this.category.id)
              this.$refs.dialogConfirm.close()
              this.$emit('forceReload')
          } catch (error) {
              this.$refs.dialogConfirm.close()
              this.$refs.dialogInfo.open('',error.response.data.error_message)
          }

      }
  }
}
</script>

<style lang="scss">
.card-category {
  box-shadow: 0px 4px 12px rgba(185, 195, 194, 0.5);
  border-radius: 20px !important;
  height: 400px;
  position: relative;

  .footer-card {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    .helper-class {
      position: relative;
      .background {
        width: 100%;
        position: absolute;
        background: #f2af02;
        opacity: 0.1;
        top: 0;
        left: 0;
        height: 40px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      .inner-text {
        font-size: 14px;
        line-height: 18px;
        color: #1f3333;
        padding-top: 12px;
      }
    }
  }
  .v-card__title {
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    /* text-base */

    color: #1f3333;
    font-weight: normal;
  }
  .subcategories-text {
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 1px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #561fdb;
    &:hover {
      cursor: pointer;
    }
  }
  .products-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #818685;
  }
}
</style>
