<template lang="pug">
quenco-drawer.drawer-customer(ref='actionDrawer' :title='title' @actionPerformed='save' :disableActionButton='!valido' actionButton='GUARDAR')
  .px-0.px-sm-10(slot='content')
    v-form(v-model='valido' ref='form')
      v-row.mt-5
        v-col.py-0(cols='12')
          v-text-field(outlined rounded :rules='[$rules.required]' v-model='item.first_name' label='Nombre')
        v-col.py-0(cols='12')
          v-text-field(outlined rounded v-model='item.last_name' :rules='[$rules.required]' label='Apellidos')
        v-col.py-0(cols='12')
          v-select(outlined rounded :rules='[$rules.required]' v-model='item.auth_type' :items="$enums.AUTH_TYPE_COMBO" label='Tipo de ingreso')
        v-col.py-0(cols='12')
          v-text-field(v-if="item.auth_type === 'email'" outlined rounded :rules='[$rules.email]' v-model='item.email' type='email' label='Correo electrónico')
          v-text-field(v-if="item.auth_type === 'username'" outlined rounded :rules='[$rules.required]' v-model='item.username' type='email' label='Nombre de usuario')
        v-col.py-0(cols='12' v-if="action === 'create'")
          v-checkbox.mt-0(outlined rounded v-model='item.force_reset_password' label='Forzar nueva contraseña al ingresar')
        v-col.py-0(cols='12' v-if="action === 'create'")
          v-text-field(outlined rounded :rules='[$rules.required]' v-model='item.pwd' label='Contraseña')       
    .red--text {{ errorMessage }}

</template>

<script>
import QuencoDrawer from "../shared/QuencoDrawer.vue";
export default {
  components: { QuencoDrawer },
  computed: {
    title() {
      return this.action === "create" ? "NUEVO USUARIO" : "EDITAR USUARIO";
    },
  },
  methods: {
    revalidate() {
      this.$refs.form.validate();
    },
    open(action, item) {
      this.clearForm();
      this.action = action;
      if (action === "create") {
        this.item = {
          id: this.$uid(),
          first_name: "",
          last_name: "",
          username: "",
          roles: [],
          disabled: false,
          super_admin: false,
          force_reset_password: false,
          carrier_id: null,
          permissions: [],
          email: "",
          auth_type: "",
          businesses: [],
        };
      }
      if (action === "edit") {
        this.item = JSON.parse(JSON.stringify(item));
      }

      this.loading = false;
      this.errorMessage = "";
      this.$refs.actionDrawer.setVisible(true);
    },
    clearForm() {
      this.item = {
        id: null,
        first_name: "",
        last_name: "",
        username: "",
        roles: [],
        disabled: false,
        super_admin: false,
        force_reset_password: false,
        carrier_id: null,
        permissions: [],
        email: "",
        auth_type: "",
        businesses: [],
      };

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    async save() {
      this.loading = true;
      this.errorMessage = "";
      try {
        let response = null;
        if(!this.item.roles.includes('picker')){
          this.item.roles.push('picker')
        }
        if (this.action === "create") {
          response = await this.$store.dispatch("postAuthUser", this.item);
        }
        if (this.action === "edit") {
          response = await this.$store.dispatch("putAuthUser", this.item);
        }
        if (response.has_error) {
          this.errorMessage = response.error_message;
          this.$refs.actionDrawer.stopLoading();
        } else {
          this.$refs.actionDrawer.setVisible(false);
          this.$refs.actionDrawer.stopLoading();
          this.$emit("done");
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.errorMessage = error.response.data.error_message;
      }
      this.loading = false;
    },
  },
  data() {
    let empty = {
      id: null,
      first_name: "",
      last_name: "",
      username: "",
      roles: [],
      disabled: false,
      super_admin: false,
      force_reset_password: false,
      carrier_id: null,
      permissions: [],
      email: "",
      auth_type: "",
      businesses: [],
    };
    return {
      show: false,
      hubs: [],
      repeatPassword: "",
      valido: false,
      errorMessage: "",
      departmentID: "15",
      provinceID: "1501",
      action: "create",
      loading: false,
      emptyItem: empty,
      paymenMethods: ["CONTADO", "CREDITO"],
      rules: {
        address: [(v) => !!v || "Ingrese dirección de la empresa"],
        business_name: [
          (v) => !!v || "Ingrese el nombre de la empresa",
          (v) => v.length <= 100 || "Ingrese máximo 100 caracteres",
        ],
        razon_social: [
          (v) => !!v || "Ingrese la razón social",
          (v) => v.length <= 100 || "Ingrese máximo 100 caracteres",
        ],
        phone: [
          (v) => !!v || "Ingrese su número de teléfono",
          (v) => v.length <= 9 || "Ingrese máximo 9 caracteres",
        ],
        email: [
          (v) =>
            this.validateEmail(v) ||
            "Ingrese un formato de correo electrónico correcto",
        ],
        doc_number: [
          (v) => !!v || "Ingrese el RUC",
          (v) => v.length <= 11 || "Ingrese máximo 11 caracteres",
        ],
        payment_method: [(v) => !!v || "Ingrese la forma de pago"],
        days_of_credit: [
          (v) => v > 0 || "Ingrese la cantidad de días del crédito",
        ],
      },
      item: {
        id: null,
        first_name: "",
        last_name: "",
        username: "",
        roles: [],
        disabled: false,
        super_admin: false,
        force_reset_password: false,
        carrier_id: null,
        permissions: [],
        email: "",
        auth_type: "",
        businesses: [],
      },
    };
  },
};
</script>

<style lang="scss">
.drawer-customer {
  width: 360px !important;
  .divider {
    border-bottom: 2px dashed #9cd6bf;
    width: 100%;
  }
}
.quenco-card {
  .form-title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #1f3333;
  }
  .v-text-field--rounded {
    border-radius: 12px;
  }
}
</style>
