import firebase from "firebase/app";
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
let firebaseConfig = {
  apiKey: "AIzaSyBa_Km_fEmTghTPNYZ2MfZ5RURwoNg0BfE",
  authDomain: "quenco-peru.firebaseapp.com",
  projectId: "quenco-peru",
  storageBucket: "quenco-peru.appspot.com",
  messagingSenderId: "109128761247",
  appId: "1:109128761247:web:8d0b39a6d8d74264f847aa",
  measurementId: "G-NQH2JBDMZ0"
};


if (process.env.NODE_ENV === "production") {    
  firebaseConfig = {
    apiKey: "AIzaSyBa_Km_fEmTghTPNYZ2MfZ5RURwoNg0BfE",
    authDomain: "quenco-peru.firebaseapp.com",
    projectId: "quenco-peru",
    storageBucket: "quenco-peru.appspot.com",
    messagingSenderId: "109128761247",
    appId: "1:109128761247:web:8d0b39a6d8d74264f847aa",
    measurementId: "G-NQH2JBDMZ0"
  };
}

firebase.initializeApp(firebaseConfig)