import firebase from "firebase/app";
import Axios from "axios";
import utilities from "./utilities";
import moment from "moment";
import Vue from "vue";
const db = firebase.firestore();
export default {
  state: {
    purchases: [],
    request: {
      buyer_id: null,
      status: null,
      date: null,
      docsPerPage: 1000,
      page: 1,
    }
  },
  mutations: {
    setPurchases(state, value) {
      state.purchases = value;
    },
  },
  actions: {
    async createPurchase({ commit, rootState, state }, payload) {
      let result = await Axios.post("v1/admin/purchases", payload);
      
      commit("setPurchases", result.data);
      return result.data;
    },
    async loadPurchases({ commit, rootState, state }, payload) {
      let result = await Axios.get("v1/admin/purchases", {
        params: state.request,
      });
      return result.data;
    },
    async findPurchaseByID({ commit, rootState, state }, id) {
      let result = await Axios.get(`v1/admin/purchases/${id}`);
      return result.data;
    },
    async updatePurchase({ commit, rootState, state }, payload) {
      let result = await Axios.put("v1/admin/purchases", payload);
      return result.data;
    },
    async upsertPurchaseItem({ commit, rootState, state }, {purchaseId, payload}) {
      let result = await Axios.put(`v1/admin/purchases/${purchaseId}/items`, payload);
      return result.data;
    },
    async deletePurchaseItem({ commit, rootState, state }, {purchaseId, purchaseItemId}) {
      let result = await Axios.delete(`v1/admin/purchases/${purchaseId}/items/${purchaseItemId}`);
      return result.data;
    },
    async upsertPurchaseExtraCost({ commit, rootState, state }, {purchaseId, payload}) {
      let result = await Axios.put(`v1/admin/purchases/${purchaseId}/extra-costs`, payload);
      return result.data;
    },
    async deletePurchaseExtraCost({ commit, rootState, state }, {purchaseId, extraCostId}) {
      let result = await Axios.delete(`v1/admin/purchases/${purchaseId}/extra-costs/${extraCostId}`);
      return result.data;
    },
    async changePurchaseStatus({}, { purchaseId , status}) {
      return await Axios.patch(`v1/admin/purchases/${purchaseId}/status/${status}`);
    },
    async changePurchaseDates({}, { purchaseId , payload}) {
      return await Axios.patch(`v1/admin/purchases/${purchaseId}/dates`, payload);
    },
    async deletePurchase({}, { purchaseId }) {
      return await Axios.delete(`v1/admin/purchases/${purchaseId}`);
    },
    async purchaseKpisSalesSummary({}, request ) {
      return utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/purchases/kpis/sales-summary`, {
        params: request
      }))
    },
    async purchaseKpisUnitCostGrowth({}, request ) {
      return utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/purchases/kpis/unit-cost-growth`, {
        params: request
      }))
    },
    async purchaseKpisSalesBySku({}, request ) {
      return utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/purchases/kpis/sales-by-sku`, {
        params: request
      }))
    }
  },
};
