import utilities from './utilities'
import Axios from 'axios'
export default {
    actions:{
        async createCategory({},payload){
            let category = payload.category
            let newImageFile = payload.newImageFile
            if(newImageFile){
                let dataPhoto = await utilities.putFile(newImageFile)
                category.photo = dataPhoto
            }
            await Axios.post("v1/admin/categories",category)
        },
        async updateCategory({},payload){
            let category = payload.category
            let newImageFile = payload.newImageFile
            if(newImageFile){
                let dataPhoto = await utilities.putFile(newImageFile)
                category.photo = dataPhoto
            }
            await Axios.put("v1/admin/categories",category)
        },
        async findCategory({},categoryID){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/categories/${categoryID}`))
        },
        async deleteCategory({},categoryID){
            await Axios.delete(`v1/admin/categories/${categoryID}`)
        },
        async listCategories({}){
            return await utilities.wrapResponseAndGetData(await Axios.get('v1/admin/categories'))
        },
        async checkSubcategoryDelete({},subcategoryID){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/categories/subcategories/check-delete/${subcategoryID}`))
        }
    }
}