<template lang="pug">
v-container.px-8
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title Rutas
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row(wrap)
    v-col.d-flex.justify-space-between.align-center(cols='12')
      .row.align-center
        //v-text-field(hide-details style='width:450px; display:inline-block;' v-model="text" outlined label='Buscar empresa...')
        v-spacer 
        v-btn(color="primary" @click="$refs.actionDrawer.open('create')") Nueva Ruta
    v-col(cols='12')
      v-data-table(:headers='headers' :options.sync="dataTableOptions" :items='items' :server-items-length="totalItems" :loading='loadingTable' locale='es-ES' no-data-text='Aún no hay rutas registradas' :footer-props="{itemsPerPageOptions: [20, 50], 'items-per-page-text': 'Rutas por página: '}" loading-text="'Cargando...'")
        template(v-slot:[`item.init_time`]='{ item }')
          span {{formatDate(item.init_time)}}
        template(v-slot:[`item.carrier`]='{ item }')
          span(v-if="item.carrier") {{item.carrier.fullname}}
        template(v-slot:[`item.order_ids`]='{ item }')
          span {{item.order_ids.length}}
        template(v-slot:[`item.order_ids`]='{ item }')
          span {{item.order_ids.length}}
        template(v-slot:[`item.businessess`]='{ item }')
          div(v-html="businessHubList(item)")
        template(v-slot:[`item.status`]='{ item }')
          span {{$decoders.routeStatus(item.status)}}
        template(v-slot:[`item.actions`]='{ item }')
          v-menu(bottom close-on-content-click left)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon='' v-bind='attrs' v-on='on')
                v-icon mdi-dots-vertical
            v-list
              v-list-item(@click='openEdit(item)')
                v-list-item-title Editar 
              v-list-item(v-if="canConfirmRoute(item)" @click='openDialogReadyRoute(item)')
                v-list-item-title Confirmar ruta
              v-list-item(v-if="item.status === 'on_route' || item.status === 'ready'" @click="openDialogCompleteRoute(item)")
                v-list-item-title Completar ruta
              v-list-item(v-if="['delivered','reviewed'].includes(item.status)" @click="$router.push(`/corporativo/rutas/${item.id}/revisar`)") Revisar ordenes
              //v-list-item(v-if="['delivered','reviewed'].includes(item.status)")
                v-list-item-title Revisar ordenes
  dialog-info(ref='dialogInfo') 
  action-route-drawer(ref='actionDrawer' :warehouses="warehouses" :carriers="carriers" @done='loadItems')
  dialog-confirm(ref="dialogConfirmReady" @actionPressed="changeRoutestatus" primaryText="Confirmar")
</template>

<script>
import ActionRouteDrawer from '../../../components/admin/business/ActionRouteDrawer.vue'
import DialogConfirm from '../../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../../components/shared/DialogInfo.vue'
import moment from 'moment'
export default {
  components: { DialogInfo, DialogConfirm, ActionRouteDrawer },
  data () {
    return {
      loading: false,
      generalLoading: false,
      dataTableOptions: {},
      loadingTable: false,
      items: [],
      carriers: [],
      warehouses: [],
      toDelete: null,
      totalItems: 0,
      routeToChangeStatus: null,
      search: {
        docsPerPage: 20,
        page: 1,
      },
      headers: [
        {
          text: 'Fecha salida',
          align: 'start',
          sortable: false,
          width: '250px',
          value: 'init_time',
          class: 'primary--text'
        },
        {
          text: 'Transportista',
          align: 'start',
          sortable: false,
          value: 'carrier',
          class: 'primary--text'
        },
        {
          text: 'Nº Pedidos',
          align: 'start',
          sortable: false,
          value: 'order_ids',
          class: 'primary--text'
        },
        {
          text: 'Empresas a entregar',
          align: 'center',
          sortable: false,
          value: 'businessess',
          class: 'primary--text'
        },
        {
          text: 'Estado',
          align: 'start',
          sortable: false,
          value: 'status',
          class: 'primary--text'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'actions',
          class: 'primary--text'
        }
      ],
      text: '',
    }
  },
  watch: {
    dataTableOptions: {
      handler () {
        this.search.page = this.dataTableOptions.page
        if (this.dataTableOptions.itemsPerPage) {
          this.search.docsPerPage = this.dataTableOptions.itemsPerPage
        }

        this.loadItems()
      },
      deep: true
    },
    'search.page': {
      handler () {
        this.loadItems()
      },
      deep: true
    }
  },
  methods: {
    formatDate(date){
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    openEdit (item) {
      this.$refs.actionDrawer.open('edit',item)
    },
    businessHubList(item){
      if(!item.orders){
        return ''
      }
      return item.orders.map(o => `<div>${o.business.business_name} (${o.hub.name})</div>`).join('')
    },
    canConfirmRoute(item){
      let canConfirm = true
      if(item.orders == null){
        return false
      }
      /*
      item.orders.forEach((o)=> {
        if(o.status !== 'picked-reviewed'){
          canConfirm = false
        }
      })
        */
      return canConfirm && item.status === 'available'
    },
    openDialogReadyRoute(route){
      this.routeToChangeStatus = {route_id:route.id,status:'ready'}
      this.$refs.dialogConfirmReady.open('Confirmar ruta', `¿Está seguro que la ruta de ${route.carrier.fullname} para las ${this.formatDate(route.init_time)} está lista para iniciar en su hora de partida?<br> Una vez confirmado, ya no podrá hacer modificaciones a la ruta.`)
    },
    openDialogCompleteRoute(route){
      this.routeToChangeStatus = {route_id:route.id,status:'completed'}
      this.$refs.dialogConfirmReady.open('Completar ruta', `¿Está seguro marcar como <b>COMPLETADO</b> a la ruta de ${route.carrier.fullname} para las ${this.formatDate(route.init_time)}?<br> Una vez confirmado, el transportista ya no podrá modificar ni actualizar el pedido desde su plataforma.`)
    },
    async changeRoutestatus(){
      this.generalLoading = true
      if(this.routeToChangeStatus.status === 'ready'){
        await this.$store.dispatch('patchRoute',this.routeToChangeStatus)
      }
      if(this.routeToChangeStatus.status === 'completed'){
        await this.$store.dispatch('completeRoute',this.routeToChangeStatus)
      }
      this.$refs.dialogConfirmReady.close()
      this.$refs.dialogConfirmReady.stopLoading()
      await this.loadItems()
      this.generalLoading = false
    },
    async loadItems (firstLoad) {
      if (!this.loadingTable) {
        this.loadingTable = true
        this.generalLoading = true
        try {
          this.loadingTable = true
          let result = await this.$store.dispatch('listRoutes', this.search)
          console.log(result)
          this.items = result.items
          this.totalItems = result.total_items
          this.loadingTable = false
          this.generalLoading = false
        } catch (error) {
          this.$refs.dialogInfo.open(
            'No se pudo listar las empresas',
            error.response.data.error_message
          )
          this.loadingTable = false
          this.generalLoading = false
        }
      }
    }
  },
  async beforeMount () {
    this.generalLoading = true
    await this.loadItems(true)
    this.generalLoading = false
    let result = await this.$store.dispatch("listCarriers", {
      text: "",
      docsPerPage: 2000,
      page: 1,
    });

    this.carriers = result.items;
    this.warehouses = await this.$store.dispatch('listWarehouses')
    
  }
}
</script>

<style lang="scss">
.customer-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>
