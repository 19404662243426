<template lang="pug">
v-container.px-8.customer-view
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title 
          v-btn(icon @click="back") 
            v-icon mdi-chevron-left 
          | Kardex {{ product.name }}
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row(wrap)
    v-col.d-flex.justify-space-between.align-center(cols='12')
      //v-text-field(hide-details style='width:450px; display:inline-block;' v-model="text" outlined label='Buscar producto por nombre')
      v-spacer 
      v-btn(color="primary" @click="openDrawer") Nuevo Movimiento
    v-col(cols='12')
      v-data-table(:headers='headers' :items='items' :items-per-page="-1" hide-default-footer :loading='loadingTable' :search="text" locale='es-ES' no-data-text='Aún no hay movimientos registradas' loading-text="'Cargando...'")
        template(v-slot:[`item.movement_type`]='{ item }')
          div {{ $enums.MOVEMENT_TYPES_PARSER(item.movement_type) }}
        template(v-slot:[`item.movement_concept`]='{ item }')
          div(v-if="item.movement_type === 'INCOMING'") {{ $enums.INCOMING_REASONS_PARSER(item.movement_concept) }}
          div(v-if="item.movement_type === 'OUTGOING'") {{ $enums.OUTGOING_REASONS_PARSER(item.movement_concept) }}
        template(v-slot:[`item.movement_datetime`]='{ item }')
          div {{ parseTime(item.movement_datetime) }}
        template(v-slot:[`item.movement`]='{ item }')
          div 
            b(style="display:inline-block;width: 80px; text-align:right;margin-right: 8px;") Cantidad: 
            span {{ item.qty }} {{ product.unit }}
          div
            b(style="display:inline-block;width: 80px; text-align:right;margin-right: 8px;") P. Unitario: 
            span S/ {{ item.unit_cost.toFixed(2) }}
          div
            b(style="display:inline-block;width: 80px; text-align:right;margin-right: 8px;") Total: 
            span S/ {{ item.total_cost.toFixed(2) }}
        template(v-slot:[`item.ending_balance`]='{ item }')
          div 
            b(style="display:inline-block;width: 80px; text-align:right;margin-right: 8px;") Cantidad: 
            span {{ item.ending_balance.qty }} {{ product.unit }}
          div
            b(style="display:inline-block;width: 80px; text-align:right;margin-right: 8px;") P. Unitario: 
            span S/ {{ item.ending_balance.unit_cost.toFixed(2) }}
          div
            b(style="display:inline-block;width: 80px; text-align:right;margin-right: 8px;") Total: 
            span S/ {{ item.ending_balance.total_cost.toFixed(2) }}
  dialog-info(ref='dialogInfo') 
  create-movement-drawer(ref='actionDrawer' :defaultSku="product.sku" @done='loadItems')

</template>

<script>
import CreateMovementDrawer from '../../../components/admin/business/CreateInventoryMovementDrawer.vue'
import DialogConfirm from '../../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../../components/shared/DialogInfo.vue'
import moment from 'moment'
export default {
  components: { DialogInfo, DialogConfirm, CreateMovementDrawer },
  data () {
    return {
      loading: false,
      generalLoading: false,
      dataTableOptions: {},
      loadingTable: false,
      items: [],
      toDelete: null,
      totalItems: 0,
      filter:{
        movement_type: null,
        start_date: null,
        end_date: null,
        sku: null,
        inventory_type: null,
      },
      headers: [
        {
          text: 'TIPO MOVIMIENTO',
          align: 'start',
          width: '155px',
          value: 'movement_type',
          class: 'primary--text'
        },
        {
          text: 'MOTIVO',
          align: 'start',
          width: '150px',
          value: 'movement_concept',
          class: 'primary--text'
        },
        {
          text: 'FECHA',
          align: 'start',
          width: '180px',
          value: 'movement_datetime',
          class: 'primary--text'
        },
        {
          text: 'MOVIMIENTO REALIZADO',
          align: 'start',
          value: 'movement',
          class: 'primary--text'
        },
        {
          text: 'SALDO FINAL',
          align: 'start',
          value: 'ending_balance',
          class: 'primary--text'
        },
      ],
      text: '',
    }
  },
  methods: {
    parseTime(date){
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    openDrawer(){
      console.log(this.$refs.actionDrawer)
      this.$refs.actionDrawer.open()
    },
    async loadItems(){
      this.generalLoading = true
      this.filter.inventory_type = this.getInventoryType
      this.items = await this.$store.dispatch('getInventoryMovements', this.filter)
      console.log(this.items)
      this.generalLoading = false
    },
    back(){
      if(this.getInventoryType === this.$enums.INVENTORY_TYPES.RAW_MATERIAL){
        return this.$router.push('/materia-prima')
      }
      if(this.getInventoryType === this.$enums.INVENTORY_TYPES.FINAL_PRODUCT){
        return this.$router.push('/producto-final')
      }
    }
  },
  async beforeMount () {
    this.filter.sku = this.$route.params.sku
    this.generalLoading = true
    this.filter.inventory_type = this.getInventoryType
    this.items = await this.$store.dispatch('getInventoryMovements', this.filter)
    console.log(this.items)
    this.generalLoading = false
  },
  computed:{
    getInventoryType(){
      const path = this.$route.path
      if(path.includes("materia-prima")){
        return this.$enums.INVENTORY_TYPES.RAW_MATERIAL
      }
      if(path.includes("producto-final")){
        return this.$enums.INVENTORY_TYPES.FINAL_PRODUCT
      }
      return ""
    },
    product(){
      console.log(this.$store.state.finalProducts)
      let products = this.$store.state.products.productsSimple
      if(this.getInventoryType === this.$enums.INVENTORY_TYPES.FINAL_PRODUCT){
        products = this.$store.state.finalProducts.finalProducts
      }
      const product = products.find((p)=>p.sku === this.filter.sku)
      if(product){
        console.log(product)
        return product
      }
      return {}
    }
  }
}
</script>

<style lang="scss">
.customer-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          
        }
      }
    }
  }
}
</style>
