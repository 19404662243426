<template lang="pug">
 .show-supplier-container
  .supplier-item__info(style='border-top: 1px solid #B9C3C2;')
    .info-field
      span Estado
    .value-field(style='text-transform:uppercase;')
      | {{ carrier.status | __normalizeKeyValue }}
  .supplier-item__info
    .info-field
      span Tipo de documento
    .value-field(style='text-transform:uppercase;')
      | {{ carrier.doc_type }}
  .supplier-item__info
    .info-field
      span N&uacute;mero de documento
    .value-field
      | {{ carrier.doc_number }}
  .supplier-item__info
    .info-field
      span Nombre completo
    .value-field
      | {{ carrier.fullname }}
  .supplier-item__info
    .info-field
      span Tel&eacute;fono de celular
    .value-field
      | {{ carrier.phone }}
  .supplier-item__info
    .info-field
      span Correo electr&oacute;nico
    .value-field
      | {{ carrier.email }}
  .supplier-item__info
    .info-field
      span Departamento
    .value-field
      | {{ decodeRegion(carrier.region_id) }}
  .supplier-item__info
    .info-field
      span Provincia
    .value-field
      | {{ decodeProvincia(carrier.provincia_id) }}
  .supplier-item__info
    .info-field
      span Distrito
    .value-field
      | {{ decodeDistrito(carrier.distrito_id) }}
  .supplier-item__info
    .info-field
      span Dirección
    .value-field
      | {{ carrier.full_address }}
  .supplier-item__info
    .info-field
      span Origen
    .value-field
      | {{ carrier.origin | __normalizeKeyValue }}
  

</template>

<script>
import regiones from '../../../ubigeo/departamentos.json'
import provincias from '../../../ubigeo/provincias.json'
import distritos from '../../../ubigeo/distritos.json'
export default {
  props: {
    carrier: {
      type: Object,
      required: true
    }
  },
  methods:{
    decodeRegion(region_id){
      if(region_id){
        let data = regiones[regiones.findIndex((d=>d.id == region_id))]
        return data.name || '' 
      }
      return ''
    },
    decodeProvincia(provincia_id){
      if(!provincia_id){
        return ''
      }
      let data = provincias[provincias.findIndex((d=>d.id == provincia_id))]
      return data.name || '' 
    },
    decodeDistrito(distrito_id){
      if(!distrito_id){
        return ''
      }
      let data = distritos[distritos.findIndex((d=>d.district_id == distrito_id))]
      if(!data){
        return ""
      }
      return data.name || ''
    },
  }
}
</script>

<style lang="scss">
.show-supplier-container {
  width: 100%;
  .supplier-item__info {
    height: 44px;

    border-bottom: 1px solid #b9c3c2;
    display: flex;
    align-items: center;
    .info-field {
      width: 140px;
      font-size: 12px;
      line-height: 18px;
      color: #818685;
    }
    .value-field {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 24px;
      color: #343837;
      .append-content {
        color: #561fdb;
      }
    }
  }
}
</style>
