<template lang="pug">
v-dialog(v-model="show" max-width="300px" persistent)
  v-card.py-2
    v-card-title.d-flex.justify-center
      h3.text-center {{ title }}
    v-card-text
      v-text-field(type="date" v-model="form.purchase_date" label="Fecha de compra" outlined dense)
      v-text-field(type="time" v-model="form.purchase_time" :step="60" label="Hora de compra" outlined dense)
      .py-3.d-flex.justify-center
        v-btn(color="primary" outlined @click="close") Cerrar
        v-btn.ml-6(color="primary" @click="confirmed" :loading="loading") {{ action === 'create' ? 'Crear' : 'Editar' }}
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          id: null,
          purchase_date: "",
          purchase_time: "",
          buyer: null,
        },
        show: false,
        loading: false,
        action: 'create',
      };
    },
    computed: {
      title() {
        return this.action === 'create' ? 'NUEVA COMPRA' : 'EDITAR COMPRA';
      },
    },
    methods: {
      close() {
        this.show = false;
        this.loading = false;
      },
      open(action, item) {
        if (action === 'edit') {
          this.action = 'edit';
          this.form.id = item.id;
          this.form.purchase_date = item.purchase_date;
          this.form.purchase_time = item.purchase_time.split(":").slice(0, 2).join(":");
          this.form.buyer = item.buyer;
        } else {
          const currentDate = new Date();
          this.form = {
            id: null,
            purchase_date: currentDate.toISOString().split("T")[0],
            purchase_time: currentDate.toTimeString().split(":").slice(0, 2).join(":"),
            buyer: null,
          };
        }
        this.show = true;
        this.loading = false;
      },
      async confirmed() {
        this.loading = true;
        try {
          if (this.action === 'edit') {
            await this.$store.dispatch('changePurchaseDates', { purchaseId: this.form.id, payload: this.form });
          } else {
            await this.$store.dispatch('createPurchase', this.form);
          }
          this.$emit('done');
          this.close();
        } catch (error) {
          console.error(error);
        }
      },
    },
  };
  </script>
  