<template>
  <second-container
    :showFooter="true"
    class="delivery-container"
    title="Configuración de delivery"
  >
    <template slot="content">
      <v-container class="px-0">
        <div class="subtitle ">Horarios de reparto</div>
        <v-row class="mt-2">
          <v-col cols="12" class="days-container">
            <open-day-list-item :day="config.schedules[0]" />
            <open-day-list-item :day="config.schedules[1]" />
            <open-day-list-item :day="config.schedules[2]" />
            <open-day-list-item :day="config.schedules[3]" />
            <open-day-list-item :day="config.schedules[4]" />
            <open-day-list-item :day="config.schedules[5]" />
            <open-day-list-item :day="config.schedules[6]" />
          </v-col>
        </v-row>
        <div class="subtitle mt-10">¿Cuántos pedidos se podrán hacer por horario de atención</div>
        <v-col cols="6" md="3">
          <v-text-field
            v-model.number="config.delivery_config.max_orders_per_range"
            label="Máximos pedidos a recibir"
            type="number"
            :disabled="loading"
            outlined
            class="mb-2"
          />
        </v-col>
        <div class="subtitle ">Zonas de reparto</div>
        <v-row>
          <v-col cols="12" md="10">
            <v-row>
              <v-col cols="3" md="3">
                <v-autocomplete
                  label="Departamento"
                  outlined
                  :items="departamentos"
                  v-model="departamento"
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col cols="3" md="3">
                <v-autocomplete
                  label="Provincia"
                  :items="provincias"
                  v-model="provincia"
                  item-text="name"
                  outlined
                  item-value="id"
                />
              </v-col>
              <v-col cols="3" md="3">
                <v-autocomplete
                  label="Distritos"
                  :items="distritos"
                  v-model="distrito"
                  item-text="name"
                  outlined
                  return-object
                  :disabled="provincia == ''"
                  no-data-text="No hay más distritos para agregar"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="3" md="3" class="pt-6">
                <v-btn
                  @click="addZone"
                  :disabled="distrito == null"
                  color="primary"
                  >Añadir</v-btn
                >
              </v-col>  
            </v-row>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row class="">
            <v-col cols="6">
              <div class="primary--text">Distrito</div>
            </v-col>
            <v-col cols="4">
              <div class="primary--text">Precio del delivery</div>
            </v-col>
            <v-col style="border-radius: 14px;" cols="10">
              <v-row
                v-for="(zone, index) in zones"
                :key="zone.district_id"
                class="zone-row"
              >
                <v-col cols="7">
                  <span>{{zone.name}}</span>
                </v-col>
                <v-col cols="5" style="display: flex; justify-content: space-between;">
                  <v-currency-field
                    v-model="zone.delivery_cost"
                    label="Costo de delivery"
                    :disabled="loading"
                    outlined
                    dense
                    hide-details
                    style="max-width: 120px;"
                    prefix="S/ "
                  />
                  <v-icon @click="deleteZone(index)">mdi-delete</v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>        
      </v-container>
      <dialog-info ref="dialogInfo" />
    </template>

    <template slot="footer-content">
      <v-btn :loading="loading" color="primary" class="ml-5" @click="save"
        >GUARDAR CAMBIOS</v-btn
      >
    </template>
  </second-container>
</template>

<script>
import SecondContainer from '../SecondContainer.vue'
import DialogConfirmEdit from '../../shared/DialogConfirmEdit.vue'
import DialogInfo from '../../shared/DialogInfo.vue'
import OpenDayListItem from './list/OpenDayListItem'
import departamentosJSON from '../../../ubigeo/departamentos.json'
import provinciasJSON from '../../../ubigeo/provincias.json'
import distritosJSON from '../../../ubigeo/distritos.json'
export default {
  components: {
    SecondContainer,
    DialogConfirmEdit,
    DialogInfo,
    OpenDayListItem
  },
  beforeMount () {
    this.config = JSON.parse(JSON.stringify(this.oldConfig))
  },
  computed: {
    zones () {
      this.key
      return this.config.zones
    },
    departamentos () {
      return departamentosJSON
    },
    provincias () {
      if (this.departamento == '') {
        return []
      }
      return provinciasJSON.filter(p => p.department_id == this.departamento)
    },
    distritos () {
      if (this.provincia == '') {
        return []
      }
      return distritosJSON
        .filter(d => d.province_id == this.provincia)
        .filter(
          d =>
            this.config.zones.findIndex(z => z.district_id == d.district_id) ==
            -1
        )
    }
  },
  data () {
    return {
      errorMessage: '',
      index: 0,
      percentaje: 0,
      loading: false,
      key: 1,
      departamento: '',
      provincia: '',
      distrito: '',
      config: {
        id: null,
        zones: [],
        banners: [],
        schedules: [],
        delivery_config: {
          price_type: 'flat',
          flat_price: 0
        }
      }
    }
  },
  props: {
    oldConfig: {
      type: Object,
      required: true
    }
  },
  methods: {
    async save () {
      this.loading = true
      try {
        await this.$store.dispatch('putConfig', this.config)
        this.$toast.success('Configuración guardada satisfactoriamente')
        this.$emit('saveDone')
      } catch (error) {
        this.$refs.dialogInfo.open(
          'No se pudo completar la operación',
          error.response.data.error_message
        )
      }
      this.loading = false
    },
    addZone () {
      let newDistrito = JSON.parse(JSON.stringify(this.distrito))
      newDistrito.delivery_cost = 0
      this.config.zones.push(newDistrito)
      this.distrito = null
    },
    hasChanged () {
      let string1 = JSON.stringify(this.oldConfig)
      let string2 = JSON.stringify(this.config)
      return string1 != string2
    },
    deleteZone (index) {
      this.config.zones.splice(index, 1)
    }
  }
}
</script>

<style lang="scss">
.delivery-container {
  .banner-item {
    height: 80px;
    background: #ffffff;
    border-radius: 16px;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }
  .zone-row{
    border-bottom: 1px solid #E9F0EF;
    background-color: white;
    .col{
      align-items: center;
      display: flex;
    }
  }
  .days-container {
    display: flex;
  }
}
</style>
