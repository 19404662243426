<template lang="pug">
v-container.px-8
  dialog-action-purchase(ref="dialogActionPurchase" @done="loadPurchase")
  dialog-action-purchase-item(ref="dialogActionPurchaseItem" :purchase="purchase" @done="loadPurchase")
  dialog-action-purchase-extra-cost(ref="dialogActionPurchaseExtraCost" :purchase="purchase"  @done="loadPurchase")
  dialog-confirm(ref="dialogDeleteItem" primaryText="Eliminar" secondaryText="Cancelar" @actionPressed="deletePurchaseItem")
  dialog-confirm(ref="dialogDeleteExtra" primaryText="Eliminar" secondaryText="Cancelar" @actionPressed="deletePurchaseExtraCost")
  dialog-confirm(ref="dialogConfirmApprove" primaryText="Aprobar" secondaryText="Cancelar" @actionPressed="confirmApproveStatus")
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title.d-flex.align-center 
          v-btn(icon @click="$router.go(-1)") 
            v-icon mdi-arrow-left
          | Detalle de la compra
          v-chip.ml-8(small label :color="$decoders.purchaseStatusColor(purchase.status)") {{ $decoders.purchaseStatus(purchase.status) }}
        v-spacer
        v-btn(color="success" v-if="purchase.status !== 'approved'" @click="openConfirmApprove") Aprobar Compra 
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row(wrap)
    v-col(cols='4')
      v-card.elevation-2
        v-card-title Total en productos
        v-card-text
          h3 {{ formatCurrency(purchase.total_items_cost) }}
    v-col(cols='4')
      v-card.elevation-2
        v-card-title Total Costos Extras
        v-card-text
          h3 {{ formatCurrency(purchase.total_extra_costs) }}
    v-col(cols='4')
      v-card.elevation-2
        v-card-title Costo Total
        v-card-text
          h3 {{ formatCurrency(purchase.total_cost) }}
  v-row
    v-col(cols='12')
      v-card.elevation-2.mb-4
        v-card-title Comprador
        v-card-text
          div
            span.font-weight-bold Nombre: 
            span {{ purchase.buyer.first_name }} {{ purchase.buyer.last_name }}
          div
            span.font-weight-bold Email: 
            span {{ purchase.buyer.email }}
          div
            span.font-weight-bold Fecha de Compra: 
            span {{ formatPurchaseDate(purchase.purchase_timestamp) }}
  v-tabs(v-model="tab")
    v-tab Productos
    v-tab Costos Extras
  v-divider
  .text-right.py-4(v-if="purchase.status !== 'approved'")
    v-btn(v-if="tab === 0" color="primary" @click="openCreateItem") Nuevo Producto 
    v-btn(v-if="tab === 1" color="primary" @click="openCreateExtraCost") Nuevo Costo Extra    
  v-tabs-items(v-model="tab")
    v-tab-item
      v-data-table(
        :items="purchase.items"
        :headers="itemsHeaders"
        no-data-text="No se encontraron productos"
        :footer-props="{'items-per-page-text': 'Por página','items-per-page-options': [100, 200]}"
      )
        template(v-slot:[`item.product_name`]='{ item }')
          span {{ item.product_name }}
        template(v-slot:[`item.quantity`]='{ item }')
          span {{ item.quantity }} {{ item.unit }}
        template(v-slot:[`item.supplier`]='{ item }')
          span {{ item.supplier }}
        template(v-slot:[`item.unit_price`]='{ item }')
          span {{ formatCurrency(item.unit_price) }}
        template(v-slot:[`item.total_price`]='{ item }')
          span {{ formatCurrency(item.total_price) }}
        template(v-slot:[`item.actions`]='{ item }')
          v-menu(bottom close-on-content-click left v-if="purchase.status !== 'approved'")
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon='' v-bind='attrs' v-on='on')
                v-icon mdi-dots-vertical
            v-list
              v-list-item(@click="$refs.dialogActionPurchaseItem.open('edit', item)" )
                v-list-item-title Editar
              v-list-item(@click='openModalDeleteItem(item)')
                v-list-item-title Eliminar
    v-tab-item
      v-data-table(
        :items="purchase.extra_costs"
        :headers="extraCostsHeaders"
        no-data-text="No se encontraron costos extras"
        :footer-props="{'items-per-page-text': 'Por página','items-per-page-options': [100, 200]}"
      )
        template(v-slot:[`item.concept`]='{ item }')
          span {{ item.concept }}
        template(v-slot:[`item.supplier`]='{ item }')
          span {{ item.supplier }}
        template(v-slot:[`item.total_price`]='{ item }')
          span {{ formatCurrency(item.total_price) }}
        template(v-slot:[`item.actions`]='{ item }')
          v-menu(bottom close-on-content-click left v-if="purchase.status !== 'approved'")
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon='' v-bind='attrs' v-on='on')
                v-icon mdi-dots-vertical
            v-list
              v-list-item(@click="$refs.dialogActionPurchaseExtraCost.open('edit', item)" )
                v-list-item-title Editar
              v-list-item(@click='openModalDeleteExtra(item)')
                v-list-item-title Eliminar
  </template>
  
  <script>
  import DialogConfirm from "../../../components/shared/DialogConfirm.vue";
  import DialogActionPurchase from '../../../components/admin/business/DialogActionPurchase.vue'
  import DialogActionPurchaseItem from '../../../components/admin/business/DialogActionPurchaseItem.vue'
  import DialogActionPurchaseExtraCost from '../../../components/admin/business/DialogActionPurchaseExtraCost.vue'
  export default {
    components: {
      DialogConfirm,
      DialogActionPurchase,
      DialogActionPurchaseItem,
      DialogActionPurchaseExtraCost
    },
    data() {
      return {
        tab: 0,
        generalLoading: false,
        toDelete: null,
        purchase: {
          items: [],
          extra_costs: [],
          total_items_cost: 0,
          total_extra_costs: 0,
          total_cost: 0,
          buyer: {
            first_name: '',
            last_name: '',
            email: '',
          },
          purchase_timestamp: 0,
        },
        itemsHeaders: [
          { text: 'Producto', value: 'product_name' },
          { text: 'Proveedor', value: 'supplier' },
          { text: 'Cantidad', value: 'quantity' },
          { text: 'Precio unitario', value: 'unit_price' },
          { text: 'Precio Total', value: 'total_price' },

          {
            text: "",
            align: "start",
            sortable: false,
            value: "actions",
            class: "primary--text",
          },
        ],
        extraCostsHeaders: [
          { text: 'Concepto', value: 'concept' },
          { text: 'Proveedor', value: 'supplier' },
          { text: 'Precio Total', value: 'total_price' },
          {
            text: "",
            align: "start",
            sortable: false,
            value: "actions",
            class: "primary--text",
          },
        ],
      };
    },
    methods: {
      formatCurrency(value) {
        return new Intl.NumberFormat("es-PE", {
          style: "currency",
          currency: "PEN",
        }).format(value);
      },
      formatPurchaseDate(timestamp) {
        const date = new Date(timestamp * 1000);
        return new Intl.DateTimeFormat("es-PE", {
          dateStyle: "short",
          timeStyle: "short",
          timeZone: "America/Lima",
        }).format(date);
      },
      openModalDeleteItem(item){
        this.toDelete = item;
        this.$refs.dialogDeleteItem.open(
           "Eliminar Compra",
          `¿Estás seguro de eliminar el producto <b>${item.product_name}</b>? Esta acción no se puede deshacer.`,
        )
      },
      openModalDeleteExtra(extra){
        this.toDelete = extra;
        this.$refs.dialogDeleteExtra.open(
           "Eliminar Costo Extra",
          `¿Estás seguro de eliminar el costo extra <b>${extra.concept}</b>? Esta acción no se puede deshacer.`,
        )
      },
      openCreateItem(){
        this.$refs.dialogActionPurchaseItem.open("create");
      },
      openCreateExtraCost(){
        this.$refs.dialogActionPurchaseExtraCost.open("create");
      },
      async loadPurchase() {
        this.generalLoading = true;
        try {
          let purchase = await this.$store.dispatch("findPurchaseByID", this.$route.params.id);
          this.purchase = purchase;
        } catch (error) {
          console.error("Error al cargar la compra:", error);
        }
        this.generalLoading = false;
      },
      createPurchase() {
        this.$refs.dialogActionPurchase.open("create");
      },
      async deletePurchaseItem() {
        if (this.toDelete) {
          try {
            await this.$store.dispatch("deletePurchaseItem", {
              purchaseId: this.purchase.id,
              purchaseItemId: this.toDelete.id,
            });
            await this.loadPurchase();
            this.$refs.dialogDeleteItem.close()
          } catch (error) {
            this.$refs.dialogInfo.open("Error", "No se pudo eliminar");
          }
        }
      },
      async deletePurchaseExtraCost() {
        if (this.toDelete) {
          try {
            await this.$store.dispatch("deletePurchaseExtraCost", {
              purchaseId: this.purchase.id,
              extraCostId: this.toDelete.id,
            });
            await this.loadPurchase();
            this.$refs.dialogDeleteExtra.close()
          } catch (error) {
            this.$refs.dialogInfo.open("Error", "No se pudo eliminar");
          }
        }
      },
      openConfirmApprove() {
        this.$refs.dialogConfirmApprove.open(
          "Confirmar revisión",
          "¿Estás seguro que deseas confirmar la revisión de la compra?"
        );
      },
      async confirmApproveStatus(){
        try {
          await this.$store.dispatch("changePurchaseStatus", { purchaseId: this.purchase.id, status: 'approved' }); 
          await this.loadPurchase();
          this.$refs.dialogConfirmApprove.close()
        } catch (error) {
          this.$refs.dialogInfo.open("Error", "No se pudo confirmar la revisión");
        }
      }
    },

    async beforeMount() {
      await this.$store.dispatch('loadProductsSimple')
      await this.loadPurchase();     
    },
  };
  </script>
  
  <style lang="scss">
  .purchase-view {
    .v-data-table__wrapper {
      table {
        tbody {
          tr:nth-child(even) {
            td {
              background: transparent;
            }
          }
          tr:nth-child(odd) {
            background: #f4f9f7;
          }
          tr {
            &:hover {
              cursor: pointer;
              background-color: #c8fbe1 !important;
            }
          }
        }
      }
    }
  }
  </style>
  