<template>
  <v-dialog v-model="dialog" width="560">
    <v-card>
      <v-card-title>
        <v-toolbar flat color="transparent">
          <v-toolbar-title class="title">PRODUCTOS</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon color="#999999">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-simple-table fixed-header height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width:30px;" class="text-left"></th>
                <th class="text-left">
                  Nombre
                </th>
                <th style="width:30px;" class="text-left">
                  Cantidad
                </th>
                <th style="width:90px;" class="text-center">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in order.products" :key="index">
                <td>
                  <v-img height="50" width="50" :src="item.thumbnail"></v-img>
                </td>
                <td>{{ item.name }}</td>
                <td class="text-center">{{ item.qty }} {{ item.unit }}</td>
                <td style="width:90px;" class="text-center">
                  S/ {{ (item.qty * item.sell_price).toFixed(2) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions
        class="justify-space-around pb-4"
      >
        <v-btn color="primary" small outlined @click="createPDF">
          Descargar
        </v-btn>
        <v-btn color="primary" small @click="dialog = false">ENTENDIDO</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  data() {
    return {
      dialog: false,
      order: {
        products: [],
      },
    };
  },
  methods: {
    decodePaymentMethod(paymentMethod) {
      return this.$decoders.paymentMethods(paymentMethod);
    },
    open(order) {
      this.dialog = true;
      this.order = order;
    },
    close() {
      this.dialog = false;
    },
    createPDF() {
      var doc = new jsPDF();
      const LEFT_PADDING = 15;
      const LEFT_PADDING_2 = 110;
      doc.setFontSize(32);
      const orderNumber = this.order.order_number.toString();
      doc.text(`Nº ${orderNumber.padStart(7, "0000000")}`, LEFT_PADDING, 20);
      doc.setFontSize(14);
      doc.setFont("Helvetica", "bold");
      doc.text("FACTURACIÓN", LEFT_PADDING, 35);
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.text(
        `${this.order.billing.doc_type.toUpperCase()} - ${
          this.order.billing.doc_number
        }`,
        LEFT_PADDING,
        43
      );
      doc.text(`${this.order.billing.business_name}`, LEFT_PADDING, 50);

      doc.setFontSize(14);
      doc.setFont("Helvetica", "bold");
      doc.text("PERSONA DE CONTACTO", LEFT_PADDING_2, 35);
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.text(`${this.order.customer.contact_name}`, LEFT_PADDING_2, 43);
      doc.text(
        `${this.order.customer.phone} - ${this.order.customer.email}`,
        LEFT_PADDING_2,
        50
      );

      doc.setFontSize(14);
      doc.setFont("Helvetica", "bold");
      doc.text("FACTURACIÓN", LEFT_PADDING, 35);
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.text(
        `${this.order.billing.doc_type.toUpperCase()} - ${
          this.order.billing.doc_number
        }`,
        LEFT_PADDING,
        43
      );
      doc.text(`${this.order.billing.business_name}`, LEFT_PADDING, 50);

      doc.setFontSize(14);
      doc.setFont("Helvetica", "bold");
      doc.text("MÉTODO DE PAGO", LEFT_PADDING, 65);
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.text(
        `${this.decodePaymentMethod(this.order.payment_method)}`,
        LEFT_PADDING,
        73
      );

      doc.setFontSize(14);
      doc.setFont("Helvetica", "bold");
      doc.text("DIRECCIÓN DE ENTREGA", LEFT_PADDING_2, 65);
      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.text(
        `${this.order.shipping_info.shipping_address}`,
        LEFT_PADDING_2,
        73
      );
      doc.text(
        `${this.order.shipping_info.shipping_zone.name}`,
        LEFT_PADDING_2,
        80
      );
      const products = [];
      for (var i = 0; i < this.order.products.length; i++) {
        const data = {};
        let product = this.order.products[i];
        data.id = (i + 1).toString();
        data.name = product.name;
        data.qty = `${product.qty} ${product.unit}`;
        data.total = "S/ " + (product.sell_price * product.qty).toFixed(2);
        products.push([data.id, data.name, data.qty, data.total]);
      }
      doc.autoTable({
        startY: 95,
        margin: LEFT_PADDING,
        columnStyles: {
          0: { halign: "center" },
          2: { halign: "center" },
          3: { halign: "center" },
        },
        head: [
          [
            { content: "Nº", styles: { halign: "center" } },
            "Producto",
            { content: "Cantidad", styles: { halign: "center" } },
            { content: "Total", styles: { halign: "center" } },
          ],
        ],
        body: products,
      });
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 10,
        margin: LEFT_PADDING,
        columnStyles: {
          0: { halign: "center" },
          1: { halign: "center" },
          2: { halign: "center" },
        },
        head: [
          [
            {
              content: "Costo total de productos",
              styles: { halign: "center" },
            },
            { content: "Costo de envio", styles: { halign: "center" } },
            { content: "Total", styles: { halign: "center" } },
          ],
        ],
        body: [
          [
            "S/ " +
              this.order.products
                .reduce((a, p) => a + p.sell_price * p.qty, 0)
                .toFixed(2),
            "S/ " + this.order.delivery_cost.toFixed(2),
            "S/ " + this.order.total_cost.toFixed(2),
          ],
        ],
      });
      doc.save(`${orderNumber.padStart(7, "0000000")}.pdf`);
    },
  },
};
</script>

<style></style>
