<template lang="pug">
v-container.px-8
  //dialog-confirm(ref="dialogFactura" primaryText="Emitir factura" secondaryText="Salir" @actionPressed="emitirFactura")
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title PICKING
        v-spacer
        v-btn(icon @click="lessDay")
          v-icon mdi-chevron-left 
        v-menu(ref='menu1' v-model='menu1'  left :close-on-content-click='false'  transition='scale-transition' max-width='290px' min-width='auto')
          template(v-slot:activator="{ on, attrs }")
            h3(v-bind='attrs' v-on='on') {{ dateFormatted }}
          v-date-picker(v-model='date' @change="loadInfo" locale="es-ES" no-title='' @input='menu1 = false')
        v-btn(icon @click="addDay")
          v-icon mdi-chevron-right 
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-col(cols="12")
    h3 Pedidos por iniciar
    p Estas son las ordenes para la fecha indicada, asignele un picker a cada una.
  v-col(cols="12" )  
    v-sheet(outlined)
      v-simple-table
        template(v-slot:default='')
          thead
            tr
              th.text-left(style="width: 400px")
                | Empresa
              th.text-left
                | Monto Total 
              th.text-center 
                | Picker
              td
          tbody
            tr(v-for='item in ordersToPicking' :key='item.id')
              td {{ item.business.business_name }} - {{ item.hub.name }}
              td S/ {{ calcAmountTotal(item) }}
              td
                v-select(hide-details v-model="item.picker_id" @change="pickerSelected(item)" dense outlined item-text="fullname" item-value="id" :items="pickers")
              td 
                div
                  v-btn(color="primary" @click="startPicking(item)" :disabled="item.picker_id == null") Iniciar picking
    //.mt-8.text-center
      div
        v-alert(dense style="max-width: 600px; margin: 0 auto;" type='info' elevation='2')
          | Al marcar como "Listo", no se podrán crear más pedidos para esta fecha.
  v-row.px-4
    // v-col(cols="4") 
      v-sheet.text-center.pa-4(outlined)
        h4 CREADO EL
        p.mb-0.mt-5 {{ formatDate(pickingInfo.created_at) }}
    // v-col(cols="4") 
      v-sheet.text-center.pa-4(outlined)
        h4 INICIADO EL
        p.mb-0.mt-5(v-if="pickingInfo.start_at != null") {{ formatDate(pickingInfo.start_at) }}
        p.mb-0(v-else)
          // v-btn.mt-2(color="primary" @click="startPicking") INICIAR
    // v-col(cols="4") 
      v-sheet.text-center.pa-4(outlined)
        h4 COMPLETADO EL
        p.mb-0.mt-5 {{ formatDate(pickingInfo.completed_at) }}
    //template(v-if="pickingInfo.status === 'waiting' || pickingInfo.status === 'in_progress'")
      v-col(cols="12")
        .d-flex
          h2 PEDIDOS
          v-spacer 
          // h2.indigo--text {{completedPercentage}}% COMPLETADO
    v-col(cols="12")  
      v-divider.my-4
      h3.mb-4 Pedidos en recolección
      v-card.mb-6(v-for="order in ordersPicking" :key="order.id")
        v-card-text 
          v-row 
            v-col(cols="4")
              .subtitle 
                p
                  b EMPRESA: 
                  | {{ order.business.business_name }}
                p 
                  b PICKER: 
                  |  {{ order.picker.first_name }} {{ order.picker.last_name }}
            v-col(cols="4")
              .subtitle.text-center 
                p
                  b HUB: 
                  | {{ order.hub.name }}
            v-col(cols="4")
              .subtitle.text-center
                //b PICKER:
                 |  {{ order.picker.first_name }} {{ order.picker.last_name }}
            v-col(cols="12")
              h3 {{ calcCompletedProducts(order) }} de {{ order.products.filter(p => p.qty_ordered > 0).length }}
              v-progress-linear(:value="getPercentageValue(order)" :height="24" rounded color="indigo")
                template(v-slot:default='{ value }')
                  strong.white--text {{ Math.ceil(value) }}%
    v-col(cols="12")
      v-divider.my-4
      h3.mb-4 Pedidos completados
      v-card.mb-6(v-for="item in ordersPicked" :key='item.id')
        .d-flex.pa-4
          h4 {{ item.picking_reviewed_at == null ? 'REVISIÓN FINAL':'ESTADO FINAL' }}
          v-spacer 
          v-btn(v-if="item.picking_reviewed_at == null" color="indigo" @click="reviewOrder(item)" dark) FINALIZAR REVISIÓN
        v-card-text 
          p 
            b EMPRESA:  
            | {{item.business.business_name}}
            b.ml-15 HUB:
            | {{ item.hub.name }}
          p 
            b PICKER: 
            |  {{ item.picker.first_name }} {{ item.picker.last_name }}
          v-sheet(outlined)
            v-simple-table
              template(v-slot:default='')
                thead
                  tr
                    th.text-left
                      | Descripción
                    th.text-center(style="width: 200px;")
                      | Cantidad recolectada
                    th.text-center(style="width: 200px;")
                      | Cantidad ordenada 
                    th.text-center(style="width: 200px;")
                      | Precio unitario 
                    th.text-center(style="width: 200px;")
                      | Total
                tbody
                  tr(v-for='product in item.products.filter(p => p.qty_ordered > 0)' :key='product.id')
                    td {{ product.name }}
                    td.text-center(v-if="item.picking_reviewed_at == null")
                      v-text-field(dense hide-details outlined type="number" v-model.number="product.qty_picked" :suffix="product.unit") 
                    td.text-center(v-if="item.picking_reviewed_at !== null") {{ product.qty_picked }} {{ product.unit_base }}
                    td.text-center {{ product.qty_ordered }} {{ product.unit }}

                    td.text-center  S/ {{ product.sell_price }} x {{ product.unit }}
                    td.text-center S/ {{ (product.qty_picked * product.sell_price).toFixed(2) }}
                  tr
                    td
                    td
                    td
                    td
                    td.text-center
                      b S/ {{ totalCalc(item) }}
      //v-col
        .d-flex.justify-center 
          v-btn(x-large color="indigo" @click="endReview" dark) FINALIZAR REVISIÓN

  dialog-info(ref='dialogInfo') 


</template>

<script>
import DialogConfirm from "../../../components/shared/DialogConfirm.vue";
import DialogInfo from "../../../components/shared/DialogInfo.vue";
import moment from "moment";
import Axios from "axios";
export default {
  components: { DialogInfo, DialogConfirm },
  data() {
    return {
      loading: false,
      generalLoading: false,
      date: null,
      orders: [],
      menu1: false,
      pusherChannel: null,
      pickers: [],
    };
  },
  computed: {
    ordersToPicking() {
      return this.orders.filter((o) => o.picking_at === null);
    },
    ordersPicking() {
      return this.orders.filter(
        (o) => o.picking_at !== null && o.picking_finished_at == null
      );
    },
    ordersPicked() {
      return this.orders.filter(
        (o) => o.picking_at !== null && o.picking_finished_at !== null
      );
    },
    dateFormatted() {
      if (this.date == null) return "";
      return moment(this.date).format("DD/MM/YYYY");
    },
    pickingInfo() {
      return this.$store.state.picking.currentPickingData;
    },
    allHasPickers() {
      if (!this.orders) {
        return false;
      }
      return !this.orders.some((o) => o.picker_id == null);
    },
    completedPercentage() {
      let totalProducts = 0;
      let completedProducts = 0;

      this.pickingInfo.orders.forEach((order) => {
        order.products.forEach((product) => {
          totalProducts++;
          if (product.completed) {
            completedProducts++;
          }
        });
      });
      return totalProducts > 0 ? (completedProducts / totalProducts) * 100 : 0;
    },
  },
  methods: {
    async endReview() {
      this.generalLoading = true;
      const movements = [];
      this.pickingInfo.orders.forEach((order) => {
        order.products.forEach((product) => {
          if (product.sku) {
            movements.push({
              id: this.$uid(),
              movement_datetime: new Date(),
              movement_timestamp: 0,
              movement_type: "OUTGOING",
              movement_concept: "SELL",
              user_info: null,
              sku: product.sku,
              qty: product.qty_picked,
              unit_cost: 0,
              total_cost: 0,
              ending_balance: null,
            });
          }
        });
      });
      const copy = JSON.parse(JSON.stringify(this.pickingInfo));
      copy.status = "reviewed";
      await this.$store.dispatch("puttPickingSummary", copy);
      try {
        await this.$store.dispatch("createMassiveMovements", movements);
      } catch (error) {}
      this.loadInfo();
    },
    findProductInOrder(order, productId) {
      return order.products.find((p) => p.id === productId);
    },
    totalCalc(item) {
      let sum = 0;
      item.products.forEach((p) => {
        sum = sum + p.sell_price * p.qty_picked;
      });
      return sum.toFixed(2);
    },
    calcCompletedProducts(order) {
      return order.products.filter((p) => p.picked).length;
    },
    async reviewOrder(item) {
      
      this.generalLoading = true;
      try {
        const { data } = await Axios.patch(
          `v1/admin/business-orders/review-picking`, item
        );
        console.log(data);
        const index = this.orders.findIndex((o) => o.id === item.id);
        this.$set(this.orders, index, data);
      } catch (error) {
        console.log(error);
      }
      this.generalLoading = false;
    },
    getPercentageValue(order) {
      const totalCompleted = order.products
        .filter((p) => p.qty_ordered > 0)
        .filter((p) => p.picked).length;
      const totalOrders = order.products.filter(
        (p) => p.qty_ordered > 0
      ).length;
      return Number(((totalCompleted / totalOrders) * 100).toFixed(2));
    },
    formatDate(date) {
      if (!date) return "-";
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    calcAmountTotal(order) {
      let sum = 0;
      order.products.forEach((i) => {
        sum = sum + i.qty_ordered * i.sell_price;
      });
      return sum.toFixed(2);
    },
    async pickerSelected(order) {
      console.log(`${order.id} - ${order.picker_id}`);
      this.generalLoading = true;
      await this.$store.dispatch("patchChangePicker", {
        business_order_id: order.id,
        picker_id: order.picker_id,
      });
      this.generalLoading = false;
    },
    async loadInfo() {
      this.generalLoading = true;
      this.orders = [];
      try {
        let data = await this.$store.dispatch(
          "getBusinessOrderOfPicking",
          this.date
        );
        this.orders = data;
        this.generalLoading = false;
      } catch (error) {
        console.log(error);
        this.$refs.dialogInfo.open(
          "No se puede mostrar la información del picking",
          error.response.data.error_message
        );
        this.generalLoading = false;
      }
    },
    lessDay() {
      this.date = moment(this.date, "YYYY-MM-DD")
        .add(-1, "days")
        .format("YYYY-MM-DD");
      this.loadInfo();
    },
    addDay() {
      this.date = moment(this.date, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
      this.loadInfo();
    },
    joinChannel() {
      console.log("join channel");
      this.pusherChannel = this.$pusher.subscribe("picking-events");
      this.pusherChannel.bind("updatePicking", (data) => {
        console.log(data);
        const index = this.orders.findIndex((o) => o.id === data.id);
        this.$set(this.orders, index, data);
        //this.refreshPickingSummary()
      });
    },
    async startPicking(item) {
      this.generalLoading = true;
      try {
        const { data } = await Axios.patch(
          `v1/admin/business-orders/${item.id}/iniciar-picking`
        );
        console.log(data);
        const index = this.orders.findIndex((o) => o.id === item.id);
        this.$set(this.orders, index, data);
      } catch (error) {
        console.log(error);
      }
      this.generalLoading = false;
    },
    removeChannel() {
      console.log("remove channel");
      if (this.pusherChannel) {
        this.pusherChannel = this.$pusher.unsubscribe("picking-events");
        this.pusherChannel = null;
      }
    },
    async refreshPickingSummary() {
      await this.$store.dispatch("getPickingSummary", this.date);
    },
  },
  async beforeMount() {
    this.date = moment().format("YYYY-MM-DD");

    this.joinChannel();
    this.loadInfo();
    let result = await this.$store.dispatch("getAuthUsers", "picker");
    this.pickers = result;
  },
  beforeDestroy() {
    this.removeChannel();
  },
};
</script>
