<template lang="pug">
  v-container.px-10.category-view(fluid='')
    v-overlay(color='white' :value='generalLoading')
      v-progress-circular(color='primary' indeterminate='' size='64')
    v-row(wrap='')
      v-col(cols='12')
        v-toolbar(flat='')
          v-toolbar-title.title
            span.ml-4 Usuarios
          v-spacer
          v-btn( color='primary' elevation='4' v-if="hasSectionPermissions('edit')" @click='moveToCreate') CREAR USUARIO
        v-divider(style='border-color: #B9C3C2;')
      v-col(cols='12' v-if="hasSectionPermissions('list')")
        v-data-table(:options.sync='dataTableOptions' :headers='headers' :server-items-length='totalItems' :items='items' :loading='loadingTable' @update:page='updatePage' @update:items-per-page='updateItems' locale='es-ES' no-data-text='Aún no ha creado un usuario' loading-text='Cargando...' :footer-props="{\
          'items-per-page-text': 'Usuarios por página',\
          'items-per-page-options': [25, 50, 100],\
          }")
          template(v-slot:[`item.fullname`]='{ item }')
            span {{item.first_name}} {{item.last_name}}
          template(v-slot:[`item.super_admin`]='{ item }')
            span.font-weight-bold.primary--text(v-if="item.super_admin") SÍ
            span.font-weight-bold(v-else) NO
          template(v-slot:[`item.disabled`]='{ item }')
            span.font-weight-bold(v-if="!item.disabled") NO
            span.font-weight-bold.red--text(v-else) SÍ
          template(v-slot:[`item.actions`]='{ item }')
            v-btn(icon='' v-if="hasSectionPermissions('edit')" @click.stop='openEdit(item)')
              v-icon mdi-pencil
            v-btn(icon='' v-if="hasSectionPermissions('delete')" @click.stop='openDeleteDialog(item)')
              v-icon mdi-delete
    dialog-info(ref='dialogInfo')
    dialog-confirm(@actionPressed='confirmDelete' ref='dialogConfirm')

</template>

<script>
import DialogConfirm from "../../../components/shared/DialogConfirm.vue";
import DialogInfo from "../../../components/shared/DialogInfo.vue";
export default {
  components: { DialogInfo, DialogConfirm },
  data() {
    return {
      loading: false,
      generalLoading: false,
      dataTableOptions: {},
      loadingTable: false,
      items: [],
      toDelete: null,
      totalItems: 0,
      headers: [
        {
          text: "Nombre completo",
          align: "start",
          sortable: false,
          value: "fullname",
          class: "primary--text",
        },
        {
          text: "Correo electrónico",
          align: "start",
          sortable: false,
          value: "email",
          class: "primary--text",
        },
        {
          text: "Super admin",
          align: "start",
          sortable: false,
          value: "super_admin",
          class: "primary--text",
        },
        {
          text: "Deshabilitado",
          align: "start",
          sortable: false,
          value: "disabled",
          class: "primary--text",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "actions",
          class: "primary--text",
        },
      ],
      search: {
        text: "",
        product_ids: [],
        docsPerPage: 20,
        page: 1,
      },
    };
  },
  methods: {
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('usuarios',permissions)
    },
    updatePage(value) {
      this.search.page = value;
      this.loadItems();
    },
    updateItems(items) {
      this.search.docsPerPage = items;
      this.loadItems();
    },
    openEdit(item) {
      this.$router.push({
        path: `/usuarios/accion`,
        query: { action: this.$enums.ACTIONS.EDIT, uid: item.uid },
      });
    },
    async confirmDelete() {
      try {
        this.generalLoading = true;
        await this.$store.dispatch("deleteUser", this.toDelete);
        this.$refs.dialogConfirm.close();
        await this.loadItems()
        this.$toast.success("Usuario eliminado satisfactoriamente");
        this.generalLoading = false
      } catch (error) {
        this.generalLoading = false
        this.$refs.dialogInfo.open(
          "Error eliminando usuario",
          error.response.data.error_message
        );
      }
    },
    openDeleteDialog(item) {
      this.$refs.dialogConfirm.open(
        "Eliminar usuario",
        "Si elimina el usuario su información se borrará permanentemente del sistema."
      );
      this.toDelete = item;
    },
    moveToCreate() {
      this.$router.push({
        path: `/usuarios/accion`,
        query: { action: this.$enums.ACTIONS.CREATE },
      });
    },
    async rebote(text) {
      this.search.text = text;
      await this.loadItems();
    },
    async loadItems(firstLoad) {
      if (!this.loadingTable) {
        try {
          this.loadingTable = true;
          this.items = [];
          let result = await this.$store.dispatch("getUsers");
          this.totalItems = result.length;
          this.items = result;
          this.loadingTable = false;
          this.generalLoading = false;
        } catch (error) {
          this.$refs.dialogInfo.open(
            "No se pudo listas los usuarios",
            error
          );
          this.loadingTable = false;
          this.generalLoading = false;
        }
      }
    },
  },
  async beforeMount() {
    this.generalLoading = true;
    await this.loadItems();
    this.generalLoading = false;
  },
};
</script>

<style lang="scss">
.category-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>