<template>
  <auth-base title="Recuperar contraseña">
    <v-form slot="form" v-model="valido">
      <div v-if="!sent">
        <div class="form-text">
          Ingrese su correo electrónico y le enviaremos un enlace para recuperar
          su cuenta.
        </div>
        <v-text-field
          :rules="rulesEmail"
          v-model.trim="credentials.email"
          class="mb-2 mt-6"
          persistent-hint
          outlined
          label="Correo electrónico"
        />
        <div class="error-text mb-5" v-if="Boolean(error)">{{error}}</div>
        <v-btn
        :disabled="!valido"
        :loading="loading"
        class="mb-8"
        color="primary"
        block
        x-large
        tile
        @click="resetPassword"
        >Enviar</v-btn
      >
      
      </div>
      <div class="text-center" v-else>
        <img class="mb-4" :src="require('../assets/envelope.png')"/>
        <div >Abrir el enlace que enviamos a tu correo</div>
        <div class="mb-5">Podrás volver a enviarlo en {{timeUntilSendAgain}} segundos.</div>
        <v-btn
        :disabled="timeUntilSendAgain>0"
        :loading="loading"
        class="mb-8"
        color="primary"
        block
        x-large
        tile
        @click="resetPassword"
        >Enviar</v-btn
      >
      </div>
      
      <div class="back-button" @click="$router.push('/')">
        <v-icon color="green">mdi-keyboard-backspace</v-icon>
        Volver al inicio
      </div>
    </v-form>
  </auth-base>
</template>

<script>
import errors from '../error_codes'
import AuthBase from '../components/auth/AuthBase.vue'
export default {
  components: { AuthBase },
  data () {
    return {
      loading: false,
      valido: false,
      showPassword: false,
      timeUntilSendAgain : 30,
      
      sent: false,
      error: '',
      credentials: {
        email: '',
        password: ''
      },
      rulesEmail: [
        email => this.validateEmail(email) || 'Formato de email incorrecto.'
      ]
    }
  },
  methods: {
    validateEmail (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    initTimer(){
      this.timeUntilSendAgain = 30
      let codeTimer = setInterval(()=>{
        this.timeUntilSendAgain--
        if(this.timeUntilSendAgain==0){
          clearInterval(codeTimer)
          this.sent = false
        }
      },1000)
    },
    async resetPassword () {
      this.error = ''
      this.loading = true
      try {
        await this.$store.dispatch('resetPassword', {
          email: this.credentials.email,
          forward_to: 'admin'
        })
        this.sent = true
        this.initTimer()
        this.loading = false
      } catch (error) {
        console.log(error)
        console.log(error.response)
        let data = error.response.data
        if(data.has_error){
          this.error = data.error_message
        }
        this.loading = false
        //this.error = errors(error)
      }
    }
  }
}
</script>

<style lang="scss">

</style>
