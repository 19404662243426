import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import business from "./business";
import suppliers from "./suppliers";
import categories from "./categories";
import products from "./products";
import config from "./config";
import customers from "./customers";
import orders from "./orders";
import users from "./users";
import carriers from "./carriers";
import excel from "./excel";
import inventoryMovements from "./inventoryMovements";
import warehouses from "./warehouses";
import routes from "./routes";
import utilities from "./utilities";
import picking from "./picking";
import finalProducts from "./final-products";
import debts from "./debt";
import purchases from './purchases';
import Axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: true,
    loadingScreen: true,
  },
  mutations: {
    changeDrawer(state, change) {
      if (change == null || change == undefined) {
        state.drawer = !state.drawer;
      } else {
        state.drawer = change;
      }
    },
    setLoadingScreen(state, loadingScreen) {
      state.loadingScreen = loadingScreen;
    },
  },
  actions: {
    async postConsultarPdfTeFacturo({}, payload) {
      return await utilities.wrapResponseAndGetData(
        await Axios.post("v1/admin/consultarpdf-tefacturo", payload, {
          responseType: "blob",
        })
      );
    },
  },
  getters: {},
  modules: {
    auth,
    business,
    suppliers,
    categories,
    products,
    config,
    customers,
    orders,
    users,
    carriers,
    excel,
    warehouses,
    routes,
    inventoryMovements,
    picking,
    finalProducts,
    debts,
    purchases
  },
});
