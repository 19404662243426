import Vue from 'vue'

Vue.prototype.$decoders = {

    units(unit){
        switch(unit){
            case 'kg': return 'Kilogramos'
            case 'ud': return 'Unidades'
            case 't': return 'Toneladas'
            case 'docena': return 'Docenas'
            case 'jaba': return 'Jabas'
            case 'caja': return 'Cajas'
            case 'paquete': return 'Paquetes'
            default: return unit
        }
    },
    paymentMethods(method){
        switch(method){
            case 'card': return 'Tarjeta'
            case 'yape': return 'Yape'
            case 'upon_delivery': return 'Contraentrega'
            default: return ''
        }
    },
    purchaseStatus(status){
        switch(status){
            case 'on_process': return 'En proceso de compra'
            case 'on_review': return 'En revisión'
            case 'approved': return 'Aprobada'
            default: return ''
        }
    },
    purchaseStatusColor(value) {
        switch (value) {
          case "on_process":
            return "info";
          case "on_review":
            return "warning";
          case "approved":
            return "success";
          default:
            return "primary";
        }
      },
    routeStatus(status){
        switch(status){
            case "available": 
                return  "DISPONIBLE"
            case "ready": 
                return  "LISTO PARA PARTIR"
            case "on_route": 
                return  "EN RUTA"
            case "delivered": 
                return  "ENTREGADO"
            case "reviewed": 
                return  "REVISADO"
            case "completed": 
                return  "COMPLETADO"
        }
        return 'DESCONOCIDO'        
    },
    orderStatus(status){
        switch(status){
            case "created": 
                return  "DISPONIBLE"
            case "assigned": 
                return  "ASIGNADO A RUTA"
            case "available": 
                return  "DISPONIBLE"            
            case "picking": 
                return  "PICKING"
            case "ready": 
                return  "LISTO PARA PARTIR"
            case "on_route": 
                return  "EN RUTA"
            case "delivered": 
                return  "ENTREGADO"
            case "reviewed": 
                return  "REVISADO"
            case "completed": 
                return  "COMPLETADO"
            case "not_delivered":
                return "NO SE PUDO ENTREGAR"
        }
        return 'DESCONOCIDO'        
    },
}

