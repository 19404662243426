import Axios from "axios"
import utilities from "./utilities"

export default {  
    actions:{
        async getConfig({}){
            return await utilities.wrapResponseAndGetData(await Axios.get('v1/admin/config'))
        },
        async putConfig({},data){
            await Axios.put("v1/admin/config",data)
        },
    }
}