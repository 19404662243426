<template>
  <auth-base title="Inicia Sesión en Quenco">
    <v-form slot="form" v-model="valido">
      <v-text-field
        :rules="rulesEmail"
        v-model.trim="credentials.email"
        class="mb-5"
        persistent-hint
        outlined
        single-line
        label="Correo electrónico"
      />
      <v-text-field
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        :rules="rulesPassword"
        v-model="credentials.password"
        class="mb-0"
        outlined
        single-line
        persistent-hint
        @click:append="showPassword = !showPassword"
        aria-autocomplete="off"
        label="Contraseña"
      />
      <div class="error-text mb-5" v-if="Boolean(error)">{{ error }}</div>
      <v-btn
        :disabled="!valido"
        :loading="loading"
        class="mb-8"
        color="primary"
        block
        x-large
        tile
        @click="logIn"
        >INGRESAR</v-btn
      >
      <div class="bottom-link" @click="$router.push('/forget-password')">Olvidé mi contraseña</div>
    </v-form>
  </auth-base>
</template>

<script>
import errors from '../error_codes'
import AuthBase from '../components/auth/AuthBase.vue'
export default {
  components: { AuthBase },
  data () {
    AuthBase
    return {
      loading: false,
      valido: false,
      showPassword: false,
      error: '',
      credentials: {
        email: '',
        password: ''
      },
      rulesEmail: [
        email => this.validateEmail(email) || 'Formato de email incorrecto.'
      ],
      rulesPassword: [
        password =>
          password.length >= 6 ||
          'Debes ingresar una contraseña de más de 6 caracteres.'
      ]
    }
  },
  methods: {
    validateEmail (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    async logIn () {
      this.error = ''
      this.loading = true
      try {
        await this.$store.dispatch('loginEmail', this.credentials)
      } catch (error) {
        console.log(error)
        this.loading = false
        this.error = errors(error.code)
      }
    }
  }
}
</script>

<style lang="scss"></style>
