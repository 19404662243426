import utilities from './utilities'
import Axios from 'axios'
export default {
    actions:{
        async listRoutes({},params){
            return await utilities.wrapResponseAndGetData(await Axios.get("v1/admin/delivery-routes",{params}))
        },
        async listRoutesSimple({},params){
            return await utilities.wrapResponseAndGetData(await Axios.get("v1/admin/delivery-routes/simple",{params}))
        },
        async putRoute({},payload){
            return await utilities.wrapResponseAndGetData(await Axios.put(`v1/admin/delivery-routes`,payload))
        },
        async patchRoute({},payload){
            return await utilities.wrapResponseAndGetData(await Axios.patch(`v1/admin/delivery-routes/status`,payload))
        },
        async completeRoute({},payload){
            return await Axios.put(`v1/admin/delivery-routes/${payload.route_id}/complete`,payload)
        },
        async getRoute({},id){
            return await utilities.wrapResponseAndGetData(await Axios.get("v1/admin/delivery-routes/"+id))
        },
    }
}