<template lang="pug">
v-container.px-8
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title Empresas
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row(wrap)
    v-col.d-flex.justify-space-between.align-center(cols='12')
      v-text-field(hide-details style='width:450px; display:inline-block;' v-model="text" outlined label='Buscar empresa...')
      v-spacer 
      v-btn(color="primary" @click="$refs.actionDrawer.open('create')") Agregar empresa
    v-col(cols='12')
      v-data-table(:headers='headers' :items='items' :loading='loadingTable' locale='es-ES' no-data-text='Aún no hay empresas registradas' loading-text="'Cargando...'")
     
        template(v-slot:[`item.actions`]='{ item }')
          v-menu(offset-y='')
            template(v-slot:activator='{ on, attrs }')
              v-btn(elevation='0' icon small='' v-bind='attrs' v-on='on')
                v-icon(color='#1F3333') mdi-dots-vertical
            v-list
              v-list-item(@click="openEdit(item)")
                v-list-item-title Editar
              v-list-item(@click='moveToUsers(item)')
                v-list-item-title Gestionar Usuarios
  dialog-info(ref='dialogInfo') 
  action-business-drawer(ref='actionDrawer' @done='loadItems')

</template>

<script>
import ActionBusinessDrawer from '../../../components/admin/business/ActionBusinessDrawer.vue'
import DialogConfirm from '../../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../../components/shared/DialogInfo.vue'
export default {
  components: { DialogInfo, DialogConfirm, ActionBusinessDrawer },
  data () {
    return {
      loading: false,
      generalLoading: false,
      dataTableOptions: {},
      loadingTable: false,
      items: [],
      toDelete: null,
      totalItems: 0,
      headers: [
        {
          text: 'Empresa',
          align: 'start',
          sortable: false,
          width: '250px',
          value: 'business_name',
          class: 'primary--text'
        },
        {
          text: 'Razón Social',
          align: 'start',
          sortable: false,
          value: 'razon_social',
          class: 'primary--text'
        },
        {
          text: 'RUC',
          align: 'start',
          sortable: false,
          value: 'doc_number',
          class: 'primary--text'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'actions',
          class: 'primary--text'
        }
      ],
      text: '',
    }
  },
  methods: {
    openEdit (item) {
      this.$refs.actionDrawer.open('edit',item)
    },
    moveToUsers(item) {
      this.$router.push(`/corporativo/empresas/${item.id}`)
    },
    async loadItems (firstLoad) {
      if (!this.loadingTable) {
        this.loadingTable = true
        try {
          this.loadingTable = true
          let result = await this.$store.dispatch('listBusinesses')
          console.log(result)
          this.items = result
          this.loadingTable = false
          this.generalLoading = false
        } catch (error) {
          this.$refs.dialogInfo.open(
            'No se pudo listar las empresas',
            error.response.data.error_message
          )
          this.loadingTable = false
          this.generalLoading = false
        }
      }
    }
  },
  async beforeMount () {
    this.generalLoading = true
    await this.loadItems(true)
    this.generalLoading = false
  }
}
</script>

<style lang="scss">
.customer-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>
