import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDaBeJE7itXtq7bDQU8B1LNwKcWZMWhhDM',
    libraries: 'places',
    region: 'PE',
    language: 'es'
  }
});