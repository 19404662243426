<template lang="pug">
v-container.px-8
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title Hubs
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row 
    v-col(cols="4")
      v-select(dense label="Seleccione la empresa" item-value="id" :items="businesses" item-text="business_name" v-model="businessId" outlined hide-details @change="businessChanged")
    v-col(cols="3" offset="5")      
      v-btn(@click="$refs.hubDetailsModal.open('create')" color="primary") NUEVO HUB
  v-row    
    v-col(cols="12")
      v-data-table(:headers="headers" :items="hubs" :loading="loading")
        template(v-slot:[`item.actions`]='{ item }')
          v-btn(icon @click="showDeleteMHubModal(item)")
            v-icon mdi-delete
          v-btn(icon @click="openEditHub(item)")
            v-icon mdi-pencil
  dialog-info(ref='dialogInfo') 
  dialog-confirm(ref="dialogConfirm" @actionPressed="confirmDelete")
  hub-details-modal(ref="hubDetailsModal" :businessId="businessId" @done="businessChanged")
</template>

<script>
import ActionBusinessDrawer from '../../../components/admin/business/ActionBusinessDrawer.vue'
import DialogConfirm from '../../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../../components/shared/DialogInfo.vue'
import HubDetailsModal from '../../../components/admin/business/HubDetailsModal.vue'

export default {
  components: { DialogInfo, DialogConfirm, ActionBusinessDrawer, HubDetailsModal },
  computed:{
    
  },
  data () {
    return {
      loading: false,
      generalLoading: false,
      businesses: [],
      hubs: [],
      businessId: null,
      toDelete: null,
      headers: [
        {
          text: 'Nombre del hub',
          align: 'start',
          sortable: false,
          value: 'name',
          class: 'primary--text',
        },
        {
          text: 'Teléfono',
          align: 'start',
          sortable: false,
          value: 'phone',
          class: 'primary--text',
        },
        {
          text: 'Dirección',
          align: 'start',
          sortable: false,
          value: 'address',
          class: 'primary--text',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: 'primary--text',
        },
      ],
    }
  },
  methods: {
    showDeleteMHubModal(item){
      this.toDelete = item
      this.$refs.dialogConfirm.open('Eliminar hub',`¿Seguro que desea eliminar el hub "<b>${item.name}</b>"?`)
    },  
    openEditHub(item){
      this.$refs.hubDetailsModal.open('edit', JSON.parse(JSON.stringify(item)))
    },
    async confirmDelete(){
      await this.$store.dispatch('deleteBusinessHub', {
        businessId: this.businessId,
        hubId: this.toDelete.id
      })
      this.$refs.dialogConfirm.close()
      this.businessChanged()
    },
    async businessChanged(){
      this.generalLoading = true
      this.hubs = await this.$store.dispatch('listBusinessHubs', this.businessId)
      this.generalLoading = false
      
    },    
  },
  async mounted(){
    try {
        this.generalLoading = true
        let businesses = await this.$store.dispatch('listBusinesses')
        this.businesses = businesses
        if(this.businesses.length>0){
          this.businessId = this.businesses[0].id
          this.hubs = await this.$store.dispatch('listBusinessHubs', this.businessId)
        }
        this.generalLoading = false
      } catch (error) {
        console.log("error en load items")
        console.log(error)
        this.generalLoading = false
      }      
  },
}
</script>

<style lang="scss">
.customer-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>
