<template lang="pug">
  v-dialog(v-model='dialog' persistent width='700px')
    v-card.product-view-dialog
      v-card-title.pb-0
        v-toolbar(flat='' color='transparent')
          v-toolbar-title.title {{action === $enums.ACTIONS.CREATE?'Nuevo producto':'Editar producto'}}
          v-spacer
          v-btn(icon='' @click='dialog = false')
            v-icon(color='#999999') mdi-close
      v-card-text
        v-container.py-0(fluid)
          v-row.py-0
            v-col.py-0(cols="12")
              v-form(v-model='valid' ref='form' style='width:100%')
                v-row
                  v-col(cols="12")
                    v-text-field(label='Nombre de la variante' :disabled='loading' outlined v-model='variant.name' counter='100')
                    v-text-field.mb-2(v-model.trim='variant.sunat_code' hide-details label='Código SUNAT' :disabled='loading' outlined)
                    v-checkbox.mt-2.mb-4(label="Incluye IGV" hide-details v-model="variant.include_igv")
                    v-textarea.mb-2(v-model='variant.description' label='Descripción' :disabled='loading' outlined rows='3' :rules='rules.description' counter='5000' no-resize='')
                    // v-text-field.mb-2(v-model.number='variant.stock' type='number' label='Stock actual' :disabled='loading' outlined='' :suffix='product.unit' :rules='rules.stock')
                    //v-checkbox(label='Incluye IGV' v-model='variant.include_igv')
                    //template(v-if='variant.include_igv')
                      v-divider
                      .my-3
                        span.igv-info IGV
                        span.igv-info-value S/ {{ igvAmount }}
                      v-divider
                      .my-3
                        span.igv-info Neto
                        span.igv-info-value S/ {{ brutePrice }}                  
                  //v-col(cols="6")  
                    // v-currency-field.mb-2(v-model='variant.sell_price' label='Precio de venta' :disabled='loading' outlined='' prefix='S/ ' :rules='rules.sell_price')
                    v-checkbox(hide-details='' label='Promoción activada' v-model='variant.offer_active' @change='validate')
                    v-radio-group(:disabled='!variant.offer_active' :rules='rules.offer_type' row='' v-model='product.offer_type')
                      v-radio(value='offer' label='Oferta')
                      v-radio.ml-5(value='season' label='Temporada')  
                    v-currency-field.mb-2(v-model='variant.offer_price' label='Precio de venta en oferta' :disabled='loading || !variant.offer_active' outlined='' prefix='S/ ')
                    v-row
                      v-col(cols='6')
                        v-menu(ref='menu1' v-model='menu1' :close-on-content-click='false' transition='scale-transition' offset-y='' max-width='290px' min-width='auto')
                          template(v-slot:activator='{ on, attrs }')
                            v-text-field(v-model='formattedStartOfferDay' label='Fecha inicio de la promoción' outlined='' :disabled='loading || !variant.offer_active' append-icon='mdi-calendar' v-bind='attrs' :rules='rules.start_offer_date' v-on='on')
                          v-date-picker(v-model='variant.start_offer_date' no-title='' @input='menu1 = false')
                      v-col(cols='6')
                        v-menu(ref='menu1' v-model='menu2' :close-on-content-click='false' transition='scale-transition' offset-y='' max-width='290px' min-width='auto')
                          template(v-slot:activator='{ on, attrs }')
                            v-text-field(v-model='formattedEndOfferDay' label='Fecha fin de la promoción' :disabled='loading || !variant.offer_active' outlined='' :rules='rules.end_offer_date' append-icon='mdi-calendar' v-bind='attrs' v-on='on')
                          v-date-picker(v-model='variant.end_offer_date' no-title='' @input='menu2 = false')
      v-card-actions.justify-center.pb-4
        v-btn.mr-15(color='primary' outlined @click='dialog = false') CANCELAR
        v-btn(color='primary' @click='saveVariant' :loading="loading" :disabled="!valid") CONFIRMAR
    
</template>

<script>
import moment from 'moment'
export default {
  data(){
    return {
      dialog: false,
      product: {
        name: ''
      },
      action: '',
      imageProductFile: null,
      imageProductURL: null,
      errorMessage: '',
      valid: false,
      loading: false,
      variantIndex: 0,
      menu1: false,
      menu2: false,
      variant: {
        name: "",
        sku: "",
        description: "",
        stock: 0,
        active: true,
        sell_price: 0.0,
        include_igv: false,
        igv_amount: 0,
        brute_price: 0,
        photo: null,
        created_at: null,
        offer_active: false,
        offer_price: 0.0,
        offer_type: "offer",
        start_offer_date: null,
        end_offer_date: null,
      },
      rules: {
        name: [
          (v) => !!v || "Debe ingresar un nombre del producto",
          (v) => v.length <= 100 || "Máximo 100 caracteres",
        ],
        sku: [
          (v) => !!v || "Debe ingresar un SKU",
          (v) => v.length <= 20 || "Máximo 20 caracteres",
        ],
        description: [(v) => !!v || "Debe ingresar una descripción"],
        subcategory: [(v) => !!v || "Debe seleccionar una subcategoría"],
        unit: [(v) => !!v || "Debe seleccionar una unidad de medida"],
        stock: [(v) => v > 0 || "Debe ingresar un stock válido"],
        sell_price: [
          (v) => {
            return v > 0 || "Debe ingresar un precio válido";
          },
        ],
        start_offer_date: [
          (v) => {
            if (this.variant.offer_active) {
              let offerMoment = moment(this.variant.start_offer_date);
              return offerMoment.isValid() || "Ingrese una fecha válida";
            }
            return true;
          },
        ],
        end_offer_date: [
          (v) => {
            if (this.variant.offer_active) {
              let endMoment = moment(this.variant.end_offer_date);
              let startMoment = moment(this.variant.start_offer_date);
              if (startMoment.isSameOrBefore(endMoment)) {
                return true;
              }
              return "La fecha de fin debe ser mayor que la fecha de inicio";
            }
            return true;
          },
        ],
        offer_type: [
          (v) => {
            if (this.variant.offer_active) {
              return !!v || "Seleccione un tipo de promoción";
            }
            return true;
          },
        ],
      },
    }
  },
  computed:{
    brutePrice() {
      return (
        Math.round(
          (this.variant.sell_price - this.igvAmount + Number.EPSILON) * 100
        ) / 100
      );
    },
    igvAmount() {
      if (!this.variant.include_igv) {
        return 0;
      }
      return (
        Math.round((this.variant.sell_price * 0.18 + Number.EPSILON) * 100) /
        100
      );
    },
    formattedStartOfferDay() {
      let offerMoment = moment(this.variant.start_offer_date);
      if (offerMoment.isValid()) {
        return offerMoment.format("DD/MM/YYYY");
      }
      return "00/00/0000";
    },
    formattedEndOfferDay() {
      let offerMoment = moment(this.variant.end_offer_date);
      if (offerMoment.isValid()) {
        return offerMoment.format("DD/MM/YYYY");
      }
      return "00/00/0000";
    },
  },
  methods:{
    validate() {
      this.$refs.form.validate();
    },
    
    open(product, action, variant, index){
      this.product = product
      this.action = action
      this.variantIndex = index
      if(this.action === this.$enums.ACTIONS.EDIT){
        this.variant = JSON.parse(JSON.stringify(variant))
      }
      if(this.action === this.$enums.ACTIONS.CREATE){
        this.variant = {
          name: "",
          sku: "",
          description: "",
          stock: 0,
          active: true,
          sell_price: 0.0,
          include_igv: false,
          igv_amount: 0,
          brute_price: 0,
          photo: null,
          created_at: null,
          offer_active: false,
          offer_price: 0.0,
          offer_type: "offer",
          start_offer_date: null,
          end_offer_date: null,
        }
      }
      this.dialog = true
    },
    async saveVariant(){
      try {
        this.loading = true
        this.variant.igv_amount = this.igvAmount;
        this.variant.brute_price = this.brutePrice;
        this.$emit('done', {
          variant: this.variant,
          index: this.variantIndex,
          action: this.action
        })
      } catch (error) {
        
      }
      this.loading = false
      this.dialog = false
      
    }
  }
}
</script>

<style lang="scss">
.product-view-dialog {
  .igv-info {
    width: 100px;
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    color: #818685;
  }
  .sku--text {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #1f3333;
    margin-left: 10px;
  }
  .product-item__info {
    height: 44px;

    border-bottom: 1px solid #b9c3c2;
    display: flex;
    align-items: center;
    .info-field {
      width: 140px;
      font-size: 12px;
      line-height: 18px;
      color: #818685;
    }
    .value-field {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 24px;
      color: #343837;
      .append-content {
        color: #561fdb;
      }
    }
  }
  .product-image {
    border: 1px solid #b9c3c2;
  }

  .igv-info-value {
    font-size: 12px;
    line-height: 18px;
    color: #343837;
  }
  .footer-products {
    height: 72px;
    display: flex;
    justify-content: flex-end;
    padding-right: 85px;
    align-items: center;
    border-top: 2px solid #f2f3f2;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .hint {
    font-size: 12px;
    line-height: 18px;
    color: #818685;
  }
}
</style>