<template>
  <div class="fill-height">
    <drawer-menu />
    <v-main class="fill-height">
      <router-view />
    </v-main>
  </div>
</template>

<script>
import DrawerMenu from '../components/init/DrawerMenu.vue'
import Appbar from '../components/init/Appbar.vue'

export default {
  components: { DrawerMenu, Appbar },
  data () {
    return {}
  },
}
</script>

<style>
</style>
