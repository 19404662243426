<template lang="pug">
v-container(fluid)
  dialog-info(ref="dialogInfo")
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title Movimientos de Inventario
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  input(type="file" v-show="false" @change="uploadExcel" ref="input" accept=".xlsx, .xls, .csv")

  v-row 
    v-col(cols="4")
      v-btn(small color="green" @click="$refs.input.click()" outlined)
        v-icon(left) mdi-file-excel
        | Subir Excel

      v-btn.ml-8(small text color="info" @click="downloadExcelTemplate")
        | Descargar Plantilla

    v-col(cols="4" offset="4")      
      v-btn(:disabled="!valid || items.length === 0" color="primary" @click="saveMovements") GUARDAR MOVIMIENTOS
    
  v-form(v-model="valid" )
    v-row    
      v-col(cols="12")
        v-row.pb-4(v-for="item in items" :key="item.id")
          v-col.py-0(cols='2')
            v-select(hide-details outlined :items="$enums.INVENTORY_TYPES_COMBO" @change="item.sku = null" rounded v-model='item.inventory_type' :rules='[$rules.required]' label='Tipo de movimiento')
          v-col.py-0(cols='2')
            v-autocomplete(v-if="item.inventory_type === $enums.INVENTORY_TYPES.RAW_MATERIAL" hide-details outlined rounded :items="products" :rules='[$rules.required]' item-value="sku" item-text="name" v-model='item.sku' label='Producto')
            v-autocomplete(v-if="item.inventory_type === $enums.INVENTORY_TYPES.FINAL_PRODUCT" hide-details outlined rounded :items="finalProducts" :rules='[$rules.required]' item-value="sku" item-text="name" v-model='item.sku' label='Producto')
          v-col.py-0(cols='2')
            v-select(hide-details outlined :items="$enums.MOVEMENT_TYPES" rounded v-model='item.movement_type' :rules='[$rules.required]' label='Tipo de movimiento')
          v-col.py-0(cols='2' v-if="item.movement_type === 'INCOMING'")
            v-select(hide-details outlined :items="$enums.INCOMING_REASONS" rounded v-model='item.movement_concept' :rules='[$rules.required]' label='Motivo')
          v-col.py-0(cols='2' v-if="item.movement_type === 'OUTGOING'")
            v-select(hide-details outlined :items="$enums.OUTGOING_REASONS" rounded v-model='item.movement_concept' :rules='[$rules.required]' label='Motivo')
          template(v-if="item.movement_type === 'INCOMING'")
            v-col.py-0(cols='2')
              v-text-field(hide-details outlined rounded :min="0" :rules='[$rules.numeric,$rules.minValue(item.qty,0)]' v-model.number='item.qty' label='Cantidad')
            v-col.py-0(cols='2' v-if="item.movement_concept !== 'REINGRESS' && item.inventory_type !== $enums.INVENTORY_TYPES.FINAL_PRODUCT")
              v-currency-field(hide-details  outlined prefix="S/" :min="0" :rules="[$rules.numeric,$rules.minValue(item.unit_cost,0)]" label="Costo unitario" v-model.number="item.unit_cost")
          template(v-if="item.movement_type === 'OUTGOING'")
            v-col.py-0(cols='2')
              v-text-field(hide-details outlined rounded :min="0" :rules='[$rules.numeric,$rules.minValue(item.qty,0)]' v-model.number='item.qty' label='Cantidad')
          //v-col.py-0(cols="2")
            v-text-field(hide-details v-model="item.obs" label="Observación (opcional)" rounded outlined)
    v-col(cols="12")
      v-btn(color="info" small @click="addMovement")
        v-icon mdi-plus 
        | Agregar movimiento
</template>
<script>
import DialogInfo from '../../../components/shared/DialogInfo'
export default {
  components: { DialogInfo },
  data() {
    return {
      generalLoading: false,
      products: [],
      items: [],
      finalProducts: [],
      valid: false,
    };
  },
  methods: {
    addMovement() {
      this.items.push({
        id: this.$uid(),
        movement_datetime: new Date(),
        movement_timestamp: 0,
        movement_type: null,
        inventory_type: 'raw-material', 
        movement_concept: null,
        user_info: null,
        sku: this.defaultSku,
        qty: 0,
        unit_cost: 0,
        total_cost: 0,
        ending_balance: null,
      });
    },
    async saveMovements(){
      this.generalLoading = true
      try {
        await this.$store.dispatch('createMassiveMovements', this.items)
        this.$router.push('/inventario')
      } catch (error) {
        this.$refs.dialogInfo.open(
          'No se pudo completar la operación',
          error.response.data.error_message
        )
      }
      this.generalLoading = false
    },
    downloadExcelTemplate(){
      window.open('https://storage.googleapis.com/quenco-peru.appspot.com/plantilla_masiva_nuevo_2.xlsx', '_target')
    },
    async uploadExcel(e){
      this.generalLoading = true
      const file = e.target.files[0]
      if(file){
        try {          
          const {data} = await this.$store.dispatch('excelMassiveMovements', file)
          if(data.status === 'FAIL') {
            const errors =  data.errors.map((e)=> `<div>${e}</div>`).join('')
            this.$refs.dialogInfo.open(
              'No se pudo completar la operación',
              errors
            )
          } else {
            this.items.push(...data.movements)
          }
        } catch (error) {
          this.$refs.dialogInfo.open(
            'No se pudo completar la operación',
            error.response.data.error_message
          )
        }
      }
      e.target.value = null
      this.generalLoading = false
    }
  },
  async beforeMount() {
    this.generalLoading = true;
    this.products = await this.$store.dispatch(
      "loadProductsSimple"
    );
    this.finalProducts = await this.$store.dispatch(
      "listFinalProducts"
    );
    this.generalLoading = false;
  },
};
</script>
