import firebase from "firebase/app";
import Axios from "axios";
import utilities from "./utilities";
import moment from "moment";
import Vue from "vue";
const db = firebase.firestore();
export default {
  state: {},
  mutations: {},
  actions: {
    async getInventoryMovements({}, filter) {
      let { data } = await Axios.get("v1/admin/inventory-movements", {
        params: filter,
      });
      return data;
    },
    async createInventoryMovement({}, data) {
      return await Axios.post("v1/admin/inventory-movements", data);
    },
    async createMassiveMovements({}, data) {
      return await Axios.post("v1/admin/inventory-movements/massive", {
        movements: data,
      });
    },
    async excelMassiveMovements({}, file) {
      let formData = new FormData();
      formData.append('file', file);

      return await Axios.post("v1/admin/inventory-movements/excel", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

    },
  },
  getters: {},
};
