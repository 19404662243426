<template>
  <quenco-drawer
    ref="editDrawer"
    title="Editar cliente"
    @actionPerformed="editCustomer"
    :disableActionButton="!valido"
    actionButton="GUARDAR"
    class="drawer-customer"
  >
    <div slot="content" class="px-0 px-sm-10">
      <v-form v-model="valido" ref="form">
        <v-row class="mt-5">
          <v-col cols="6" class="py-0">
            <v-text-field
              outlined
              rounded
              v-model="customer.contact_name"
              :rules="rules.contact_name"
              label="Nombre y apellido de contacto"
              counter="50"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              outlined
              rounded
              :rules="rules.phone"
              v-model="customer.phone"
              label="Teléfono"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              outlined
              rounded
              :rules="rules.email"
              v-model="customer.email"
              type="email"
              label="Correo de contacto"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-select
              outlined
              rounded
              label="Tipo de documento"
              v-model="customer.doc_type"
              :rules="rules.doc_type"
              :items="$enums.DOC_TYPES"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              outlined
              rounded
              :rules="rules.doc_number"
              v-model="customer.doc_number"
              label="RUC/DNI"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              outlined
              rounded
              :rules="rules.business_name"
              v-model="customer.business_name"
              label="Nombre del negocio/Razon social"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-select
              label="Distrito de su local"
              :items="distritos"
              v-model="customer.business_zone"
              rounded
              item-text="name"
              :rules="rules.distrito"
              outlined
              return-object
            >
            </v-select>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              outlined
              rounded
              :rules="rules.fulladdress"
              v-model="customer.fulladdress"
              label="Dirección de su negocio"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-select
              outlined
              rounded
              :rules="rules.business_type"
              v-model="customer.business_type"
              :items="businessTypes"
              label="Tipo de negocio"
            />
          </v-col>
          <div class="divider"></div>          
        </v-row>        
      </v-form>
      <v-row class="mt-5">
        <v-col cols="6">
          <v-select
            outlined
            rounded
            v-model="customer.delivery_price_type"
            :items="delivery_types"
            @change="revalidate"
            label="Tipo de negocio"
          />
        </v-col>
        <v-col cols="6">
          <v-currency-field
            :disabled="customer.delivery_price_type === 'zones'"
            v-model="customer.fixed_delivery_cost"
            label="Costo de delivery"
            outlined
            class="mb-2"
            prefix="S/ "
          />
        </v-col>
      </v-row>
      <div class="red--text">{{ errorMessage }}</div>
    </div>
  </quenco-drawer>
</template>

<script>
import QuencoDrawer from '../shared/QuencoDrawer.vue'
import distritos from '../../../ubigeo/distritos'
export default {
  components: { QuencoDrawer },
  computed: {
    distritos () {
      return distritos.filter(d => d.province_id == this.provinceID)
    }
  },
  methods: {
    revalidate(){
      this.$refs.form.validate()
    },
    open (customer) {
      this.clearForm()
      this.loading = false
      this.errorMessage = ''
      this.customer = JSON.parse(JSON.stringify(customer))
      if(this.customer.delivery_price_type == ''){
        this.customer.delivery_price_type = 'zones'
      }
      this.$refs.editDrawer.setVisible(true)
    },
    clearForm () {
      this.customer.contact_name = ''
      this.customer.phone = ''
      this.customer.email = ''
      this.customer.doc_number = ''
      this.customer.delivery_price_type = 'zones'
      this.customer.fixed_delivery_cost = 0.0
      this.customer.business_name = ''
      this.customer.business_type = ''
      
      if (this.$refs.form){
        console.log(this.$refs.form)
        this.$refs.form.resetValidation()
      }
    },
    validateEmail (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    async editCustomer () {
      this.loading = true
      this.errorMessage = ''
      try {
        let response = await this.$store.dispatch(
          'updateCustomer',
          this.customer
        )
        console.log(response)
        if (response.has_error) {
          this.errorMessage = response.error_message
          this.$refs.editDrawer.stopLoading()
        } else {
          this.$refs.editDrawer.setVisible(false)
          this.$refs.editDrawer.stopLoading()
          this.$emit('done')
        }

        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
        this.errorMessage = error.response.data.error_message
      }
      this.loading = false
    }
  },
  data () {
    let empty = {
        contact_name: '',
        phone: '',
        email: '',
        doc_number: '',
        delivery_price_type: 'zones',
        fixed_delivery_cost: 0.0,
        business_name: '',
        business_type: ''
      }
    return {
      show: false,
      repeatPassword: '',
      valido: false,
      errorMessage: '',
      departmentID: '15',
      provinceID: '1501',
      loading: false,
      showpassword1: false,
      showpassword2: false,
      emptyCustomer: empty,
      delivery_types:[
        {text: 'Estándar (por distrito)', value: 'zones'},
        {text: 'Preferencial', value: 'fixed'},
      ],
      businessTypes: [
        { text: 'Restaurante', value: 'restaurante' },
        { text: 'Hotel', value: 'hotel' },
        { text: 'Minimarket', value: 'minimarket' },
        { text: 'Bodega', value: 'bodega' },
        { text: 'Otros', value: 'otros' }
      ],
      rules: {
        contact_name: [
          v => !!v || 'Ingrese su nombre completo',
          v => v.length <= 50 || 'Ingrese máximo 50 caracteres'
        ],
        phone: [
          v => !!v || 'Ingrese su número de teléfono',
          v => v.length <= 9 || 'Ingrese máximo 9 caracteres'
        ],
        email: [
          v =>
            this.validateEmail(v) ||
            'Ingrese un formato de correo electrónico correcto'
        ],
        doc_type: [v => !!v || 'Seleccione el tipo de documento'],
        fulladdress: [
          v => !!v || 'Ingrese la dirección del local',
          v => v.length <= 100 || 'Ingrese máximo 100 caracteres'
        ],
        doc_number: [
          v => !!v || 'Ingrese su DNI/RUC',
          v => v.length <= 11 || 'Ingrese máximo 11 caracteres'
        ],
        fixed_delivery_cost: [
          v => {
            console.log("--------------------------------")
            console.log("validation")
            console.log(v)
            console.log(this.customer.delivery_price_type)
            console.log(v > 0 )
            if(this.customer.delivery_price_type == 'zones'){
              return true
            }
            return v > 0 || 'Debe ingresar un precio válido'
          }
        ],
        district: [v => !!v || 'Seleccione el distrito'],
        business_name: [
          v => !!v || 'Ingrese el nombre del negocio',
          v => v.length <= 100 || 'Ingrese máximo 100 caracteres'
        ],
        business_type: [v => !!v || 'Seleccione el tipo de negocio'],
        password: [v => v.length >= 6 || 'Ingrese mínimo 6 caracteres'],
        repeatPassword: [
          v => v.length >= 6 || 'Ingrese mínimo 6 caracteres',
          v => this.customer.password == v || 'Las contraseñas deben coincidir'
        ]
      },
      customer: {
        contact_name: '',
        phone: '',
        email: '',
        doc_number: '',
        fulladdress :'',
        delivery_price_type: 'zones',
        fixed_delivery_cost: 0.0,
        business_name: '',
        business_type: '',
        password: ''
      }
    }
  }
}
</script>

<style lang="scss">
.drawer-customer{
  width: 580px !important;
  .divider{
    border-bottom: 2px dashed #9CD6BF;
    width: 100%;
  }
}
.quenco-card {
  .form-title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #1f3333;
  }
  .v-text-field--rounded {
    border-radius: 12px;
  }
}
</style>
