<template lang="pug">
tr
  td.text-left {{ item.name }}
  td.text-center
    v-text-field(hide-details min="0" prefix="S/" label="Cantidad nota de crédito" type="number" v-model.number="item.sell_price" :disabled="disabled" dense outlined :suffix="item.unit")
  td.text-center {{ item.qty_ordered }}
  td.text-center {{ item.qty_rejected }}
  td 
    v-text-field(hide-details min="0" label="Cantidad nota de crédito" :max="item.qty_ordered" type="number" v-model.number="item.qty_canceled" :disabled="disabled" dense outlined)

</template>

<script>
export default {
  props:{
    item:{
      required: true,
      type: Object
    },
    disabled: {
      require: false,
      type: Boolean,
      default: false
    }
  },
  methods:{
  }
}
</script>

<style>

</style>