import firebase from "firebase/app";
const storage = firebase.storage();
import {uid} from 'uid';

export default {
  async putFile(file) {
    let uidCode = uid()
    let newID = uidCode+"."+file.name.split('.').pop();
    let thumbnailID = uidCode+"_150x150"+"."+file.name.split('.').pop();
    var storageRef = storage.ref();
    // Create a reference to 'images/mountains.jpg'
    var imageRef = storageRef.child(newID);
    await imageRef.put(file)
    let url = await imageRef.getDownloadURL()
    var imageRef = storageRef.child(thumbnailID);
    let base64Image = await this.imageToBase64(file)
    let resizedImage = await this.resizeImage(base64Image)
    //console.log(imageRef.fullPath)
    await imageRef.putString(resizedImage,"data_url")
    let thumbnail_url = await imageRef.getDownloadURL()
    return {
        object_id:newID,
        photo_url:url,
        thumbnail_url: thumbnail_url
    }
  },
  
  resizeImage(base64Str) {
    var img = new Image();
    img.src = base64Str;
    var canvas = document.createElement('canvas');
    var MAX_WIDTH = 150;
    var MAX_HEIGHT = 150;
    var width = img.width;
    var height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);
    return canvas.toDataURL();
  },
  imageToBase64 (file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader()
      reader.onload = e => {
        resolve(e.target.result)
      }
      reader.readAsDataURL(file)
    })
  },
  async wrapResponseAndGetData(axiosRequest){
      try {
          return axiosRequest.data
      } catch (error) {
          throw error
      }
  }
};
