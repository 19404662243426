import utilities from "./utilities";
import Axios from "axios";
export default {
  actions: {
    async putBusiness({}, payload) {
      return await utilities.wrapResponseAndGetData(
        await Axios.put("v1/admin/businesses", payload)
      );
    },
    async listBusinesses({}, payload) {
      return await utilities.wrapResponseAndGetData(
        await Axios.get(`v1/admin/businesses`, { params: payload })
      );
    },
    async getBusiness({}, id) {
      return await utilities.wrapResponseAndGetData(
        await Axios.get(`v1/admin/businesses/${id}`)
      );
    },
    async listBusinessTemplate({}, id) {
      return await utilities.wrapResponseAndGetData(
        await Axios.get(`v1/admin/businesses/${id}/business-templates`)
      );
    },
    async putBusinessTemplate({}, { id, payload }) {
      return await utilities.wrapResponseAndGetData(
        await Axios.put(`v1/admin/businesses/${id}/business-templates`, payload)
      );
    },
    async listBusinessHubs({}, id) {
      return await utilities.wrapResponseAndGetData(
        await Axios.get(`v1/admin/businesses/${id}/business-hubs`)
      );
    },
    async putBusinessHub({}, { id, payload }) {
      return await utilities.wrapResponseAndGetData(
        await Axios.put(`v1/admin/businesses/${id}/business-hubs`, payload)
      );
    },
    async deleteBusinessHub({}, { businessId, hubId }) {
      return await utilities.wrapResponseAndGetData(
        await Axios.delete(
          `v1/admin/businesses/${businessId}/business-hubs/${hubId}`
        )
      );
    },
    async postDownloadFactura({dispatch},ok_response){
        const response = await dispatch('postConsultarPdfTeFacturo', ok_response)
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${ok_response.serie}_${ok_response.numero}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
    },
    async postBusinessOrder({}, payload) {
      return await utilities.wrapResponseAndGetData(
        await Axios.post("v1/admin/business-orders", payload)
      );
    },
    async putBusinessOrder({}, payload) {
      return await utilities.wrapResponseAndGetData(
        await Axios.put("v1/admin/business-orders", payload)
      );
    },
    async patchBusinessOrderReview({}, payload) {
        return await utilities.wrapResponseAndGetData(
          await Axios.patch("v1/admin/business-orders/review", payload)
        );
      },
    async postBusinessOrderEmitirFactura({},id){
        return await utilities.wrapResponseAndGetData(
            await Axios.post(`v1/admin/business-orders/${id}/emitir-factura`)
          );
    },
    async getBusinessOrder({}, id) {
      return await utilities.wrapResponseAndGetData(
        await Axios.get("v1/admin/business-orders/" + id)
      );
    },
    
    async listBusinessOrders({}, payload) {
      return await utilities.wrapResponseAndGetData(
        await Axios.get("v1/admin/business-orders",{
          params: payload
        })
      );
    },
    async postEmitirNotaCredito({}, order){
      return Axios.post("v1/admin/business-orders/emitir-nota-credito", order)
    },
    async getBusinessUsers({}, businessId){
      let data = await utilities.wrapResponseAndGetData(
        await Axios.get(`v1/admin/businesses/${businessId}/users`)
      );
      console.log('getBusinessUsers')
      console.log(data)
      return data
    }
  },
};
