import Axios from "axios";
import utilities from "./utilities";
export default {
  actions: {
    async listCarriers({}, search) {
      return utilities.wrapResponseAndGetData(
        await Axios.get("v1/admin/carriers", {
          params: search,
        })
      );
    },
    async getCarrier({}, id) {
      return utilities.wrapResponseAndGetData(
        await Axios.get(`v1/admin/carriers/${id}`)
      );
    },
    async putCarrier({}, carrier) {
      await Axios.put("v1/admin/carriers", carrier);
    },
    async removeCarrier({}, carrier) {
      await Axios.delete(`v1/admin/carriers/${carrier.id}`);
    },
  },
};
