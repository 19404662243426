<template lang="pug">
v-container.px-8.c-business-order-action
  v-row
    dialog-confirm(ref="confirmComplete" primaryText="Completar" secondaryText="Aún no" @actionPressed="completeOrder")
    dialog-confirm(ref="dialogFactura" primaryText="Emitir factura" secondaryText="Salir" @actionPressed="emitirFactura")
    dialog-confirm(ref="backDialog" primaryText="Salir" secondaryText="Quedarme aquí" @actionPressed="$router.push('/corporativo/rutas')")
    dialog-info(ref="dialogInfo")
    v-col(cols='12')
      v-toolbar(flat)
          .d-flex.align-center
            v-btn(icon @click="prevOrder")
              v-icon mdi-chevron-left
            span {{index == 0 ? 'Salir':'Pedido anterior'}}
          v-spacer
          .d-flex.align-center(v-if="index !== item.orders.length - 1")
            span Siguiente
            v-btn(icon @click="nextOrder")
              v-icon mdi-chevron-right
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row.pb-3
    v-col.py-0(cols="12" v-if="currentOrder")
      v-btn(color="primary" @click="openModalComplete" v-if="currentOrder.status === 'assigned' || currentOrder.status === 'delivered'") Completar
    v-col.py-0(cols="12" v-if="currentOrder")
      v-btn(color="green" dark @click="openFacturaModal" v-if="['reviewed','completed'].includes(currentOrder.status) && !currentOrder.has_billing") Emitir factura
    v-col.py-0.text-right(cols="12" v-if="currentOrder")
      v-btn(color="green" dark @click="downloadFactura" v-if="['reviewed','completed'].includes(currentOrder.status) && currentOrder.has_billing") Descargar factura
  v-row(wrap v-if="item")
    v-col.py-1(cols="12")
      h4 Detalle de ruta
    v-col.py-1(cols="4")
      span {{ routeTextFunction(item) }}
    //v-col.py-1(cols="4")
      span Estado: 
        span.font-weight-bold {{ $decoders.routeStatus(item.status) }}
  v-row.mt-3(wrap v-if="currentOrder")
    v-col.py-1(cols="12")
      h4 Detalle de la orden
    v-col.py-1(cols="4" v-if="currentOrder.business")
      span Empresa: {{currentOrder.business.business_name}}
    v-col.py-1(cols="4" v-if="currentOrder.hub")
      span Hub: {{currentOrder.hub.name}}  - {{currentOrder.hub.address}}
    v-col.py-1(cols="4" v-if="currentOrder")
      span Estado: {{$decoders.orderStatus(currentOrder.status)}}
  v-row.mt-3(wrap)
    v-col.py-1(cols="12")
      h4 Productos
    v-col(cols="12")
      v-simple-table
        template(v-slot:default='')
          thead
            tr
              th.text-left
                | Producto
              th.text-center(style="width:200px")
                | P. Unitario
              th.text-center(style="width:120px")
                | Cantidad entregada  
              th.text-center(style="width:160px")
                | Cantidad aceptada
              th.text-center(style="width:160px")
                | Cantidad devuelta
          tbody
            order-review-row(v-for='item in currentOrder.products' :disabled="!canEdit" v-if="currentOrder" :item="item" :key='item.sku')
            tr
</template>

<script>
import DialogConfirm from '../../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../../components/shared/DialogInfo.vue'
import OrderReviewRow from '../../../components/admin/business/OrderReviewRow.vue'
import moment from 'moment'

export default {
  components: { DialogInfo, DialogConfirm,OrderReviewRow },
  data () {
    return {
      loading: false,
      generalLoading: false,
      index: 0,
      item: {
        id: '',
        orders: []
      }
    }
  },
  methods: {
    revalidateForm(){
      this.$refs.form.validate()
    },
    nextOrder(){
      if(this.index !== this.item.orders.length - 1){
        this.index++
      }
    },
    prevOrder(){
      if(this.index === 0){
        this.$refs.backDialog.open('Salir','¿Seguro que deseas volver a las rutas?')
      } else {
        this.index--
      }
    },
    openModalComplete(){
      this.$refs.confirmComplete.open('', '¿Seguro que desea completar el pedido?. Esta opción no se puede deshacer.')
    },
    openFacturaModal(){
      this.$refs.dialogFactura.open('Emitir Factura', '¿Está seguro que desea emitir una factura por esta compra?')      
    },  
    async emitirFactura(){
      this.generalLoading = true
      
      try {
        const response = await this.$store.dispatch('postBusinessOrderEmitirFactura', this.currentOrder.id)
        console.log(response)
        this.item.orders[this.index].facturacion_tefacturo_response = response
        this.item.orders[this.index].has_billing = true
        
        await this.$store.dispatch('postDownloadFactura', this.item.orders[this.index].facturacion_tefacturo_response.ok_response)

      } catch (error) {
        let errorMessage = error
        if(error.response?.data?.error_message){
          errorMessage = error.response.data.error_message
        }
        this.$refs.dialogInfo.open('',errorMessage)
      }
      try {
        // MOVEMENTS
        const movements = [];
        this.currentOrder.products.forEach((product) => {
          if (product.sku) {
            if (product.qty_picked > 0) {
              movements.push({
                id: this.$uid(),
                movement_datetime: new Date(),
                movement_timestamp: 0,
                movement_type: "OUTGOING",
                movement_concept: "SELL",
                user_info: null,
                sku: product.sku,
                qty: product.qty_picked,
                unit_cost: 0,
                total_cost: 0,
                ending_balance: null,
              });
            }
          }
        })
        await this.$store.dispatch("createMassiveMovements", movements);
      } catch (error) {
        
      }
      this.$refs.dialogFactura.close()
      this.$refs.dialogFactura.stopLoading()
      this.generalLoading = false
    },
    async completeOrder(){
      this.generalLoading = true
      this.currentOrder.products.forEach((p)=>{
        p.qty_received = Number(p.qty_received)
        p.qty_picked = Number(p.qty_picked)
        p.qty_rejected = Number(p.qty_rejected)
      })
      let copy = JSON.parse(JSON.stringify(this.currentOrder))
      copy.status = 'reviewed'
      const response = await this.$store.dispatch('patchBusinessOrderReview', copy)
      console.log(response)
      this.item.orders[this.index].status = 'reviewed'
      this.$refs.confirmComplete.close()
      this.$refs.confirmComplete.stopLoading()
      this.generalLoading = false
    },
    async downloadFactura(){
      await this.$store.dispatch('postDownloadFactura', this.item.orders[this.index].facturacion_tefacturo_response.ok_response)
    },
    routeTextFunction(value){
      if(!value.carrier && !value.init_time){
        return ''
      }
      return `${value.carrier.fullname} - ${moment(value.init_time).format('DD/MM HH:mm')}`
    }
  },
  async beforeMount () {
    this.generalLoading = true
    this.item = await this.$store.dispatch('getRoute', this.$route.params.id)
    //await this.loadItems(true)
    this.generalLoading = false
  },
  computed: {
    title(){
      return this.action === 'create' ? 'NUEVA ORDEN':'EDITAR ORDEN'
    },
    currentOrder(){
      let data = this.item.orders[this.index]
      return data ? data : {products:[]}
    },
    totalSum(){
      let sum = 0
      if(!this.currentOrder){
        return 0
      }
      this.currentOrder.products.forEach((i)=> sum+=(i.qty_ordered*i.sell_price))
      return Number(sum).toFixed(2)
    },
    canEdit(){
      if(this.currentOrder == null){
        return false
      }
      if(['delivered','assigned'].includes(this.currentOrder.status)){
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss">
.c-business-order-action {
  .v-input--selection-control {
    margin-top: 0 !important;
  }
}
</style>
