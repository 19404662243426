<template>
  <div class="history-container">
    <v-row>
      <v-col cols="12" class="py-0">
        <v-row>
          <v-col class="text-right py-0">
            <div>
              <span class="history-current-month mr-5">{{
                currentMonthFormatted
              }}</span>
              <v-btn-toggle>
                <v-btn
                  width="33px"
                  height="33px"
                  color="white"
                  :disabled="loadingTable"
                  @click="previousMonth"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>

                <v-menu
                  ref="menu"
                  v-model="menuMonth"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="33px"
                      height="33px"
                      color="white"
                      icon
                      :disabled="loadingTable"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-calendar</v-icon></v-btn
                    >
                  </template>
                  <v-date-picker
                    v-model="currentMonth"
                    type="month"
                    no-title
                    scrollable
                    locale="ESP"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuMonth = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(currentMonth)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <v-btn
                  width="33px"
                  height="33px"
                  color="white"
                  :disabled="loadingTable"
                  @click="nextMonth"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-btn-toggle>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-row v-if="!loadingTable">
          <v-col cols="12" class="d-flex justify-space-between">
            <div style="width:450px !important;">
              <v-text-field
                hide-details
                v-debounce:200ms="rebote"
                outlined
                label="Buscar cliente por nombre, RUC, Nombre del negocio"
              ></v-text-field>
            </div>
            <div class="pt-3" v-if="hasSectionPermissions('amounts')">
              Ventas totales:
              <span class="title ml-1">
                S/ {{ history.total_earning.toFixed(2) }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :options.sync="dataTableOptions"
          :headers="headers"
          :server-items-length="totalOrders"
          :items="history.orders"
          :loading="loadingTable"
          locale="es-ES"
          no-data-text="Aún no tiene pedidos en el historial de este mes"
          loading-text="Cargando..."
          :footer-props="{
            'items-per-page-text': 'Ordenes por página',
            'items-per-page-options': [10, 25, 50]
          }"
        > 
          <template v-slot:[`item.products`]="{ item }">
            <div class="text-decoration-underline primary--text hover" @click="seeProducts(item)">Ver productos</div>
          </template>
          <template v-slot:[`item.total_cost`]="{ item }">
            S/ {{item.total_cost.toFixed(2) }}
          </template>
          <template v-slot:[`item.ordered_at`]="{ item }">
            {{ parseOrderedAt(item.ordered_at) }}
          </template>
          <template v-slot:[`item.payment_method`]="{ item }">
            {{ decodePaymentMethod(item.payment_method) }}
          </template>
          <template v-slot:[`item.tefacturo`]="{ item }">
            <div v-if="item.tefacturo_response">
                <div v-if="item.tefacturo_response.ok_response" class="text-center">
                  {{item.tefacturo_response.ok_response.serie}}-{{item.tefacturo_response.ok_response.numero}}
                </div>
                <div class="red--text text-decoration-underline hover" @click="$refs.dialogInfo.open('Error al emitir comprobante',item.tefacturo_response.error_message)" v-else>
                  Error al emitir
                </div>
            </div>
            <div v-if="item.tefacturo_response == null && item.declared_at != null">
              POR ENVIAR
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="primary--text" v-if="item.status == 'completed'">
              COMPLETADO
            </div>
            <div v-else-if="item.status == 'canceled'" @click="$refs.dialogInfo.open('Motivo de cancelación',item.cancel_reason)" class="text-decoration-underline red--text hover">
              CANCELADO
            </div>
            
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <see-products-dialog ref="productsDialog"/>
    <dialog-info ref="dialogInfo"/>
  </div>
</template>

<script>
import moment from 'moment'
import SeeProductsDialog from '../dialogs/SeeProductsDialog.vue'
import DialogInfo from '../../../shared/DialogInfo.vue'
moment.locale('es')

export default {
  components: { SeeProductsDialog, DialogInfo },
  data () {
    return {
      currentMonth: '',
      dataTableOptions: {},
      loadingTable: false,
      menuMonth: false,
      history: {
        total_earning: 0,
        orders: []
      },
      totalOrders: 0,
      loadExcel: false,
      headers: [
        {
          text: 'Nombre del cliente',
          align: 'start',
          sortable: false,
          value: 'billing.business_name',
          class: 'primary--text',
          width: '150px'
        },
        {
          text: 'RUC/DNI',
          align: 'start',
          sortable: false,
          value: 'customer.doc_number',
          class: 'primary--text'
        },
        {
          text: 'Fecha y hora',
          align: 'start',
          sortable: false,
          value: 'ordered_at',
          class: 'primary--text'
        },
        {
          text: 'Pedido',
          align: 'start',
          sortable: false,
          value: 'products',
          class: 'primary--text'
        },
        {
          text: 'Precio',
          align: 'start',
          sortable: false,
          value: 'total_cost',
          class: 'primary--text'
        },
        {
          text: 'Método de pago',
          align: 'start',
          sortable: false,
          value: 'payment_method',
          class: 'primary--text'
        },
        {
          text: 'Facturación',
          align: 'start',
          sortable: false,
          value: 'tefacturo',
          class: 'primary--text'
        },
        {
          text: 'Estado',
          align: 'start',
          sortable: false,
          value: 'status',
          class: 'primary--text'
        }
      ],
      search: {
        page: 1,
        items_per_page: 10,
        month: 0,
        year: 0,
        text: ''
      },
    }
  },
  computed: {
    currentMonthFormatted () {
      return moment(this.currentMonth, 'YYYY-MM')
        .format('MMMM YYYY')
        .toUpperCase()
    },
    startDateFormatted () {
      return moment(this.search.startDate, 'YYYY-MM-DD').format('DD MMM')
    },
    endDateFormatted () {
      return moment(this.search.endDate, 'YYYY-MM-DD').format('DD MMM')
    },
    fileName () {
      let monthString = moment(this.currentMonth, 'YYYY-MM')
        .format('MMMM-YYYY')
        .toUpperCase()
      return `reporte_ventas_${monthString}.xls`
    }
  },
  methods: {
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('pedidos',permissions)
    },
    seeProducts (order) {
      this.$refs.productsDialog.open(order)
    },
    decodePaymentMethod (paymentMethod) {
      return this.$decoders.paymentMethods(paymentMethod)
    },
    async rebote (text) {
      this.search.text = text
      await this.loadHistory()
    },
    startDownload () {
      this.loadExcel = true
    },
    finishDownload () {
      this.loadExcel = false
    },
    previousMonth () {
      let currentMonthMoment = moment(this.currentMonth, 'YYYY-MM')
      this.currentMonth = currentMonthMoment
        .subtract(1, 'month')
        .format('YYYY-MM')
    },
    nextMonth () {
      let currentMonthMoment = moment(this.currentMonth, 'YYYY-MM')
      this.currentMonth = currentMonthMoment.add(1, 'month').format('YYYY-MM')
    },
    parseOrderedAt (date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    async loadHistory () {
      if (!this.loadingTable) {
        this.loadingTable = true
        this.history.orders = []
        let result = await this.$store.dispatch('loadHistory', this.search)
        this.totalOrders = result.total_items
        this.history = result
        this.loadingTable = false
      }
    },
    async fetchFullHistory () {
      try {
        let result = await this.$store.dispatch('getFullHistory', this.search)
        return result
      } catch (error) {
        console.log(error)
      }
    }
  },

  async beforeMount () {
    this.currentMonth = moment().format('YYYY-MM')
    this.search.month = moment().month() + 1
    this.search.year = moment().year()

    await this.loadHistory()
  },
  watch: {
    currentMonth (currentMonth) {
      let currentMonthMoment = moment(currentMonth, 'YYYY-MM')
      this.search.month = moment(currentMonth, 'YYYY-MM').month() + 1
      this.search.year = moment(currentMonth, 'YYYY-MM').year()
      this.menuMonth = false
      this.loadHistory()
    },
    dataTableOptions: {
      handler () {
        this.search.page = this.dataTableOptions.page
        if (this.dataTableOptions.itemsPerPage) {
          this.search.items_per_page = this.dataTableOptions.itemsPerPage
        }

        this.loadHistory()
      },
      deep: true
    },
    'search.page': {
      handler () {
        this.loadHistory()
      },
      deep: true
    }
  }
}
</script>

<style>
.history-current-month {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-align: center;

  color: #263238;
}
.history-range {
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.075px;

  color: #666666;
}
</style>
