<template>
  <dialog-base ref="dialogHasChanges">
    <div
      slot="actions"
      style="width:100%;"
      class=" d-flex flex-column justify-center align-center px-10"
    >
      <v-btn
        block
        :loading="loading"
        color="primary"
        class="mb-3"
        @click="saveAndExit"
        >GUARDAR Y SALIR</v-btn
      >
      <v-btn
        block
        :loading="loading"
        outlined
        small
        @click="exit"
        color="primary"
        class="mb-3"
        >SALIR SIN GUARDAR</v-btn
      >
      <v-btn
        block
        :loading="loading"
        outlined
        small
        color="primary"
        @click="close"
        >CONTINUAR EDITANDO</v-btn
      >
    </div>
  </dialog-base>
</template>

<script>
import DialogBase from './DialogBase.vue'
export default {
  components: { DialogBase },
  data () {
    return {
      title: 'Salir sin guardar',
      content: 'Si decide salir los cambios realizados se perderán.',
      loading: false
    }
  },
  props:{
    loadingOnPress:{
      type: Boolean,
      default: true
    }
  },
  methods: {
    open () {
      this.loading = false
      this.$refs.dialogHasChanges.open(this.title,this.content)
    },
    close (emit) {
      this.$refs.dialogHasChanges.close()
      if(emit)
        this.$emit('close')
    },
    saveAndExit(){
      this.loading = this.loadingOnPress
      this.$emit('saveAndExit')
    },
    stopLoading(){
      this.loading = false
    },
    exit(){
      this.$refs.dialogHasChanges.close()
      this.$emit('exit')
    }
  }
}
</script>

<style></style>
