<template lang="pug">
v-container.px-8
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title Almacenes
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row     
    v-col.text-right(cols="12")      
      v-btn(@click="$refs.warehouseDetailsModal.open('create')" color="primary") NUEVO ALMACÉN
    v-col(cols="12")
      v-data-table(:headers="headers" :items="items" :loading="loading")
        template(v-slot:[`item.actions`]='{ item }')
          v-btn(icon @click="openEditHub(item)")
            v-icon mdi-pencil
  dialog-info(ref='dialogInfo') 
  warehouse-detail-modal(ref="warehouseDetailsModal" :businessId="businessId" @done="businessChanged")
</template>

<script>
import DialogConfirm from '../../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../../components/shared/DialogInfo.vue'
import WarehouseDetailModal from '../../../components/admin/business/WarehouseDetailModal.vue'
export default {
  components: { DialogInfo, DialogConfirm,  WarehouseDetailModal },
  data () {
    return {
      loading: false,
      generalLoading: false,
      items: [],
      businessId: null,
      toDelete: null,
      headers: [
        {
          text: 'Nombre del almacén',
          align: 'start',
          sortable: false,
          value: 'name',
          class: 'primary--text',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: 'primary--text',
        },
      ],
    }
  },
  methods: {
    openEditHub(item){
      this.$refs.warehouseDetailsModal.open('edit', JSON.parse(JSON.stringify(item)))
    },
    async businessChanged(){
      this.generalLoading = true
      this.items = await this.$store.dispatch('listWarehouses')
      this.generalLoading = false
      
    },    
  },
  async mounted(){
    try {
        this.generalLoading = true
        this.items = await this.$store.dispatch('listWarehouses')
        this.generalLoading = false
      } catch (error) {
        console.log("error en load items")
        console.log(error)
        this.generalLoading = false
      }      
  },
}
</script>

<style lang="scss">
.customer-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>
