<template>
  <quenco-drawer
    :actionButton="'GUARDAR'"
    class="subcategories-form"
    ref="quencoDrawer"
    :title="title"
    :disableActionButton="false"
    @actionPerformed="saveCategory"
  >
    <div class="subcategories-content" slot="content">
      <div class="category-name-container" v-if="hasSectionPermissions('edit')">
        <v-text-field
          :loading="loading"
          hide-details
          label="Nueva Categoría"
          class="mr-4"
          counter="20"
          @keypress.enter="addNewCategory"
          v-model="newCategory"
          outlined
        />
        <v-btn
          outlined          
          :loading="loading"
          color="primary"
          :disabled="newCategory.length == 0 || newCategory.length>20"
          
          @click="addNewCategory"
          >Añadir</v-btn
        >
      </div>
      <draggable
        v-if="category != null"
        
        ghost="ghost"
        :animation="200"
        v-model="category.subcategories"
      >
        <transition-group type="transition"  :name="!drag ? 'flip-list' : null">
          <subcategory-item
            @showErrorDelete="openErrorModal"
            @deleteSubcategory="deleteSubcategory"
            v-for="subcategory in category.subcategories"
            :subcategory="subcategory"
            :key="subcategory.id"
          />
        </transition-group>
      </draggable>
      <dialog-info ref="dialogInfo" />
    </div>
  </quenco-drawer>
</template>

<script>
import QuencoDrawer from '../shared/QuencoDrawer.vue'
import draggable from 'vuedraggable'
import SubcategoryItem from './SubcategoryItem.vue'
import DialogInfo from '../../shared/DialogInfo.vue'
export default {
  components: { QuencoDrawer, draggable, SubcategoryItem, DialogInfo },

  methods: {
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('categorias',permissions)
    },
    openErrorModal (subcategory) {
      this.$refs.dialogInfo.open(
        'Error al eliminar la subcategoría',
        `La subcategoría <b>${subcategory.name}</b> tiene productos asociados a él, por lo que no se puede eliminar.`
      )
    },
    deleteSubcategory (subcategory) {
      let index = this.category.subcategories.findIndex(
        s => s.id == subcategory.id
      )
      this.category.subcategories.splice(index, 1)
    },
    addNewCategory () {
      if (this.newCategory == '') {
        return
      }
      this.category.subcategories.push({
        id: this.$uid(),
        category_id: this.category.id,
        name: this.newCategory,
        edited: false
      })
      this.newCategory = ''
    },
    async saveCategory () {
      
      try {
        if(this.hasSectionPermissions('edit')){
          this.loading = true
          await this.$store.dispatch('updateCategory', {
            category: this.category
          })
          this.loading = false
          this.$refs.quencoDrawer.stopLoading()
          this.$refs.quencoDrawer.setVisible(false)
          this.$emit('forceReload')
        } else {
          this.$refs.quencoDrawer.stopLoading()
          this.$refs.quencoDrawer.setVisible(false)
        }
        
      } catch (error) {}
    },
    open (category) {
      this.category = JSON.parse(JSON.stringify(category))
      //this.category.created_at = new Date()
      this.$refs.quencoDrawer.setVisible(true)
      //
    }
  },
  data () {
    return {
      category: null,
      newCategory: '',
      drag: false,
      loading: false
    }
  },
  computed: {
    title () {
      if (this.category == null) {
        return 'Subcategorías'
      }
      return 'Subcategorías ' + this.category.name
    }
  },
  mounted () {}
}
</script>

<style lang="scss">
.subcategories-form {
  .subcategories-content {
    .category-name-container {
      height: 96px;
      background: #f4f9f7;
      display: flex;
      padding-left: 30px;
      align-items: center;
      .v-text-field {
        max-width: 200px;
      }
    }
  }
  
}
.flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
</style>
