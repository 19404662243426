<template lang="pug">
tr
  td.text-left {{ item.name }}
  td.text-center S/ {{ item.sell_price.toFixed(2) }} x {{item.unit}}
  td.text-center {{ item.qty_picked }}
  td 
    v-text-field(hide-details min="0" label="Cantidad aceptada" @input="acceptedChange" :max="item.qty_picked" type="number" v-model.number="item.qty_received" :disabled="disabled" dense outlined)
  td 
    v-text-field(hide-details min="0" label="Cantidad devuelta" @input="rejectedChange" :max="item.qty_picked" type="number" v-model.number="item.qty_rejected" :disabled="disabled" dense outlined)

</template>

<script>
export default {
  props:{
    item:{
      required: true,
      type: Object
    },
    disabled: {
      require: false,
      type: Boolean,
      default: false
    }
  },
  methods:{
    acceptedChange(value){
      let accepteds = value
      let rejecteds = Number(this.item.qty_picked) - accepteds
      if(rejecteds < 0){
        rejecteds = 0        
        accepteds = Number(this.item.qty_picked)
      }
      console.log(accepteds)
      console.log(rejecteds)
      this.item.qty_received = Number(accepteds)
      this.item.qty_rejected = Number(rejecteds)   
      console.log(this.item)   
    },
    rejectedChange(value){
      let rejecteds = value
      let accepteds = Number(this.item.qty_picked) - rejecteds
      if(accepteds < 0){
        rejecteds = this.item.qty_picked        
        accepteds = 0
      }
      this.item.qty_received = Number(accepteds)
      this.item.qty_rejected = Number(rejecteds)  
    }
  }
}
</script>

<style>

</style>