<template lang="pug">
v-dialog(v-model="show" max-width="500px" persistent)
  v-card.py-2
    v-card-title.d-flex.justify-space-between
      h3.text-center {{ title }}
    v-card-text
      v-form(v-model="valid" ref="form")
        v-text-field(v-model="form.concept" :rules="[$rules.required]" label="Concepto" outlined dense)
        v-text-field(v-model="form.supplier" label="Proveedor (opcional)" outlined dense)
        v-currency-field(dense outlined prefix="S/" :min="0" :rules="[$rules.numeric,$rules.minValue(form.total_price,0)]" label="Total de la compra" v-model.number="form.total_price")
    .py-3.d-flex.justify-center
      v-btn(color="primary" outlined @click="close") Cerrar
      v-btn.ml-8(color="primary" @click="confirmed" :loading="loading" :disabled="!valid") {{ action === 'create' ? 'Crear' : 'Editar' }}
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          id: null,
          concept: "",
          supplier: "",
          total_price: 0,
        },
        show: false,
        loading: false,
        action: 'create',
        valid: false,
      };
    },
    computed: {
      title() {
        return this.action === 'create' ? 'NUEVO COSTO EXTRA' : 'EDITAR COSTO EXTRA';
      }
    },
    methods: {
      close() {
        this.show = false;
        this.loading = false;
      },
      open(action, item) {
        this.action = action;
        if (action === 'edit') {
          this.form = { ...item };
        } else {
          this.form = {
            id: null,
            concept: "",
            supplier: "",
            total_price: 0,
          };
        }
        this.show = true;
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      },
      async confirmed() {
        this.loading = true;
        try {
          await this.$store.dispatch('upsertPurchaseExtraCost', { purchaseId: this.$route.params.id, payload: this.form });
          this.$emit('done');
          this.close();
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .text-center {
    text-align: center;
  }
  </style>
  