<template>
  <second-container
    :showFooter="true"
    class="webpage-container"
    title="Página web"
  >
    <template slot="content">
      <input
        accept="image/x-png,image/gif,image/jpeg"
        type="file"
        @change="changeFile"
        ref="imageSelector"
        class="d-none"
      />
      <v-col cols="12" class="px-0 py-0">
        <div>Foto de la categoría</div>
        <div
          class="dragzone mt-5 d-flex justify-center"
          @click="$refs.imageSelector.click()"
          @dragover.prevent
          @drop.stop.prevent="dropImage"
        >
          <div
            v-if="loading"
            style="width:80%"
            class="align-self-center text-center"
          >
            <v-progress-linear v-model="percentaje" color="primary" />
          </div>
          <div v-if="imagePreview == null && !loading" class="noImage">
            <v-icon color="#B9C3C2" size="64px">mdi-plus</v-icon>
            <div class="mt-4">Arrastre y suelte la foto aquí</div>
            <div>o</div>
            <div class="text-select">
              SUBE UNO DE TUS ARCHIVOS
            </div>
          </div>
          <img
            width="100%"
            height="250px"
            style="border-radius:20px;"
            v-if="imagePreview && !loading"
            :src="imagePreview"
          />
          <v-btn
            style="position:absolute; right:10px; top:20px; background-color:white;"
            icon
            v-if="imagePreview && !loading"
            color="white"
            @click="removeImage(index)"
            ><v-icon color="primary">mdi-delete</v-icon></v-btn
          >
        </div>

        <div class="hint mt-4">
          Las imágenes deben ser en formato .jpeg y no debe pasar los 3M.
        </div>
        <v-row class="mt-3">
          <v-col
            @click="index = i"
            :key="image"
            v-for="(image, i) in config.banners"
            cols="3"
          >
            <div class="banner-item px-0 py-0">
              <img width="100%" height="100%" :src="image" />
              <v-btn
                style="position:absolute; right:10px; top:20px; background-color:white;"
                icon
                color="white"
                @click="removeImage(i)"
                ><v-icon color="primary">mdi-delete</v-icon></v-btn
              >
            </div>
          </v-col>
          <v-col cols="3">
            <div
              class="banner-item px-0 py-0 d-flex justify-center"
              @click="openNewImage"
            >
              <v-icon color="primary" size="36px">mdi-plus</v-icon>
            </div>
          </v-col>
        </v-row>
        <div class="red--text text-center mt-8">
          {{ errorMessage }}
        </div>
      </v-col>
      <dialog-info ref="dialogInfo" />
      
    </template>

    <template slot="footer-content">
      <v-btn :loading="loading" color="primary" class="ml-5" @click="save"
        >GUARDAR CAMBIOS</v-btn
      >
    </template>
  </second-container>
</template>

<script>
import SecondContainer from '../SecondContainer.vue'
const MAX_SIZE_FILE = 3000000
import firebase from 'firebase/app'
const storage = firebase.storage()
import { uid } from 'uid'
import DialogConfirmEdit from '../../shared/DialogConfirmEdit.vue'
import DialogInfo from '../../shared/DialogInfo.vue'
export default {
  components: { SecondContainer, DialogConfirmEdit, DialogInfo },
  beforeMount () {
    this.config = JSON.parse(JSON.stringify(this.oldConfig))
  },
  data () {
    return {
      errorMessage: '',
      index: 0,
      percentaje: 0,
      loading: false,
      key: 1,
      config: {
        id: null,
        zones: [],
        banners: []
      }
    }
  },
  props: {
    oldConfig: {
      type: Object,
      required: true
    }
  },
  computed: {
    imagePreview () {
      this.key
      return this.config.banners[this.index]
    }
  },
  methods: {
    async save () {
        this.loading = true
        try {
            await this.$store.dispatch('putConfig',this.config)
            this.$toast.success('Configuración guardada satisfactoriamente')
            this.$emit('saveDone')
        } catch (error) {
            this.$refs.dialogInfo.open('No se pudo completar la operación',error.response.data.error_message)
        }
        this.loading = false
        
    },
    hasChanged () {
      let string1 = JSON.stringify(this.oldConfig)
      let string2 = JSON.stringify(this.config)
      return string1 != string2
    },
    openNewImage () {
      this.index = this.config.banners.length
      this.key++
      this.$refs.imageSelector.click()
    },
    removeImage (index) {
      this.config.banners.splice(index, 1)
      this.key++
    },
    saveImageWithoutResize (file) {
      this.loading = true
      let uidCode = uid()
      let newID = uidCode + '.' + file.name.split('.').pop()
      var storageRef = storage.ref()
      // Create a reference to 'images/mountains.jpg'
      var imageRef = storageRef.child(newID)
      let uploadTask = imageRef.put(file)
      uploadTask.on(
        'state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          this.percentaje = progress
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running')
              break
          }
        },
        error => {
          // Handle unsuccessful uploads
          console.log(error)
          this.loading = false
        },
        async () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          var downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          console.log('finished')
          if (this.index == this.config.banners.length) {
            this.config.banners.push(downloadURL)
          } else {
            this.config.banners[this.index] = downloadURL
          }

          this.percentaje = 0
          this.loading = false
          this.key++
        }
      )
    },
    fileValid (file) {
      const fileType = file['type']
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']
      if (!validImageTypes.includes(fileType)) {
        return {
          valid: false,
          message: 'El archivo no es una imagen válida'
        }
      }
      if (file.size > MAX_SIZE_FILE) {
        return {
          valid: false,
          message: 'El archivo debe pesar menos de 3MB'
        }
      }
      return {
        valid: true,
        message: ''
      }
    },
    async changeFile (event) {
      let file = event.target.files[0]
      let response = this.fileValid(file)
      if (response.valid) {
        await this.saveImageWithoutResize(file)
      } else {
        this.errorMessage = response.message
      }
    },
    async dropImage (event) {
      event.preventDefault()
      if (event.dataTransfer.files.length > 0) {
        let file = event.dataTransfer.files[0]
        let response = this.fileValid(file)
        if (response.valid) {
          await this.saveImageWithoutResize(file)
        } else {
          this.errorMessage = response.message
        }
      }
    },
    imageToBase64 (file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = e => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          resolve(e.target.result)
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(file)
      })
    }
  }
}
</script>

<style lang="scss">
.webpage-container {
  .dragzone {
    height: 250px;
    background: #ffffff;
    border: 2px dashed #b9c3c2;
    position: relative;
    border-radius: 20px;
    &:hover {
      cursor: pointer;
    }
    .noImage {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      font-size: 14px;
      line-height: 24px;
      text-align: center;

      color: #818685;
      .text-select {
        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: #561fdb;
      }
    }
  }
  .banner-item {
    height: 80px;
    background: #ffffff;
    border-radius: 16px;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
