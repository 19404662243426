<template>
  <div class="input-schedule__container">
    <div class="input-time__container">
      <input type="time" v-model="initTime" />
    </div>
    <span>a</span>
    <div class="input-time__container">
      <input type="time" v-model="finishTime" />
    </div>
    <v-icon @click="$emit('deleteRange')" size="15" class="delete-icon">mdi-delete</v-icon>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    rangeTime: { type: Object, required: true }
  },
  methods: {
    minutesToTimeFormat (total_minutes) {
      let minutes = total_minutes % 60
      let hours = Math.floor(total_minutes / 60)
      return `${hours < 10 ? '0' + hours : hours}:${
        minutes < 10 ? '0' + minutes : minutes
      }`
    }
  },
  computed: {
    initTime: {
      set (hms) {
        if (moment(hms, 'HH:mm', true).isValid()) {
          var a = hms.split(':')
          var minutes = +a[0] * 60 + +a[1]
          this.rangeTime.start_minutes = minutes
        } else {
          this.rangeTime.start_minutes = null
        }
      },
      get () {
        if (this.rangeTime.start_minutes == null) {
          return '-- : --'
        }
        return this.minutesToTimeFormat(this.rangeTime.start_minutes)
      }
    },
    finishTime: {
      set (hms) {
        if (moment(hms, 'HH:mm', true).isValid()) {
          var a = hms.split(':')
          var minutes = +a[0] * 60 + +a[1]
          this.rangeTime.end_minutes = minutes
        } else {
          this.rangeTime.end_minutes = null
        }
      },
      get () {
        if (this.rangeTime.end_minutes == null) {
          return '-- : --'
        }
        return this.minutesToTimeFormat(this.rangeTime.end_minutes)
      }
    }
  }
}
</script>

<style lang="scss">
.input-schedule__container {
  display: flex;
  width: 100%;
  justify-content: center;
  border: 1px solid #b9c3c2;
  border-radius: 8px;
  padding: 5px;
  align-items: center;
  margin-bottom: 14px;
  position: relative;
  .delete-icon {
    bottom: -6px;
    right: -6px;
    position: absolute;
  }
  span {
    font-size: 12px;
    margin: 0 2px;
    opacity: 0.5;
  }
  .input-time__container {
    width: 50px;
    overflow-x: hidden;

    input {
      font-weight: normal;
      font-size: 12px;
      color: #343837;
      display: block !important;
      &:focus {
        outline: none;
      }
      &::-webkit-calendar-picker-indicator {
        background: none;
      }
    }
  }
}
</style>
