var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show-supplier-container"},[_c('div',{staticClass:"supplier-item__info",staticStyle:{"border-top":"1px solid #B9C3C2"}},[_vm._m(0),_c('div',{staticClass:"value-field",staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(_vm._f("__normalizeKeyValue")(_vm.carrier.status)))])]),_c('div',{staticClass:"supplier-item__info"},[_vm._m(1),_c('div',{staticClass:"value-field",staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(_vm.carrier.doc_type))])]),_c('div',{staticClass:"supplier-item__info"},[_vm._m(2),_c('div',{staticClass:"value-field"},[_vm._v(_vm._s(_vm.carrier.doc_number))])]),_c('div',{staticClass:"supplier-item__info"},[_vm._m(3),_c('div',{staticClass:"value-field"},[_vm._v(_vm._s(_vm.carrier.fullname))])]),_c('div',{staticClass:"supplier-item__info"},[_vm._m(4),_c('div',{staticClass:"value-field"},[_vm._v(_vm._s(_vm.carrier.phone))])]),_c('div',{staticClass:"supplier-item__info"},[_vm._m(5),_c('div',{staticClass:"value-field"},[_vm._v(_vm._s(_vm.carrier.email))])]),_c('div',{staticClass:"supplier-item__info"},[_vm._m(6),_c('div',{staticClass:"value-field"},[_vm._v(_vm._s(_vm.decodeRegion(_vm.carrier.region_id)))])]),_c('div',{staticClass:"supplier-item__info"},[_vm._m(7),_c('div',{staticClass:"value-field"},[_vm._v(_vm._s(_vm.decodeProvincia(_vm.carrier.provincia_id)))])]),_c('div',{staticClass:"supplier-item__info"},[_vm._m(8),_c('div',{staticClass:"value-field"},[_vm._v(_vm._s(_vm.decodeDistrito(_vm.carrier.distrito_id)))])]),_c('div',{staticClass:"supplier-item__info"},[_vm._m(9),_c('div',{staticClass:"value-field"},[_vm._v(_vm._s(_vm.carrier.full_address))])]),_c('div',{staticClass:"supplier-item__info"},[_vm._m(10),_c('div',{staticClass:"value-field"},[_vm._v(_vm._s(_vm._f("__normalizeKeyValue")(_vm.carrier.origin)))])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-field"},[_c('span',[_vm._v("Estado")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-field"},[_c('span',[_vm._v("Tipo de documento")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-field"},[_c('span',[_vm._v("Número de documento")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-field"},[_c('span',[_vm._v("Nombre completo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-field"},[_c('span',[_vm._v("Teléfono de celular")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-field"},[_c('span',[_vm._v("Correo electrónico")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-field"},[_c('span',[_vm._v("Departamento")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-field"},[_c('span',[_vm._v("Provincia")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-field"},[_c('span',[_vm._v("Distrito")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-field"},[_c('span',[_vm._v("Dirección")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-field"},[_c('span',[_vm._v("Origen")])])
}]

export { render, staticRenderFns }