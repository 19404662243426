import Vue from 'vue'
import { VTextField } from "vuetify/lib"; //Globally import 
import VCurrencyField from "v-currency-field";
Vue.component("v-text-field", VTextField);

Vue.use(VCurrencyField, {
  locale: "es-PE",
  decimalLength: 2,
  autoDecimalMode: true,
  min: 0.0,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});