<template>
  <v-app-bar app dark clipped-left color="primary" flat dense>
    <v-app-bar-nav-icon @click.stop="changeDrawer" />
    <v-toolbar-title>
      {{title}}
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    changeDrawer () {
      this.$store.commit('changeDrawer')
    },
    logout () {
      this.$store.dispatch('logOut')
    }
  },
  computed: {
    title () {
      let selectedBusiness = this.$store.state.business.selectedBusiness
      if (selectedBusiness) {
        return selectedBusiness.commercial_name
      }
      return 'UMATEC TIENDAS'
    }
  }
}
</script>

<style></style>
