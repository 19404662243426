import Vue from 'vue'
import './plugins'
import router from './router'
import store from './store'
import vuetify from './vuetify';
import App from './App.vue'
// shared components
import DialogInfo from './components/shared/DialogInfo.vue'



Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  components:{DialogInfo},
  render: h => h(App)
}).$mount('#app')
