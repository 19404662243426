<template lang="pug">
v-container(fluid)
  input(type="file" v-show="false" @change="uploadChangePrincingExcel" ref="input" accept=".xlsx, .xls, .csv")
  v-dialog(v-model="modalMargin" width="400px")
    v-card 
      v-card-title.justify-center Ajustar Margen
      v-card-text 
        v-currency-field(outlined label="Margen de ganancia para todos los productos" suffix="%" v-model.number="general_margin" :min="0")
        .text-center
          v-btn(color="primary" @click="ajustMargin") AJUSTAR
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title Plantillas
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row 
    v-col(cols="4")
      v-select(dense label="Seleccione la empresa" item-value="id" :items="businesses" item-text="business_name" v-model="businessId" outlined hide-details @change="businessChanged")
    v-col(cols="5")
      v-btn(color="green" @click="downloadExcelTemplateForBusinessForCangePricing" plain)
        v-icon mdi-file-excel
        .font-weight-bold.text-decoration-underline Descargar Plantilla Excel 
        
    v-col(cols="3")      
      v-btn(color="primary" :disabled="!validSave" block @click="save") Guardar   
  v-row
    v-col.d-flex(cols="12")
      v-btn(color="info" @click="addTemplateProduct") Agregar producto
      v-spacer 
      v-btn(color="green" text @click="$refs.input.click()")
        v-icon mdi-file-excel 
        .text-decoration-underline Subir cambio de precios
      v-btn(color="green" text @click="openMarginModal") 
        .text-decoration-underline Ajustar Margen 
    v-col(cols="12" v-if="template")
      v-form(v-model="valid" ref="form")
        template-product-row(:item="item" v-for="(item,i) in template.products" :key="`${item.id}-${i}`" @delete="showDeleteProductModal")
  dialog-info(ref='dialogInfo') 
  dialog-confirm-edit(ref="dialogConfirmEdit" @saveAndExit="saveAndChange" @close="continueEditing" @exit="exitWithoutSave")
  dialog-confirm(ref="dialogConfirm" @actionPressed="confirmDelete") 
</template>

<script>
import ActionBusinessDrawer from '../../../components/admin/business/ActionBusinessDrawer.vue'
import DialogConfirm from '../../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../../components/shared/DialogInfo.vue'
import DialogConfirmEdit from '../../../components/shared/DialogConfirmEdit.vue'
import TemplateProductRow from '../../../components/admin/business/TemplateProductRow.vue'
import moment from 'moment'

export default {
  components: { DialogInfo, DialogConfirm, ActionBusinessDrawer, DialogConfirmEdit, TemplateProductRow },
  data () {
    return {
      loading: false,
      generalLoading: false,
      valid: false,
      items: [],
      businesses: [],
      template: null,
      templateCopy: null,
      oldBusinessId: null,
      businessId: null,
      toDelete: null,
      changingBusiness: false,
      modalMargin: false,
      general_margin: 5,
    }
  },
  methods: {
    ajustMargin(){
      this.template.products.forEach((templateProduct)=>{
        const inventoryProduct = this.products.find(p => p.sku == templateProduct.sku)
        if(inventoryProduct){
          const total_cost = inventoryProduct.cost_unit
          const desired_margin = this.general_margin
          templateProduct.sell_price = Number((((desired_margin/100) * total_cost) + total_cost).toFixed(2))
        }
      })
      this.modalMargin = false
      this.general_margin = 5
    },
    async downloadExcelTemplateForBusinessForCangePricing(){
      this.generalLoading = true
      const response = await this.$store.dispatch('downloadExcelTemplateForBusinessForChangePricing', this.businessId)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const now = moment(this.date).format("DD-MM-YYYY")

      const business = this.businesses.find(b => b.id === this.businessId)

      link.setAttribute('download', `plantilla_${business.business_name}_${now}.xlsx`); //or any other extension
      link.click();
      // this.$emit('actionPressed')
      this.generalLoading = false
    },
    async saveAndChange(){
      await this.$store.dispatch('putBusinessTemplate',{
        id: this.oldBusinessId,
        payload: this.template,
      })
      this.template = await this.$store.dispatch('listBusinessTemplate', this.businessId)
      this.templateCopy = JSON.parse(JSON.stringify(this.template))
      this.oldBusinessId = this.businessId
      this.$refs.dialogConfirmEdit.stopLoading()
      this.$refs.dialogConfirmEdit.close(false)
      this.changingBusiness = false
    },
    async save(){
      if(this.$refs.form){
        console.log(this.$refs.form.validate())
      }
      this.generalLoading = true
      await this.$store.dispatch('putBusinessTemplate',{
        id: this.businessId,
        payload: this.template,
      })
      this.templateCopy = JSON.parse(JSON.stringify(this.template))
      this.generalLoading = false
    },  
    continueEditing(){
      this.businessId = this.oldBusinessId
      this.changingBusiness = false
    },
    async exitWithoutSave(){
      this.generalLoading = true
      this.template = await this.$store.dispatch('listBusinessTemplate', this.businessId)
      this.templateCopy = JSON.parse(JSON.stringify(this.template))
      this.oldBusinessId = this.businessId
      this.generalLoading = false
      this.$refs.dialogConfirmEdit.stopLoading()
      this.$refs.dialogConfirmEdit.close(false)
      this.changingBusiness = false
    },
    showDeleteProductModal(item){
      this.toDelete = item
      this.$refs.dialogConfirm.open('Eliminar producto',`¿Seguro que desea eliminar el producto "<b>${item.name}</b>"?`)
    },  
    confirmDelete(){
      const index = this.template.products.findIndex((p) => p.id === this.toDelete.id)
      if(index !== -1){
        this.template.products.splice(index,1)
      }
      this.$refs.dialogConfirm.close()

    },
    templateHasChanged(){
      const copyTemplateString = JSON.stringify(this.templateCopy)
      const templateString = JSON.stringify(this.template)
      return copyTemplateString !== templateString
    },
    openMarginModal(){
      this.modalMargin = true
      this.general_margin = 5
    },  
    async businessChanged(value){
      if(!this.changingBusiness){
        this.changingBusiness = true
        console.log("businessChanged")
        if(this.templateHasChanged() && this.validSave){
          this.$refs.dialogConfirmEdit.open()
        } else {
          this.generalLoading = true
          this.template = await this.$store.dispatch('listBusinessTemplate', this.businessId)
          this.templateCopy = JSON.parse(JSON.stringify(this.template))
          this.oldBusinessId = this.businessId
          this.generalLoading = false
          this.changingBusiness = false
        }
      }
      
    },
    addTemplateProduct(){      
      this.template.products.push({
        id: this.$uid(),
        name: '',
        sell_price: null,
        sunat_code: '',
        related_sku: null,
        unit_base: null,
        equiv_amount: 0,
      })
    },
    async uploadChangePrincingExcel(e){
      this.generalLoading = true
      const file = e.target.files[0]
      if(file){
        try {          
          const {data} = await this.$store.dispatch('uploadPrincingTemplateExcel', file)
          console.log(data)
          const copyProducts = JSON.parse(JSON.stringify(this.template.products))
          data.forEach((p)=>{
            let findProduct = copyProducts.find(tp => tp.id === p.id)
            findProduct.sell_price = p.new_price
            console.log(findProduct)
          })
          this.template.products = copyProducts
        } catch (error) {
          console.log(error)
          this.$refs.dialogInfo.open(
            'No se pudo completar la operación',
            error.response.data.error_message
          )
        }
      }
      e.target.value = null
      this.generalLoading = false
    },
    async loadItems () {
        try {
          this.generalLoading = true
          let businesses = await this.$store.dispatch('listBusinesses')
          this.businesses = businesses
          if(this.businesses.length>0){
            this.businessId = this.businesses[0].id
            this.template = await this.$store.dispatch('listBusinessTemplate', this.businessId)
            this.templateCopy = JSON.parse(JSON.stringify(this.template))
            this.oldBusinessId = this.businesses[0].id
          }
          this.generalLoading = false
        } catch (error) {
          console.log("error en load items")
          console.log(error)
          this.generalLoading = false
        }      
    }
  },
  computed:{
    products(){
      return this.$store.state.finalProducts.finalProducts
    },
    validSave(){
      return this.valid && this.template.products.length > 0
    }
  },
  async beforeMount () {
    this.$store.dispatch('listFinalProducts')
    this.generalLoading = true
    await this.loadItems()
    this.generalLoading = false
    console.log(this.$rules)
  }
}
</script>

<style lang="scss">
.customer-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>
