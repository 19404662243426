<template>
  <v-container class="first-container px-15">
    <v-row>
      <v-col cols="12" class="px-0"> 
        <v-toolbar flat class="px-0">
          <v-toolbar-title>
            <span class="form-title">{{ title }}</span>
          </v-toolbar-title>
          <v-spacer />
          <slot name="top-button" />
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <slot name="content" />
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.first-container {
  .form-title {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 1px;
    color: #1f3333;
  }
  .v-toolbar__content{
    padding: 0;
  }
}
</style>
