<template lang="pug">
v-container.px-10(fluid='')
  dialog-expected-debt-payment-date(ref='dialogExpectedDebtPaymentDateRef' @update="paymentDateChanged")
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate='' size='64')
  v-row(wrap='')
    v-col(cols='12')
      v-toolbar(flat='')
        v-toolbar-title.title
          span Deudas
      v-divider(style='border-color: #B9C3C2;')
    v-col(cols="12")
      .d-flex
          h3 Total de la deuda S/ {{ totalDebt }}
          v-spacer
          v-btn(color="primary" icon @click="previousWeek")
            v-icon mdi-chevron-left
          .d-flex.align-center 
            h3 {{formatDateSimple(filter.start_date)}} - {{formatDateSimple(filter.end_date)}}
          v-btn(color='primary' icon @click="nextWeek")
            v-icon mdi-chevron-right
      v-divider.my-3
      .d-flex
        v-autocomplete.mr-5(
          dense
          hide-details
          style="max-width: 250px;" 
          outlined 
          v-model="local_filter.business_id"
          :items="businesses"
          item-value="id"
          label="Filtrar por empresa"
          item-text="debt_name"
          clearable
        )
        v-select(
          dense
          hide-details
          style="max-width: 250px;" 
          outlined 
          v-model="local_filter.debt_status"
          :items="states"
          label="Filtrar por estado"
        )
    v-col(cols='12' v-if="!showEmptyCard && hasSectionPermissions('list')")
      v-data-table(:options.sync='dataTableOptions' :headers='headers' :items='filteredOrders' :loading='loadingTable' :search="search.text" locale='es-ES' no-data-text='No tiene facturas en este rango de fechas' loading-text='Cargando...' :footer-props="{\
      'items-per-page-text': 'Ordenes por página',\
      'items-per-page-options': [1000]\
      }")
        template(v-slot:[`item.declared_at`]='{ item }')
          span {{formatDateSimple(item.declared_at)}}
        template(v-slot:[`item.total_debt`]='{ item }')
          b S/ {{formatNumber(item.total_debt)}}
        template(v-slot:[`item.expected_debt_payment_date`]='{ item }')
          div.text-center.hover.text-decoration-underline(@click="openDialogExpectedDebtPaymentDateRef(item)" v-if="item.debt_status === 'pending'") {{formatDateSimple(item.expected_debt_payment_date)}}
          div.text-center(v-else) {{formatDateSimple(item.expected_debt_payment_date)}}
          b.text-center(:class="debtInfo(item).colorClass" v-if="item.debt_status === 'pending'") {{debtInfo(item).text}}
        template(v-slot:[`item.has_billing`]='{ item }')
          b.text-decoration-underline.hover.green--text( @click="downloadBilling(item)") {{item.facturacion_tefacturo_response.ok_response.serie}}-{{item.facturacion_tefacturo_response.ok_response.numero}}
        template(v-slot:[`item.has_nota_credito`]='{ item }')
          b.text-decoration-underline(v-if="!item.nota_credito_tefacturo_response") SIN N.C
          b.text-decoration-underline.hover.green--text( @click="downloadNotaCredito(item)" v-else-if="item.nota_credito_tefacturo_response.ok_response") {{item.nota_credito_tefacturo_response.ok_response.serie}}-{{item.nota_credito_tefacturo_response.ok_response.numero}}
          b.text-decoration-underline.hover.red--text(v-else @click="$refs.dialogInfo.open('Error de facturación', item.nota_credito_tefacturo_response.error_message)") Error en la N.C
        template(v-slot:[`item.business_name`]='{ item }')
          span {{ item.business.business_name }} 
            span(v-if="item.hub") - ({{ item.hub.name }})    
        template(v-slot:[`item.debt_status`]='{ item }')
          div.red--text.text-decoration-underline.hover(@click="openDebtStatusModal(item)" v-if="item.debt_status === 'pending' || item.debt_status === ''") Por pagar
          div.green--text.text-decoration-underline.hover(@click="openDebtStatusModal(item)" v-if="item.debt_status === 'completed'") 
            | Pagado
  dialog-info(ref='dialogInfo')
  dialog-confirm(@actionPressed='confirmChangeStatus' maxWidth="400px" :primaryText="confirmChangeSecondaryText" ref='dialogConfirm')
</template>

<script>
import DialogConfirm from '../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../components/shared/DialogInfo.vue'
import DialogExpectedDebtPaymentDate from '../../components/admin/business/DialogExpectedDebtPaymentDate.vue';
import moment from 'moment'
export default {
  components: { DialogInfo, DialogConfirm, DialogExpectedDebtPaymentDate },
  data () {
    return {
      loading: false,
      generalLoading: false,
      dataTableOptions: {},
      loadingTable: false,
      items: [],
      showEmptyCard: false,
      toDelete: null,
      total_cost: 0,
      confirmChangeSecondaryText: '',
      local_filter: {
        business_id: null,
        debt_status: null
      },
      changeStatusObject: {
        order_id: null,
        debt_status: ''
      },
      filter: {
        start_date: null,
        end_date: null,
      },
      totalItems: 0,
      business: null,
      businesses: [],
      states: [
        { text: 'Cualquiera', value: null },
        { text: 'Pendiente', value: 'pending' },
        { text: 'Pagado', value: 'completed' }
      ],
      headers: [
        {
          text: "Empresa - (Hub)",
          align: "start",
          sortable: false,
          width: "250px",
          value: "business_name",
          class: "primary--text",
        },
        {
          text: "Fecha Facturación",
          align: "start",
          width: "100px",
          sortable: true,
          value: "declared_at",
          class: "primary--text",
        },
        {
          text: 'Monto Deuda',
          align: 'center',
          sortable: true,
          value: 'total_debt',
          class: 'primary--text',
        },
        {
          text: "Fecha de Pago esperado",
          align: "center",
          sortable: true,
          value: "expected_debt_payment_date",
          class: "primary--text",
        },
        {
          text: "Facturación",
          align: "start",
          sortable: false,
          value: "has_billing",
          class: "primary--text",
        },
        {
          text: "Nota de crédito",
          align: "start",
          sortable: false,
          value: "has_nota_credito",
          class: "primary--text",
        },
        {
          text: "Estado Deuda",
          align: "start",
          sortable: false,
          value: "debt_status",
          class: "primary--text",
        },
      ],
      filterItems: [
        { text: 'Todos', value: null },
        { text: 'Activos', value: true },
        { text: 'Inactivos', value: false }
      ],
      filterOffers: [
        { text: 'Todos', value: null },
        { text: 'Temporada', value: "season" },
        { text: 'Ofertas', value: "offer" }
      ],
      search: {
        text: '',
        active: null,
        docsPerPage: 20,
        page: 1,
        categoryID: null,
        offer_type: null
      },
      category: {
        name: '',
        id: '',
        subcategories: []
      }
    }
  },
  methods: {
    daysUntil(date1, date2) {
      // Convertir ambas fechas a solo la parte de fecha (sin horas)
      const firstDate = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
      const secondDate = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
      
      // Calcular la diferencia en milisegundos
      const differenceInTime = secondDate - firstDate;
      
      // Convertir la diferencia a días
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
      
      return differenceInDays;
    },
    openDialogExpectedDebtPaymentDateRef(item){
      this.$refs.dialogExpectedDebtPaymentDateRef.open(item)
    },
    openDebtStatusModal(item) {
      this.changeStatusObject.order_id = item.id
      this.changeStatusObject.debt_status = item.debt_status === 'pending' ? 'completed' : 'pending'
      if(item.debt_status === 'pending'){
        this.confirmChangeSecondaryText = 'Marcar como pagado'
        this.$refs.dialogConfirm.open(
          'Estado de la deuda',
          `La deuda de la empresa ${item.business.business_name} es de S/ ${item.total_debt} y se espera que sea pagada el ${this.formatDateSimple(item.expected_debt_payment_date)}.`
        )
      } else {
        this.confirmChangeSecondaryText = 'Marcar como pendiente'
        this.$refs.dialogConfirm.open(
          'Estado de la deuda',
          `La deuda de la empresa ${item.business.business_name} ha sido pagada el ${this.formatDateSimple(item.debt_paid_at)}.`
        )
      } 
    },
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('productos',permissions)
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    formatDateSimple(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    paymentDateChanged(item){
      const itemDebt = this.items.find(i => i.id === item.id)
      itemDebt.expected_debt_payment_date = item.expected_debt_payment_date+"T12:00:00Z"
    },
    async downloadBilling(item) {
      this.generalLoading = true;
      await this.$store.dispatch(
        "postDownloadFactura",
        item.facturacion_tefacturo_response.ok_response
      );
      this.generalLoading = false;
    },
    async downloadNotaCredito(item) {
      this.generalLoading = true;
      let copy = JSON.parse(
        JSON.stringify(item.nota_credito_tefacturo_response.ok_response)
      );
      copy.tipo_documento = "NOTACREDITO";
      await this.$store.dispatch("postDownloadFactura", copy);
      this.generalLoading = false;
    },
    updatePage (value) {
      this.search.page = value
      this.loadProducts()
    },
    updateItems (items) {
      this.search.docsPerPage = items
      this.loadProducts()
    },
    clickOnRow (product) {
      // let categoryID = this.$route.params.categoryID
      this.$router.push({
        path: `/materia-prima/${product.id}`,
        query: { action: this.$enums.ACTIONS.EDIT }
      })
    },
    async confirmChangeStatus (status) {
      try {
        await this.$store.dispatch('patchDebtStatus', this.changeStatusObject)
        this.$refs.dialogConfirm.stopLoading()
        this.$refs.dialogConfirm.close()
        const itemDebt = this.items.find(i => i.id === this.changeStatusObject.order_id)
        itemDebt.debt_status = this.changeStatusObject.debt_status
      } catch (error) {
        this.$refs.dialogConfirm.stopLoading()
        this.$refs.dialogConfirm.close()
        this.$refs.dialogInfo.open(
          'Error al marcar deuda',
          error.response.data.error_message
        )
      }
    },
    openDeleteDialog (item) {
      this.$refs.dialogConfirm.open(
        'Eliminar producto',
        'Si elimina el producto su información se borrará permanentemente del sistema.'
      )
      this.toDelete = item
    },
    async changeState (item) {
      try {
        this.loadingTable = true
        await this.$store.dispatch('changeProductState', {
          id: item.id,
          active: item.active
        })
        this.loadingTable = false
        this.calculateBusinessDebts()
      } catch (error) {
        this.$refs.dialogInfo(
          'Error al actualizar el estado del producto',
          error.response.data.error_message
        )
        this.loadingTable = false
      }
    },
    moveToCreateProduct () {
      this.$router.push({
        path: `/materia-prima/${this.$uid()}`,
        query: { action: this.$enums.ACTIONS.CREATE }
      })
    },
    setCurrentWeek() {
      // Establece la semana actual de lunes a domingo
      this.filter.start_date = moment().startOf('isoWeek').format('YYYY-MM-DD');
      this.filter.end_date = moment().endOf('isoWeek').format('YYYY-MM-DD');
    },
    nextWeek() {
      // Avanza una semana
      this.filter.start_date = moment(this.filter.start_date).add(1, 'week').format('YYYY-MM-DD');
      this.filter.end_date = moment(this.filter.end_date).add(1, 'week').format('YYYY-MM-DD');
      this.loadDebts()
    },
    previousWeek() {
      // Retrocede una semana
      this.filter.start_date = moment(this.filter.start_date).subtract(1, 'week').format('YYYY-MM-DD');
      this.filter.end_date = moment(this.filter.end_date).subtract(1, 'week').format('YYYY-MM-DD');
      this.loadDebts()
    },
    async loadDebts (firstLoad) {
      if (!this.loadingTable) {
        try {
          this.loadingTable = true
          const debts = await this.$store.dispatch('listDebts', this.filter)
          this.items = [...debts] 
          this.loadingTable = false
          this.generalLoading = false
          this.calculateBusinessDebts()
        } catch (error) {
          this.$refs.dialogInfo.open(
            'No se pudo listar los pedidos',
            error.response.data.error_message
          )
          this.loadingTable = false
          this.generalLoading = false
        }
      }
    },
    calculateBusinessDebts(){
      this.businesses.forEach(business => {
        let total_debt = 0
        this.items.forEach(item => {
            if(item.business.id === business.id){
              if(item.debt_status === 'pending'){
                total_debt += item.total_debt 
              }
            }
          })
          business.debt_name = `${business.business_name} - S/ ${this.formatNumber(total_debt)}`
          business.total_debt = total_debt
      })
      this.businesses.sort((a, b) => b.total_debt - a.total_debt)
    },  
    formatNumber(number){
      return number.toLocaleString('es-PE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    debtInfo(item) {
      const daysDebt = this.daysUntil(new Date(), new Date(item.expected_debt_payment_date));
      
      if (daysDebt < 0) {
        return {
          text: 'Vencido hace ' + Math.abs(daysDebt) + ' días',
          colorClass: 'red--text text--darken-2' // Color rojo para vencido
        };
      } else if (daysDebt === 0) {
        return {
          text: 'Hoy',
          colorClass: 'orange--text text--darken-2' // Color naranja para hoy
        };
      } else if (daysDebt === 1) {
        return {
          text: 'Mañana',
          colorClass: 'yellow--text text--darken-2' // Color amarillo para mañana
        };
      } else {
        return {
          text: `${daysDebt} días`,
          colorClass: 'green--text text--darken-2' // Color verde para días restantes
        };
      }
    },

  },
  computed:{
    totalDebt(){
      let subtotal = 0
      this.filteredOrders.forEach(item => {
        subtotal += item.total_debt
      })
      const formattedNumber = subtotal.toLocaleString('es-PE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedNumber
    },
    filteredOrders(){
      return this.items.filter(item => {
        let businessFilter = true
        let statusFilter = true
        if(this.local_filter.business_id){
          businessFilter = item.business.id === this.local_filter.business_id
        }
        if(this.local_filter.debt_status){
          statusFilter = item.debt_status === this.local_filter.debt_status
        }
        return businessFilter && statusFilter
      })
    },
    filteredBusinesses(){
      this.businesses.forEach(business => {
        let total_debt = 0
        this.items.forEach(item => {
          if(item.business.id === business.id){
            total_debt += item.total_debt
          }
        })
        business.total_debt = total_debt
        return business
      })
      return filtered.filter(business => business.total_debt > 0)
    } 
  },
  async beforeMount () {
    this.generalLoading = true
    this.setCurrentWeek()
    let result = await this.$store.dispatch('listBusinesses')
    this.businesses = result
    
    await this.loadDebts(true)

    this.generalLoading = false
  }
}
</script>

<style lang="scss">
.category-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>
