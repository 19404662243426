<template>
  <v-container fluid class="mx-0 px-0 py-0 config-view">
    <v-overlay color="white" :value="loading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="3" lg="3" class="py-0 px-0">
        <first-container title="Configuraciones">
          <template slot="content">
            <div class="list-config">
              <div
                class="config-item"
                @click="changeTab(item.value)"
                v-for="item in items"
                :key="item.value"
              >
                <template v-if="hasSectionPermissions(item.value)">
                  <v-icon
                    class="config-icon"
                    :class="{ active: currentTab == item.value }"
                    >{{ item.icon }}</v-icon
                  >
                  <span
                    class="config-name"
                    :class="{ active: currentTab == item.value }"
                    >{{ item.name }}</span
                  >
                </template>
                
              </div>
            </div>
          </template>
        </first-container>
      </v-col>
      <v-col cols="9" lg="9" class="py-0 px-0">
        <web-page
          @saveDone="onSaveDone"
          :oldConfig="config"
          ref="web"
          v-if="currentTab == 'web' && hasSectionPermissions('web')"
        />
        <delivery-config
          @saveDone="onSaveDone"
          :oldConfig="config"
          ref="web"
          v-if="currentTab == 'delivery' && hasSectionPermissions('delivery')"
        />
      </v-col>
    </v-row>
    <dialog-confirm-edit
      @exit="confirmChangeTab"
      ref="dialogConfirmEdit"
      @saveAndExit="confirmSave"
    />
  </v-container>
</template>

<script>
import DeliveryConfig from '../../components/admin/config/DeliveryConfig.vue'
import WebPage from '../../components/admin/config/WebPage.vue'
import FirstContainer from '../../components/admin/FirstContainer.vue'
import DialogConfirmEdit from '../../components/shared/DialogConfirmEdit.vue'

export default {
  components: { FirstContainer, WebPage, DialogConfirmEdit, DeliveryConfig },
  async mounted () {
    this.loading = true
    this.config = await this.$store.dispatch('getConfig')
    this.loading = false
  },
  methods: {
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('configuracion',permissions)
    },
    async confirmSave () {
      await this.$refs[this.currentTab].save()
      this.$refs.dialogConfirmEdit.close()
      this.confirmChangeTab()
    },
    confirmChangeTab () {
      this.currentTab = this.incomingTab
      this.incomingTab = ''
    },
    changeTab (tab) {
      if (this.currentTab != '') {
        if (this.$refs[this.currentTab]) {
          if (this.$refs[this.currentTab].hasChanged()) {
            this.incomingTab = tab
            this.$refs.dialogConfirmEdit.open()
          } else {
            this.currentTab = tab
          }
        } else {
          this.currentTab = tab
        }
      } else {
        this.currentTab = tab
      }
    },
    async onSaveDone () {
      this.currentTab = ''
      this.loading = true
      this.config = await this.$store.dispatch('getConfig')
      this.loading = false
    }
  },
  data () {
    return {
      currentTab: '',
      loading: false,
      incomingTab: '',
      config: {
        id: null,
        banners: [],
        zones: []
      },
      items: [
        {
          icon: 'mdi-web',
          name: 'Página web',
          value: 'web'
        },
        {
          icon: 'mdi-motorbike',
          name: 'Zonas de delivery',
          value: 'delivery'
        },
        /*{
          icon: 'mdi-account',
          name: 'Usuarios',
          value: 'users'
        }*/
      ]
    }
  }
}
</script>

<style lang="scss">
.config-view {
  .first-container{
    padding: 12px 40px !important;
  }
  .second-container{
    padding: 12px 30px 70px 30px!important;
  }
  .list-config {
    width: 100%;
    .config-item {
      padding: 20px 0 20px 5px;
      border-bottom: 1px solid #b9c3c2;
      &:hover {
        cursor: pointer;
      }
      .config-icon {
        margin-right: 10px;
        &.active {
          color: #561fdb;
        }
      }
      .config-name {
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        color: #818685;
        &.active {
          color: #561fdb;
        }
      }
    }
  }
}
</style>
