<template>
  <v-dialog
    persistent
    max-width="360px"
    overlay-color="#1F3333"
    overlay-opacity="0.9"
    v-model="show"
  >
    <v-card class="dialog-base-card">
      <v-card-title class="dialog-title">CANCELAR PEDIDO</v-card-title>
      <v-card-text class="content">
        <div>¿Está seguro que desea cancelar el pedido?</div>
        <v-textarea
          v-model="cancelReason"
          label="Motivo de la cancelación"
          class="mt-4"
          auto-grow
          no-resize
          hide-details
          rounded
          outlined
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <div
          style="width:100%;"
          class="d-flex justify-center align-center text-center"
        >
          <v-btn
            :loading="loading"
            @click="close"
            color="primary"
            outlined
            >SALIR</v-btn
          >
          <v-btn
            :loading="loading"
            @click="actionPress"
            color="red"
            dark
            class="ml-12"
            >RECHAZAR</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      order: null,
      cancelReason: '',
      loading: false
    }
  },
  methods: {
    open (order) {
      this.cancelReason = ''
      this.order = order
      this.show = true
    },
    close () {
      this.show = false
    },
    async actionPress () {
      try {
        this.loading = true
        await this.$store.dispatch('changeStatus', {
          order_id: this.order.id,
          status: 'canceled',
          cancel_reason: this.cancelReason
        })
        this.loading = false
        this.show = false
        this.$toast.open({
          message: 'La orden fue CANCELADA.',
          type: 'success'
        })
      } catch (error) {
        this.$toast.open({
          message: 'Hubo un error y no se realizó la operación',
          type: 'error'
        })

        this.loading = false
      }
      this.show = false
    }
  }
}
</script>

<style lang="scss">
.dialog-base-card {
  border-radius: 12px !important;
  .v-card__title {
    font-size: 24px !important;
    line-height: 36px;
    color: #1f3333;
    display: flex;
    justify-content: center;
    padding-top: 40px !important;
    text-align: center;
    word-break: break-word;
  }
  .content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1f3333 !important;
  }
  .v-card__actions {
    padding-bottom: 40px !important;
  }
}
</style>
