<template lang="pug">
  v-card(flat outlined)
    v-card-text 
      | {{note.content}}
      v-divider.my-1
      .grey--text.caption {{note.created_by}}
      small.grey--text.caption {{note.date | __stringToTime}}
</template>

<script>
export default {
  props:{
    note: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>