<template lang="pug">
quenco-drawer.drawer-business(ref='actionDrawer' :title='title' @actionPerformed='save' :disableActionButton='!valido' actionButton='GUARDAR')
  .px-0.px-sm-10(slot='content')
    v-form(v-model='valido' ref='form')
      v-row.mt-5
        v-col.py-0(cols='6')
          v-text-field(outlined rounded :rules='rules.business_name' v-model='item.business_name' label='Nombre del negocio')
        v-col.py-0(cols='6')
          v-text-field(outlined rounded v-model='item.internal_code' label='Código Interno (opcional)')
        v-col.py-0(cols='6')
          v-text-field(outlined rounded v-model='item.razon_social' :rules='rules.razon_social' label='Razón social')
        v-col.py-0(cols='6')
          v-text-field(outlined rounded :rules='rules.phone' v-model='item.phone' label='Teléfono')
        v-col.py-0(cols='6')
          v-text-field(outlined rounded :rules='rules.email' v-model='item.email' type='email' label='Correo de contacto')
        v-col.py-0(cols='6')
          v-text-field(outlined rounded :rules='rules.doc_number' v-model='item.doc_number' label='RUC')
        v-col.py-0(cols='6')
          v-text-field(outlined rounded :rules='rules.address' v-model='item.address' label='Dirección fiscal')
        v-col.py-0(cols='6')
          v-select(outlined rounded :items="paymenMethods" :rules='rules.payment_method' v-model='item.payment_method' label='Método de pago')
        v-col.py-0(cols='6' v-if="item.payment_method === 'CREDITO'")
          v-text-field(outlined type="number" rounded :rules='rules.days_of_credit' v-model.number='item.days_of_credit' label='Días de crédito')
        v-col.py-0(cols=12)
          v-checkbox(hide-details v-model="item.can_change_order_on_picking" label="Permitir cambio de orden durante revisión de picking")
        v-col.py-0(cols=12)
          v-checkbox(hide-details v-model="item.ignore_detraction" label="Ignorar la detracción")
    .red--text {{ errorMessage }}

</template>

<script>
import QuencoDrawer from '../shared/QuencoDrawer.vue'
import distritos from '../../../ubigeo/distritos'
export default {
  components: { QuencoDrawer },
  computed:{
    title(){
      return this.action === 'create'?'NUEVA EMPRESA':'EDITAR EMPRESA'
    }
  },
  methods: {
    revalidate(){
      this.$refs.form.validate()
    },
    open (action, item) {
      this.clearForm()
      this.action = action
      if(action === 'create') {
        this.item = {
          id: this.$uid(),
          business_name: '',
          internal_code: '',
          phone: '',
          email: '',
          address: '',
          doc_number:'',
          razon_social:'',
          days_of_credit: 0,
          payment_method: '',
          can_change_order_on_picking: false,
          ignore_detraction: false,
        }
      }
      if(action === 'edit'){
        this.item = JSON.parse(JSON.stringify(item))
      }
      
      this.loading = false
      this.errorMessage = ''
      this.$refs.actionDrawer.setVisible(true)
    },
    clearForm () {
      this.item = {
          id: null,
          business_name: '',
          internal_code: '',
          phone: '',
          email: '',
          doc_number:'',
          razon_social:'',
          days_of_credit: 0,
          payment_method: '',
          can_change_order_on_picking: false,
          ignore_detraction: false,
        }
      
      if (this.$refs.form){
        this.$refs.form.resetValidation()
      }
    },
    validateEmail (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    async save () {
      this.loading = true
      this.errorMessage = ''
      try {
        let response = await this.$store.dispatch(
          'putBusiness',
          this.item
        )
        if (response.has_error) {
          this.errorMessage = response.error_message
          this.$refs.actionDrawer.stopLoading()
        } else {
          this.$refs.actionDrawer.setVisible(false)
          this.$refs.actionDrawer.stopLoading()
          this.$emit('done')
        }

        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
        this.errorMessage = error.response.data.error_message
      }
      this.loading = false
    }
  },
  data () {
    let empty = {
        id: null,
        business_name: '',
        internal_code: '',
        phone: '',
        email: '',
        doc_number:'',
        address: '',
        razon_social:'',
        days_of_credit: 0,
        payment_method: '',
        ignore_detraction: false
      }
    return {
      show: false,
      repeatPassword: '',
      valido: false,
      errorMessage: '',
      departmentID: '15',
      provinceID: '1501',
      action: 'create',
      loading: false,
      emptyItem: empty,
      paymenMethods:['CONTADO','CREDITO'],
      rules: {
        address: [
          v => !!v || 'Ingrese dirección de la empresa',
        ],
        business_name: [
          v => !!v || 'Ingrese el nombre de la empresa',
          v => v.length <= 100 || 'Ingrese máximo 100 caracteres'
        ],
        razon_social: [
          v => !!v || 'Ingrese la razón social',
          v => v.length <= 100 || 'Ingrese máximo 100 caracteres'
        ],
        phone: [
          v => !!v || 'Ingrese su número de teléfono',
          v => v.length <= 9 || 'Ingrese máximo 9 caracteres'
        ],
        email: [
          v =>
            this.validateEmail(v) ||
            'Ingrese un formato de correo electrónico correcto'
        ],
        doc_number: [
          v => !!v || 'Ingrese el RUC',
          v => v.length <= 11 || 'Ingrese máximo 11 caracteres'
        ],
        payment_method: [
          v => !!v || 'Ingrese la forma de pago',
        ],
        days_of_credit: [
          v => v > 0 || 'Ingrese la cantidad de días del crédito',
        ],
      },
      item: {
        id: null,
        business_name: '',
        internal_code: '',
        phone: '',
        email: '',
        doc_number:'',
        razon_social:'',
        days_of_credit: 0,
        payment_method: '',
        ignore_detraction: false,
      }
    }
  }
}
</script>

<style lang="scss">
.drawer-business{
  width: 580px !important;
  .divider{
    border-bottom: 2px dashed #9CD6BF;
    width: 100%;
  }
}
.quenco-card {
  .form-title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #1f3333;
  }
  .v-text-field--rounded {
    border-radius: 12px;
  }
}
</style>
