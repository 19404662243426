<template>
  <v-dialog persistent :max-width="maxWidth" overlay-color="#1F3333" overlay-opacity="0.9"  v-model="show">
    <v-card class="dialog-base-card">
      <v-btn v-if="showClose" @click="show = false" icon class="btn-close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title class="dialog-title">{{ title }}</v-card-title>
      <v-card-text class="content" v-if="content" v-html="content">
      </v-card-text>
      <v-card-text class="content" v-else>
        <slot name="content" />
      </v-card-text>
      <v-card-actions>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showClose: { type: Boolean, default: false, required: false},
    maxWidth: {type: String, default: '320px', required: false }
  },
  data () {
    return {
      show: false,
      title: '',
      content: ''
    }
  },
  methods: {
    open (title, content) {
      this.title = title
      this.content = content
      this.show = true
    },
    close () {
      this.show = false
    }
  }
}
</script>

<style lang="scss">
.dialog-base-card {
  border-radius: 12px !important;
  position: relative;
  .btn-close{
    position: absolute;
    top: 8px;
    right: 12px;
  }
  .v-card__title {
    font-size: 24px !important;
    line-height: 36px;
    color: #1f3333;
    display: flex;
    justify-content: center;
    padding-top: 40px !important;
    text-align: center;
    word-break: break-word;
  }
  .content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1f3333 !important;
  }
  .v-card__actions {
    padding-bottom: 40px !important;
  }
}
</style>
