import Axios from "axios";
import utilities from "./utilities";

export default {
  actions: {
    async excelAcceptedOrders({}) {
      return await Axios.get("v1/admin/excel/acceptedOrders", {
        responseType: "blob",
      });
    },
    async excelBusinessOrdersByDate({}, date) {
      return await Axios.get("v1/admin/excel/business-orders-by-date", {
        params: { date },
        responseType: "blob",
      });
    },
    async excelBusinessOrdersByDispatchDate({}, date) {
      return await Axios.get("v1/admin/excel/businesses/download-products-by-dispatch", {
        params: { date },
        responseType: "blob",
      });
    },
    async excelProductsList({}, inventoryType) {
      console.log(inventoryType)
      return await Axios.get("v1/admin/excel/products-list", {
        params: { inventoryType },
        responseType: "blob",
      });
    },
    async excelDemand({}, payload) {
      return await Axios.get("v1/admin/excel/demand", {
        responseType: "blob",
        params: payload,
      });
    },
    // 	adminRoute.GET("/excel/businesses/:businessId/download-template-business", admin.GetExcelBusinessTemplate)
    //  adminRoute.POST("/excel/upload-template-business", admin.PostUploadExcelBusinessTemplate)
    async downloadExcelTemplateForBusiness({}, businessId) {
      return await Axios.get(
        `v1/admin/excel/businesses/${businessId}/download-template-business`,
        {
          responseType: "blob",
        }
      );
    },
    async downloadExcelTemplateForBusinessForChangePricing({}, businessId) {
      return await Axios.get(
        `v1/admin/excel/businesses/${businessId}/download-template-business-for-change-pricing`,
        {
          responseType: "blob",
        }
      );
    },
    async uploadExcelBusinessTemplate({}, file) {
      let formData = new FormData();
      formData.append("file", file);

      return await Axios.post(
        "v1/admin/excel/upload-template-business",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    async uploadPrincingTemplateExcel({}, file) {
      let formData = new FormData();
      formData.append("file", file);

      return await Axios.post(
        "v1/admin/excel/businesses/product-template-pricing-update",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  },
};
