const errorMap = new Map()
errorMap.set('auth/user-not-found',"No se encontró el usuario registrado")
errorMap.set('auth/wrong-password',"Contraseña incorrecta")
errorMap.set('auth/not-found',"No hay un usuario registrado con ese correo electrónico")
errorMap.set('auth/reset-token-not-found',"Su token de cambio de contraseña no existe")
errorMap.set('auth/reset-token-expired',"Su solicitud de cambio de contraseña expiró.")
export default function(code){
    let message = errorMap.get(code)
    if(!message){
        console.log("Sin mensaje: ",code)
        message = "Error: "+code 
    }
    return message
}