<template lang="pug">
v-container.px-8.customer-view(fluid)
  v-dialog(v-model="modalDownload" width="600px")
    v-card 
      v-card-title DESCARGA DE INFORMES 
      v-card-text 
        v-radio-group(v-model="downloadType" hide-details row)
          v-radio(label="Inventario actual" hide-details value="current_inventory") 
          v-radio(label="Ventas - Demanda" hide-details value="demand")        
        template(v-if="downloadType === 'current_inventory'") 
          v-divider.my-4
          .d-flex.align-center
            v-select(dense outlined hide-details label="Inventario" style="max-width: 200px;" v-model="modalCurrentInventoryFilter.inventoryType" :items="$enums.INVENTORY_TYPES_COMBO")
            v-btn(color="green" text :disabled="modalCurrentInventoryFilter.inventoryType == null" @click="downloadExcelProducts") 
              v-icon mdi-download
              | Descargar inventario
        template(v-if="downloadType === 'demand'") 
          v-divider.my-4
          .mb-3(style="display: flex; justify-content: center; column-gap: 1rem;")
            v-text-field(hide-details type="date" required v-model="demand_start_date" outlined label="Fecha inicio")
            v-text-field(hide-details type="date" required v-model="demand_end_date" outlined label="Fecha fin")
          v-btn(color="green" text :disabled="demand_end_date == null || demand_start_date == null" @click="downloadExcelDemand") 
            v-icon mdi-download
            | Descargar reporte
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title.d-flex.justify-space-between(style="width: 100%;")
          span  Inventario
          v-spacer 

          v-btn.mr-5(color="green" text @click="modalDownload = true") 
            v-icon mdi-download
            | Descarga de informes
          v-btn(color="primary"  small @click="$router.push('inventario/movimientos-masivo')") MULTIPLES MOVIMIENTOS
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row(wrap)
    v-col.d-flex.justify-space-between.align-center(cols='12')
      // v-text-field(hide-details style='width:450px; display:inline-block;' v-model="text" outlined label='Buscar producto por nombre')
      
    v-col(cols='12')
      .d-flex
        v-spacer 
  v-row
    v-col(cols="2")
      v-select(dense outlined hide-details label="Inventario" v-model="filter.inventory_type" @change="filter.sku = null" :items="inventoryTypesCombo")
    v-col(cols="2")
      v-select(dense outlined hide-details @change="filter.movement_concept = null" label="Movimiento" v-model="filter.movement_type" :items="movementsCombo")
    v-col(cols="2")
      v-select(dense outlined hide-details label="Motivo" v-model="filter.movement_concept" :items="movementsConceptCombo")
    v-col(cols="2")
      v-autocomplete(dense outlined hide-details :disabled="filter.inventory_type == null"  label="Producto" v-model="filter.sku" item-text="name" item-value="sku" :items="filteresProducts")
    v-col(cols="3")
      v-menu(ref="menu", v-model="menu", :close-on-content-click="false", transition="scale-transition", offset-y, min-width="auto")
        template(v-slot:activator="{ on, attrs }")
          v-text-field(v-model="dateRangeText", label="Rango de Fechas" dense hide-details outlined , readonly, v-bind="attrs", v-on="on")
        v-date-picker(v-model="dateRange" locale="es-ES" no-title range)
          v-btn(text='' color='primary' @click='cleanDates')
            | Limpiar fechas
          v-btn(text='' color='primary' @click='menu = false')
            | OK

    v-col(cols="1")
      v-btn(color="primary" @click="applyFilter" :loading="generalLoading" ) APLICAR
  v-row
    v-col(cols="12")
      v-data-table(:headers='headers' :items-per-page="-1" hide-default-footer :items='movements' :loading='loadingTable' locale='es-ES' no-data-text='Aún no hay movimientos registradas' loading-text="'Cargando...'")
        template(v-slot:[`item.inventory_type`]='{ item }')
          div {{ $enums.INVENTORY_TYPES_PARSER(item.inventory_type) }}
        template(v-slot:[`item.movement_type`]='{ item }')
          div {{ $enums.MOVEMENT_TYPES_PARSER(item.movement_type) }}
        template(v-slot:[`item.movement_concept`]='{ item }')
          div(v-if="item.movement_type === 'INCOMING'") {{ $enums.INCOMING_REASONS_PARSER(item.movement_concept) }}
          div(v-if="item.movement_type === 'OUTGOING'") {{ $enums.OUTGOING_REASONS_PARSER(item.movement_concept) }}
        template(v-slot:[`item.movement_datetime`]='{ item }')
          div {{ parseTime(item.movement_datetime) }}
        template(v-slot:[`item.movement`]='{ item }')
          div 
            b(style="display:inline-block;width: 80px; text-align:right;margin-right: 8px;") Cantidad: 
            span {{ item.qty }} {{ item.product.unit }}
          div
            b(style="display:inline-block;width: 80px; text-align:right;margin-right: 8px;") P. Unitario: 
            span S/ {{ item.unit_cost.toFixed(2) }}
          div
            b(style="display:inline-block;width: 80px; text-align:right;margin-right: 8px;") Total: 
            span S/ {{ item.total_cost.toFixed(2) }}
        template(v-slot:[`item.ending_balance`]='{ item }')
          div 
            b(style="display:inline-block;width: 80px; text-align:right;margin-right: 8px;") Cantidad: 
            span {{ item.ending_balance.qty }} {{ item.product.unit }}
          div
            b(style="display:inline-block;width: 80px; text-align:right;margin-right: 8px;") P. Unitario: 
            span S/ {{ item.ending_balance.unit_cost.toFixed(2) }}
          div
            b(style="display:inline-block;width: 80px; text-align:right;margin-right: 8px;") Total: 
            span S/ {{ item.ending_balance.total_cost.toFixed(2) }}
  dialog-info(ref='dialogInfo') 
  // action-business-drawer(ref='actionDrawer' @done='loadItems')

</template>

<script>
import ActionBusinessDrawer from '../../../components/admin/business/ActionBusinessDrawer.vue'
import DialogConfirm from '../../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../../components/shared/DialogInfo.vue'
import moment from 'moment'
export default {
  components: { DialogInfo, DialogConfirm, ActionBusinessDrawer },
  computed:{
    filteresProducts(){
      if(this.filter.inventory_type === this.$enums.INVENTORY_TYPES.RAW_MATERIAL){
        const data = JSON.parse(JSON.stringify(this.rawProducts))
        data.unshift({
          name: 'Cualquiera',
          sku: null
        })
        return data
      } else {
        const data = JSON.parse(JSON.stringify(this.finalProducts))
        data.unshift({
          name: 'Cualquiera',
          sku: null
        })
        return data
      }
    },
    inventoryTypesCombo(){
      const combo = JSON.parse(JSON.stringify(this.$enums.INVENTORY_TYPES_COMBO))
      combo.unshift({
        value: null,
        text: 'Cualquiera'
      })
      return combo
    },
    movementsCombo(){
      const combo = JSON.parse(JSON.stringify(this.$enums.MOVEMENT_TYPES))
      combo.unshift({
        value: null,
        text: 'Cualquiera'
      })
      return combo
    },
    movementsConceptCombo(){

      const comboIncoming = JSON.parse(JSON.stringify(this.$enums.INCOMING_REASONS))
      const comboOutgoing = JSON.parse(JSON.stringify(this.$enums.OUTGOING_REASONS))
      if(this.filter.movement_type ==  "INCOMING"){
        comboIncoming.unshift({
          value: null,
          text: 'Cualquiera'
        })
        return comboIncoming
      }
      else if(this.filter.movement_type ==  "OUTGOING"){
        comboOutgoing.unshift({
          value: null,
          text: 'Cualquiera'
        })
        return comboOutgoing
      }
      else {
        // Unir los arrays y eliminar duplicados
        const combinedArray = comboIncoming.concat(comboOutgoing).reduce((acc, current) => {
            // Si no encuentra un elemento con el mismo valor, lo añade al acumulador
            if (!acc.some(item => item.value === current.value)) {
                acc.unshift(current);
            }
            return acc;
        }, []);
        combinedArray.unshift({
          value: null,
          text: 'Cualquiera'
        })
        return combinedArray
      }
      return []
    },
  },    
  data () {
    return {
      loading: false,
      generalLoading: false,
      dataTableOptions: {},
      loadingTable: false,
      modalCurrentInventoryFilter:{
        inventoryType: null
      },
      rawProducts: [],
      dateRange: [],
      dateRangeText: '',
      menu: false,
      movements: [],
      options: {
        itemsPerPage: null,
      },
      filter:{
        movement_type: null,
        start_date: null,
        end_date: null,
        sku: null,
        inventory_type: null,
        movement_concept: null,
      },
      toDelete: null,
      totalItems: 0,
      downloadType: null,
      modalDownload: false,
      demand_start_date: null,
      demand_end_date: null,
      finalProducts: [],
      inventoryType: 'raw-material',
      headers: [
        {
          text: 'INVENTARIO',
          align: 'start',
          width: '140px',
          value: 'inventory_type',
          class: 'primary--text'
        },
        {
          text: 'PRODUCTO',
          align: 'start',
          width: '240px',
          value: 'product.name',
          class: 'primary--text'
        },
        {
          text: 'TIPO MOVIMIENTO',
          align: 'start',
          width: '155px',
          value: 'movement_type',
          class: 'primary--text'
        },
        {
          text: 'MOTIVO',
          align: 'start',
          width: '150px',
          value: 'movement_concept',
          class: 'primary--text'
        },
        {
          text: 'FECHA',
          align: 'start',
          width: '180px',
          value: 'movement_datetime',
          class: 'primary--text'
        },
        {
          text: 'MOVIMIENTO REALIZADO',
          align: 'start',
          value: 'movement',
          class: 'primary--text'
        },
        {
          text: 'SALDO FINAL',
          align: 'start',
          value: 'ending_balance',
          class: 'primary--text'
        },
      ],
      text: '',
    }
  },
  watch: {
  dateRange(newValue) {
    console.log('watch daterange')
    console.log(newValue)
    if (newValue.length === 2) {
      this.dateRangeText = this.formatDateRange(newValue);
    }
  }
},
  methods: {
    formatDateRange(dates) {
      console.log('formatDateRange')
      console.log(dates)
      return dates.map(date => this.formatDate(date)).join(' - ');
    },
    formatDate(dateStr) {
      if (!dateStr) {
        return '';
      }

      // Descomponer la fecha en partes
      const parts = dateStr.split('-');
      if (parts.length !== 3) {
        return ''; // Manejar error o cadena de fecha inválida
      }

      // Crear un nuevo objeto Date con año, mes y día, interpretado como fecha local
      const date = new Date(parts[0], parts[1] - 1, parts[2]);

      // Formatear fecha en la zona horaria local (Perú)
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'America/Lima'
      };

      return new Intl.DateTimeFormat('es-PE', options).format(date);
    },
    openEdit (item) {
      this.$refs.actionDrawer.open('edit',item)
    },
    parseTime(date){
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    cleanDates(){
      this.dateRange = []
      this.dateRangeText = ""
      this.menu = false
    },
    async applyFilter(){
      this.generalLoading = true
      if(this.dateRange.length == 2){
        this.filter.start_date = this.dateRange[0]
        this.filter.end_date = this.dateRange[1]
      } else {
        this.filter.start_date = null
        this.filter.end_date = null
      }
      this.movements = await this.$store.dispatch('getInventoryMovements', this.filter)
      console.log(this.movements)
      this.generalLoading = false
    },
    async downloadExcelProducts(){
      this.generalLoading = true
      const response = await this.$store.dispatch('excelProductsList', this.modalCurrentInventoryFilter.inventoryType)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const now = moment(this.date).format("DD-MM-YYYY")
      link.setAttribute('download', `inventario_al_${now}.xlsx`); //or any other extension
      link.click();
      // this.$emit('actionPressed')
      this.generalLoading = false
    },
    async downloadExcelDemand(){
      this.generalLoading = true
      const response = await this.$store.dispatch('excelDemand', {
        start_date: this.demand_start_date,
        end_date: this.demand_end_date
      })
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const start = moment(this.demand_start_date).format("DD-MM-YYYY")
      const end = moment(this.demand_end_date).format("DD-MM-YYYY")
      link.setAttribute('download', `demanda_del_${start}_al_${end}.xlsx`); //or any other extension
      link.click();
      // this.$emit('actionPressed')
      this.generalLoading = false
    }
  },
  async beforeMount () {
    
    this.generalLoading = true
    this.rawProducts = await this.$store.dispatch('loadProductsSimple')
    this.finalProducts = await this.$store.dispatch('listFinalProducts')
    this.generalLoading = false
  }
}
</script>

<style lang="scss">
.customer-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>
