import Axios from 'axios';
import utilities from './utilities'
export default {
  state:{
    productsSimple:[],
  },
  mutations:{
    setProductSimple(state, products){
      state.productsSimple = products
    },
  },
  actions: {
    async listProducts({}, search) {
        return utilities.wrapResponseAndGetData(await Axios.get("v1/admin/products",{
            params: search
        }))
    },
    async loadProductsSimple({commit}){
      let data = await utilities.wrapResponseAndGetData(await Axios.get("v1/admin/products/simple"))
      if(data == null){
        data = []
      }
      commit('setProductSimple',data)
      return data
    },
    async loadProductsSimpleWithVariants({commit}){
      let data = await utilities.wrapResponseAndGetData(await Axios.get("v1/admin/products/simple-variants"))
      if(data == null){
        data = []
      }
      commit('setProductSimple',data)
      return data
    },
    async uploadFile({}, file){
      return await utilities.putFile(file)
    },
    async createProduct({},payload){
      let newImage = payload.newImage
      let product = payload.product
      if(newImage){
        product.photo = await utilities.putFile(newImage)
      }
      await Axios.post("v1/admin/products",product)
    },
    async changeProductState({},payload){
     await Axios.patch("v1/admin/products/active",payload)
    },
    async editProduct({},payload){
      let newImage = payload.newImage
      let product = payload.product
      if(newImage){
        product.photo = await utilities.putFile(newImage)
      }
      await Axios.put(`v1/admin/products`,product)
    },
    async deleteProduct({},data){
      await Axios.delete(`v1/admin/products/${data.id}`)
    },
    async findProduct({},id){
      return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/products/${id}`))
    },
    async nextSKU({},name){
      return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/products/available-sku`,{params:{productName:name}}))
    },
  },
};
