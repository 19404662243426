<template lang="pug">
  v-container.px-10.px-lg-15.product-view(fluid='')
    v-overlay(color='white' :value='generalLoading')
      v-progress-circular(color='primary' indeterminate='' size='64')
    v-row(wrap='')
      v-col(cols='12')
        v-toolbar(flat='')
          v-toolbar-title.title
            v-icon(@click='checkOrBack') mdi-arrow-left
            span.ml-4 {{ title }}
          v-spacer
          template(v-if='action == $enums.ACTIONS.SHOW')
            v-btn(color='primary' v-if="hasSectionPermissions('edit')" @click='action = $enums.ACTIONS.EDIT' outlined='')
              v-icon mdi-pencil
              | Editar
            v-btn.ml-4(color='primary' v-if="hasSectionPermissions('delete')" outlined='' @click='openDeleteDialog')
              v-icon mdi-delete
              | Eliminar
          template(v-if='action !== $enums.ACTIONS.SHOW')
            v-btn(color='primary' @click="$refs.dialogNote.open($enums.ACTIONS.CREATE)")
              v-icon.mr-2(small) mdi-note-multiple
              | Agregar nota
        v-divider.ml-10(style='border-color: #B9C3C2;')
      v-col.pl-12(cols='12')
        v-row.mb-15
          v-col(cols='6')
            v-row.mx-0
              v-col(cols="12")
                h4 Notas
              v-col(v-for="(note,i) in item.notes" :key="i" cols="12" md="6")
                note-card(:note="note")
          v-col(cols='6' v-if='$enums.ACTIONS.SHOW != action && hasSectionPermissions("list")')
            v-form(v-model='valid' ref='form' style='width:100%')
              v-row.pl-3.pr-5
                v-col(cols="12")
                  h4 Información
                v-col.px-0.py-0(cols="12")
                  v-select.mb-1(:items='$enums.STATUSES' v-model='item.status' label='Estado' :disabled='loading' color='1F3333' dense=''  outlined='')
                v-col.px-0.py-0(cols="12")
                  v-text-field.mb-1(:disabled='loading' v-model='item.fullname' label='Nombre completo' color='1F3333' dense='' outlined='' :rules='rules.contact_name' counter='100')
                v-col.pl-0.py-0(cols="6")
                  v-select.mb-1(:items='documents' v-model='item.doc_type' label='Tipo de documento' :disabled='loading' color='1F3333' dense='' :rules='rules.doc_type' outlined='')
                v-col.pr-0.py-0(cols="6")
                  v-text-field.mb-1(:disabled='loading' v-model='item.doc_number' label='Número de documento' color='1F3333' :rules='rules.doc_type' counter='100' outlined='' dense='')        
                v-col.pl-0.py-0(cols="12")
                  v-text-field.mb-1(:disabled='loading' label='Teléfono celular' type='tel' color='1F3333' outlined='' dense='' v-model='item.phone' :rules='rules.cellphone' counter='9')
                v-col.px-0.py-0(cols="12")
                  v-text-field.mb-1(:disabled='loading' label='Correo electrónico' type='email' color='1F3333' outlined='' v-model='item.email' :rules='rules.email' counter='50' dense='')
                v-col.px-0.py-0(cols="12")
                  v-text-field.mb-1(:disabled='loading' label='Licencia de conducir' color='1F3333' outlined='' v-model='item.license' :rules='rules.license' dense='')
                v-col.px-0.py-0(cols="12")
                  v-text-field.mb-1(:disabled='loading' label='Placa del vehículo' color='1F3333' outlined='' v-model='item.vehicle_plate' :rules='rules.vehicle_plate' dense='')
                v-col.pl-0.py-0(cols="4")
                  v-select.mb-1(:disabled='loading' v-model='item.region_id' :items='regiones' item-text='name' item-value='id' label='Departamento' color='1F3333' outlined='' dense='')
                v-col.px-0.py-0(cols="4")
                  v-select.mb-1(:disabled='loading' v-model='item.provincia_id' :items='provincias' item-text='name' item-value='id' label='Provincia' color='1F3333' outlined='' dense='')
                v-col.pr-0.py-0(cols="4")
                  v-select.mb-1(:disabled='loading' v-model='item.distrito_id' :items='distritos' item-text='name' item-value='district_id' label='Distrito' color='1F3333' dense='' outlined='')
                v-col.px-0.py-0(cols="12")
                  v-text-field.mb-1(:disabled='loading' v-model='item.full_address' label='Detalle de ubicación (avenida, calle, jirón, departamento)' color='1F3333' outlined='' dense='' :rules='rules.full_address' counter='100')
          v-col(cols='6' v-else='')
            v-col(cols="12")
              h4 Información
            show-carrier(:carrier='item')
    dialog-info(ref='dialogInfo')
    dialog-confirm(@actionPressed='confirmDelete' ref='dialogConfirm')
    dialog-confirm-edit(ref='dialogConfirmEdit' @exit='back' @saveandexit='saveItem')
    dialog-note(ref="dialogNote" @done="newNote")
    .footer-products(v-if='(action == $enums.ACTIONS.CREATE || action == $enums.ACTIONS.EDIT) || hasSectionPermissions("edit") || hasSectionPermissions("delete")')
      v-btn(color='primary' :loading='loading' @click='back' primary='' outlined='' small='') CANCELAR
      v-btn.ml-6(color='primary' :loading='loading' @click='saveItem' small='' :disabled='!valid') {{ actionText }}

</template>

<script>
import DialogConfirm from "../../../components/shared/DialogConfirm.vue";
import DialogConfirmEdit from "../../../components/shared/DialogConfirmEdit.vue";
import DialogNote from "../../../components/shared/DialogNote.vue";
import DialogInfo from "../../../components/shared/DialogInfo.vue";
import ShowCarrier from "../../../components/admin/carriers/ShowCarrier.vue";
import regiones from "../../../ubigeo/departamentos.json";
import provincias from "../../../ubigeo/provincias.json";
import distritos from "../../../ubigeo/distritos.json";
import NoteCard from "../../../components/admin/cards/NoteCard.vue";
import moment from "moment";
export default {
  components: {
    DialogInfo,
    DialogConfirm,
    DialogConfirmEdit,
    ShowCarrier,
    DialogNote,
    NoteCard,
  },
  async mounted() {
    this.generalLoading = true;
    let id = this.$route.params.id;
    this.action = this.$route.query.action;
    if (this.$enums.ACTIONS.CREATE == this.action) {
      this.item = this.generateItem();
    }
    if (
      this.$enums.ACTIONS.EDIT == this.action ||
      this.$enums.ACTIONS.SHOW == this.action
    ) {
      this.item = await this.$store.dispatch("getCarrier", id);
      this.copyOfItem = JSON.stringify(this.item);
      if(this.$refs.form){

        this.$refs.form.validate()
      }
    }
    this.generalLoading = false;
  },
  computed: {
    products() {
      return this.$store.state.products.productsSimple;
    },
    actionText() {
      if (this.$enums.ACTIONS.CREATE == this.action) {
        return "CREAR TRANSPORTISTA";
      }
      if (this.$enums.ACTIONS.EDIT == this.action) {
        return "GUARDAR TRANSPORTISTA";
      }
      return "";
    },
    title() {
      if (this.$enums.ACTIONS.CREATE == this.action) {
        return "Crear transportista";
      }
      if (this.$enums.ACTIONS.EDIT == this.action) {
        return "Editar transportista";
      }
      if (this.$enums.ACTIONS.SHOW == this.action) {
        return "Detalles del transportista";
      }
      return "";
    },
    regiones() {
      return regiones;
    },
    provincias() {
      if (this.item.region_id == null) {
        return [];
      }
      return provincias.filter((p) => p.department_id == this.item.region_id);
    },
    distritos() {
      if (this.item.provincia_id == null) {
        return [];
      }
      return distritos.filter((p) => p.province_id == this.item.provincia_id);
    },
  },
  data() {
    return {
      loading: false,
      action: "",
      generalLoading: false,
      key: 0,
      valid: false,
      errorMessage: "",
      copyOfItem: null,
      documents: [
        { text: "RUC", value: "ruc" },
        { text: "DNI", value: "dni" },
        { text: "PASAPORTE", value: "pas" },
        { text: "C.E", value: "ce" },
        { text: "OTRO", value: "otro" },
      ],
      rules: {
        razon_social: [
          (v) => !!v || "Debe ingresar una razón social",
          (v) => v.length <= 50 || "Máximo 50 caracteres",
        ],
        doc_type: [(v) => !!v || "Seleccione un tipo de documento"],
        doc_number: [
          (v) => !!v || "Debe ingresar un número de documento",
          (v) => v.length <= 100 || "Máximo 100 caracteres",
        ],
        contact_name: [
          (v) => !!v || "Debe ingresar el nombre completo",
          (v) => v.length <= 100 || "Máximo 100 caracteres",
        ],
        cellphone: [
          (v) => !!v || "Debe ingresar un número de celular",
          (v) => v.length <= 9 || "Máximo 9 caracteres",
        ],
        vehicle_plate: [
          (v) => {
            if(this.item.status === 'aprobado'){
              return !!v || "Debe ingresar la placa de su vehiculo"
            }
            return true
          },
        ],
        license: [
          (v) => {
            console.log(this.item)
            if(this.item.status === 'aprobado'){
              return !!v || "Debe ingresar la licencia de conducir del transportista"
            }
            return true
          },
        ],
        phone: [(v) => v.length <= 10 || "Máximo 10 caracteres"],
        email: [
          (v) => {
            if (v == "") {
              return true;
            }
            return (
              this.validateEmail(v) || "Ingrese un correo electrónico válido"
            );
          },
          (v) => v.length <= 50 || "Máximo 50 caracteres",
        ],
        full_address: [
          (v) =>
            v.length <= 100 ||
            "La dirección no puede tener más de 100 caracteres",
        ],
      },
      item: {
        id: "",
        fullname: "",
        doc_type: "",
        doc_number: "",
        phone: "",
        email: "",
        region_id: null,
        provincia_id: null,
        distrito_id: null,
        full_address: "",
        created_at: null,
        vehicle_plate: "",
        license: "",
        notes: [],
        status: "",
      },
    };
  },
  methods: {
    hasSectionPermissions(...permissions) {
      return this.$store.getters.hasSectionPermissions(
        "transportistas",
        permissions
      );
    },
    newNote(note) {
      this.item.notes.unshift(note);
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    newObjectItem() {
      return {
        id: this.$uid(),
        fullname: "",
        doc_type: "",
        doc_number: "",
        phone: "",
        email: "",
        region_id: null,
        provincia_id: null,
        distrito_id: null,
        full_address: "",
        created_at: null,
        vehicle_plate: "",
        license: "",
        notes: [],
        status: "",
      };
    },
    checkOrBack() {
      if (this.itemHasChanged()) {
        this.$refs.dialogConfirmEdit.open();
      } else {
        this.back();
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    itemHasChanged() {
      if (this.action == this.$enums.ACTIONS.EDIT) {
        let currentObject = JSON.stringify(this.item);
        if (currentObject == this.copyOfItem) {
          return false;
        }
        return true;
      }
      return false;
    },
    async confirmDelete() {
      try {
        await this.$store.dispatch("removeCarrier", this.item);
        this.$refs.dialogConfirm.stopLoading();
        this.$refs.dialogConfirm.close();
        this.$toast.success("Transportista eliminado satisfactoriamente");
        this.back();
      } catch (error) {
        this.$refs.dialogInfo(
          "Error al eliminar el transportista",
          error.response.data.error_message
        );
      }
    },
    openDeleteDialog() {
      this.$refs.dialogConfirm.open(
        "Eliminar transportista",
        "Si elimina el transportista su información se borrará permanentemente del sistema."
      );
    },
    async saveItem() {
      this.loading = true;
      this.generalLoading = false;

      try {
        if (this.action == this.$enums.ACTIONS.CREATE) {
          this.item.origin = "manual";
        }
        await this.$store.dispatch("putCarrier", this.item);
        if (this.action == this.$enums.ACTIONS.CREATE) {
          this.$toast.success("Transportista creado satisfactoriamente");
        } else if (this.action == this.$enums.ACTIONS.EDIT) {
          this.$toast.success("Transportista editado satisfactoriamente");
        }
        this.$refs.dialogConfirmEdit.close();
        this.back();
      } catch (error) {
        this.errorMessage = error.response.data.error_message;
      }
      this.loading = false;
      this.generalLoading = false;
    },
    back() {
      this.$router.push(`/transportistas`);
    },
    generateItem() {
      let emptyValue = {
        id: this.$uid(),
        fullname: "",
        doc_type: "",
        doc_number: "",
        phone: "",
        email: "",
        region_id: null,
        provincia_id: null,
        distrito_id: null,
        full_address: "",
        created_at: null,
        notes: [],
        vehicle_plate: "",
        license: "",
        status: "",
      };
      return emptyValue;
    },
  },
};
</script>

<style lang="scss">
.product-view {
  .igv-info {
    width: 100px;
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    color: #818685;
  }
  .sku--text {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #1f3333;
    margin-left: 10px;
  }
  .product-item__info {
    height: 44px;

    border-bottom: 1px solid #b9c3c2;
    display: flex;
    align-items: center;
    .info-field {
      width: 140px;
      font-size: 12px;
      line-height: 18px;
      color: #818685;
    }
    .value-field {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 24px;
      color: #343837;
      .append-content {
        color: #561fdb;
      }
    }
  }
  .thumbnail {
    border: 1px solid #b9c3c2;
    width: 64px;
    height: 64px;
    display: inline-block;
    margin-right: 20px;
    margin-top: 20px;
    &.active {
      border: 1px solid #561fdb;
    }
    &:hover {
      border: 1px solid #561fdb;
      cursor: pointer;
    }
  }
  .igv-info-value {
    font-size: 12px;
    line-height: 18px;
    color: #343837;
  }
  .footer-products {
    height: 72px;
    display: flex;
    justify-content: flex-end;
    padding-right: 85px;
    align-items: center;
    border-top: 2px solid #f2f3f2;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .hint {
    font-size: 12px;
    line-height: 18px;
    color: #818685;
  }
}
</style>
