<template lang="pug">
quenco-drawer.drawer-custom(ref='quencoDrawer' :title='title' @actionPerformed='save' :width="width" :disableActionButton='!valido' actionButton='GUARDAR')
  .px-0.px-sm-10(slot='content')
    v-form(v-model='valido' ref='form')
      v-row.mt-5
        v-col.py-0(cols='12' v-if="defaultSku == null")
          v-autocomplete(outlined rounded :items="products" :rules='[$rules.required]' item-value="sku" item-text="name" v-model='item.sku' label='Producto')
        v-col.py-0(cols='12')
          v-select(outlined :items="$enums.MOVEMENT_TYPES" rounded v-model='item.movement_type' :rules='[$rules.required]' label='Tipo de movimiento')
        v-col.py-0(cols='12' v-if="item.movement_type === 'INCOMING'")
          v-select(outlined :items="$enums.INCOMING_REASONS" rounded v-model='item.movement_concept' :rules='[$rules.required]' label='Motivo')
        v-col.py-0(cols='12' v-if="item.movement_type === 'OUTGOING'")
          v-select(outlined :items="$enums.OUTGOING_REASONS" rounded v-model='item.movement_concept' :rules='[$rules.required]' label='Motivo')
        template(v-if="item.movement_type === 'INCOMING'")
          v-col.py-0(cols='12')
            v-text-field(outlined rounded :min="0" :rules='[$rules.numeric,$rules.minValue(item.qty,0)]' v-model.number='item.qty' label='Cantidad')
          v-col.py-0(cols='12' v-if="item.movement_concept !== 'REINGRESS' && !isFinalProduct")
            v-currency-field( outlined prefix="S/" :min="0" :rules="[$rules.numeric,$rules.minValue(item.unit_cost,0)]" label="Costo unitario" v-model.number="item.unit_cost")
        template(v-if="item.movement_type === 'OUTGOING'")
          v-col.py-0(cols='12')
            v-text-field(outlined rounded :min="0" :rules='[$rules.numeric,$rules.minValue(item.qty,0)]' v-model.number='item.qty' label='Cantidad')
          v-col.py-0(cols="12")
            v-textarea(v-model="item.obs" label="Observación (opcional)" rounded outlined)
    .red--text {{ errorMessage }}

</template>

<script>
import QuencoDrawer from "../shared/QuencoDrawer.vue";

export default {
  components: { QuencoDrawer },
  props: {
    defaultSku: {
      required: false,
      default: null
    },
    width: {
      default: '360px',
    }
  },
  methods: {
    revalidate() {
      this.$refs.form.validate();
    },
    open() {
      this.clearForm();
      console.log(this.defaultSku)
      this.item = {
        id: this.$uid(),
        movement_datetime: new Date(),
        movement_timestamp: 0,
        movement_type: null,
        movement_concept: null,
        user_info: null,
        sku: this.defaultSku,
        qty: 0,
        unit_cost: 0,
        total_cost: 0,
        ending_balance: null,
      };
      this.loading = false;
      this.errorMessage = "";
      console.log(this.$refs.quencoDrawer)
      this.$refs.quencoDrawer.setVisible(true);
    },
    clearForm() {
      this.item = {
        id: "",
        movement_datetime: new Date(),
        movement_timestamp: 0,
        movement_type: null,
        movement_concept: null,
        user_info: null,
        sku: this.defaultSku,
        qty: 0,
        unit_cost: 0,
        total_cost: 0,
        ending_balance: null,
        obs: ''
      };

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    async save() {
      this.loading = true;
      this.errorMessage = "";
      this.item.inventory_type = this.getInventoryType
      try {
        let response = await this.$store.dispatch("createInventoryMovement", this.item);
        if (response.has_error) {
          this.errorMessage = response.error_message;
          this.$refs.quencoDrawer.stopLoading();
        } else {
          this.$refs.quencoDrawer.setVisible(false);
          this.$refs.quencoDrawer.stopLoading();
          this.$emit("done");
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.errorMessage = error.response.data.error_message;
      }
      this.loading = false;
    },
  },
  computed: {
    products() {
      return this.$store.state.products.productsSimple;
    },
    finalProducts() {
      return this.$store.state.products.finalProducts;
    },
    title() {
      return 'NUEVO MOVIMIENTO DE INVENTARIO';
    },
    getInventoryType(){
      const path = this.$route.path
      if(path.includes("materia-prima")){
        return this.$enums.INVENTORY_TYPES.RAW_MATERIAL
      }
      if(path.includes("producto-final")){
        return this.$enums.INVENTORY_TYPES.FINAL_PRODUCT
      }
      return ""
    },
    isFinalProduct(){
      return this.getInventoryType === this.$enums.INVENTORY_TYPES.FINAL_PRODUCT
    }
  },
  data() {
    let empty = {
      id: "",
      movement_datetime: new Date(),
      movement_timestamp: 0,
      movement_type: null,
      movement_concept: null,
      user_info: null,
      sku: null,
      qty: 0,
      unit_cost: 0,
      total_cost: 0,
      ending_balance: null,
      obs: ''
    };
    return {
      show: false,
      repeatPassword: "",
      valido: false,
      hideProductCombo: false,
      errorMessage: "",
      departmentID: "15",
      provinceID: "1501",
      action: "create",
      loading: false,
      emptyItem: empty,
      item: {
        id: "",
        movement_datetime: new Date(),
        movement_timestamp: 0,
        movement_type: null,
        movement_concept: null,
        user_info: null,
        sku: null,
        qty: 0,
        unit_cost: 0,
        total_cost: 0,
        ending_balance: null,
        obs: ''
      },
    };
  },
};
</script>

<style lang="scss">
.drawer-custom {
  width: 360px !important;
  .divider {
    border-bottom: 2px dashed #9cd6bf;
    width: 100%;
  }
}
.quenco-card {
  .form-title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #1f3333;
  }
  .v-text-field--rounded {
    border-radius: 12px;
  }
}
</style>
