import firebase from "firebase/app";
import Axios from "axios";
import utilities from "./utilities";
const auth = firebase.auth();
const db = firebase.firestore();
let authSnapshot = null;
export default {
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async loginEmail({ commit }, credentials) {
      await auth.signInWithEmailAndPassword(
        credentials.email,
        credentials.password
      );
    },
    async init({ commit, dispatch }) {
      let doc = await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .get();
      if (doc.exists) {
        let data = doc.data();
        data.uid = doc.id;
        commit("setUser", data);
        authSnapshot = db
          .collection("users")
          .doc(auth.currentUser.uid)
          .onSnapshot((doc) => {
            if (doc.exists) {
              let user = doc.data();
              user.uid = doc.id;
              console.log(user);
              if (user.disabled) {
                dispatch("logOut");
              } else {
                commit("setUser", user);
              }
            } else {
              dispatch("logOut");
            }
          });
      } else {
        dispatch("logOut");
      }
    },
    async resetPassword({}, payload) {
      return await Axios.post("v1/public/forgot-password", payload);
    },
    async createPassword({}, payload) {
      return await Axios.put("v1/public/reset-password", payload);
    },
    async verifyToken({}, token) {
      return utilities.wrapResponseAndGetData(
        await Axios.get("v1/public/reset-password", {
          params: { token: token },
        })
      );
    },
    async getAuthUsers({}, role) {
      let data = await utilities.wrapResponseAndGetData(
        await Axios.get(`v1/admin/auth-users`, {
          params: {
            role: role,
          },
        })
      );
      data = data.map((i)=>{
        return {
          ...i,
          fullname: i.first_name + " " + i.last_name
        }
      })
      return data;
    },
    async logOut({ commit }) {
      await auth.signOut();
      commit("setUser", null);
      if (authSnapshot) {
        authSnapshot();
      }
    },
    async postAuthUser({}, data) {
      return await Axios.post("v1/admin/auth-users", data);
    },
    async putAuthUser({}, data) {
      return await Axios.put("v1/admin/auth-users", data);
    },
    async patchAuthUserPassword({}, data) {
      console.log(data);
      return await Axios.patch("v1/admin/auth-users", data);
    },
    async deleteAuthUser({}, id) {
      return await Axios.delete(`v1/admin/auth-users/${id}`);
    },
  },
  getters: {
    canWatchSection(state) {
      return (section) => {
        if (!state.user) {
          return false;
        }
        if (state.user.super_admin) {
          return true;
        }
        if (!state.user.permissions) {
          return false;
        }
        let sectionFound = state.user.permissions.find((p) => p.section === section);
        if (!sectionFound) {
          return false;
        }
        return sectionFound.permissions.length > 0;
      };
    },
    hasSectionPermissions(state) {
      return (section, permissions) => {
        if (!state.user) {
          return false;
        }
        if (state.user.super_admin) {
          return true;
        }
        if (!state.user.permissions) {
          return false;
        }
        const mappedPermissions = permissions.map((p) =>
          state.user.permissions
            .find((p) => p.section === section)
            .permissions.includes(p)
        );
        return !mappedPermissions.includes(false);
      };
    },
  },
};
