<template lang="pug">
dialog-base(ref='dialogBase' v-bind='$attrs')
  div(slot="content")
    v-text-field(v-model='item.expected_debt_payment_date' outlined rounded hide-details label='Fecha de pago esperada' type='date' required)
  .d-flex.justify-center.align-center.text-center(style='width:100%;' slot='actions')
    v-btn(:loading='loading' @click='close' color='primary' small='' outlined='' primary='') Mantener
    v-btn.ml-4(:loading='loading' @click='confirm' color='primary' small='' primary='') Confirmar

</template>

<script>
import DialogBase from '../../shared/DialogBase.vue'
export default {
  components: { DialogBase },
  data () {
    return {
      show: false,
      item: {
        id: null,
        expected_debt_payment_date: null
      },
      loading: false
    }
  },
  methods: {
    open (_item) {
      this.loading = false
      this.$refs.dialogBase.open("Cambiar fecha esperada de pago","")
      this.item = JSON.parse(JSON.stringify(_item))
      this.item.expected_debt_payment_date = this.item.expected_debt_payment_date ? this.item.expected_debt_payment_date.split('T')[0] : null
    },
    close () {
      this.$refs.dialogBase.close()
    },
    async confirm(){
      this.loading = true
      await this.$store.dispatch('patchExpectedPaymentDate',{
        order_id: this.item.id,
        date: this.item.expected_debt_payment_date
      })
      this.$emit('update', this.item)
      this.close()
    },
    stopLoading(){
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.dialog-base-card {
    border-radius: 12px !important;
  .v-card__title {
    font-size: 24px !important;
    line-height: 36px;
    color: #1f3333;
    display: flex;
    justify-content: center;
    padding-top: 40px !important;
  }
  .content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1f3333 !important;
  }
  .v-card__actions {
    padding-bottom: 40px !important;
  }
}
</style>
