<template>
  <dialog-base ref="dialogBase" v-bind="$attrs">
    <div style="width:100%;" class="d-flex justify-center align-center text-center" slot="actions">
      <v-btn :loading="loading" @click="close" color="primary" small outlined primary>{{secondaryText}}</v-btn>
      <v-btn :loading="loading" @click="actionPress" color="primary" class="ml-4" small primary>{{primaryText}}</v-btn>
    </div>
  </dialog-base>
</template>

<script>
import DialogBase from './DialogBase.vue'
export default {
  components: { DialogBase },
  data () {
    return {
      show: false,
      title: '',
      content: '',
      loading: false
    }
  },
  props:{
    primaryText:{
      type: String,
      default:'Eliminar'
    },
    secondaryText:{
      type: String,
      default: 'Mantener'
    },
    loadingOnPress:{
      type: Boolean,
      default: true
    }
  },
  methods: {
    open (title, content) {
      this.loading = false
      this.$refs.dialogBase.open(title,content)
    },
    close () {
      this.$refs.dialogBase.close()
    },
    actionPress(){
      this.loading = this.loadingOnPress
      this.$emit('actionPressed')
    },
    stopLoading(){
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.dialog-base-card {
    border-radius: 12px !important;
  .v-card__title {
    font-size: 24px !important;
    line-height: 36px;
    color: #1f3333;
    display: flex;
    justify-content: center;
    padding-top: 40px !important;
  }
  .content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1f3333 !important;
  }
  .v-card__actions {
    padding-bottom: 40px !important;
  }
}
</style>
