<template lang="pug">
  dialog-base(ref="dialogBase" showClose)
    div(slot="content")
      v-textarea(label="Contenido de la nota" rows="3" v-model="content" no-resize outlined hide-details)
    v-btn.mx-auto(color="primary" @click="save" slot="actions") GUARDAR
</template>

<script>
import DialogBase from './DialogBase.vue'
import moment from 'moment'
export default {
  components: {DialogBase},
  data(){
    return {
      action: '',
      noteToEdit: null,
      content: ''
    }
  },
  methods:{
    open (action, note) {
      this.action = action
      if(this.$enums.ACTIONS.EDIT === action){
        this.$refs.dialogBase.open('Editar nota')
        this.noteToEdit = JSON.parse(JSON.stringify(note))
        this.content = this.noteToEdit.content
      }      
      else {
        this.content = ''
        this.noteToEdit = null
        this.$refs.dialogBase.open('Nueva nota')
      }
    },
    close () {
      this.$refs.dialogBase.close()
    },
    save(){
      if(this.action === this.$enums.ACTIONS.CREATE){
        const newNote = {
          date : moment().toISOString(true),
          content : this.content,
          created_by : this.$store.state.auth.user.first_name + " "+ this.$store.state.auth.user.last_name,
          creator_uid : this.$store.state.auth.user.uid,
        }
        this.$emit('done', newNote)
      } else {
        this.noteToEdit.content = this.content
        this.$emit('done', this.noteToEdit)
      }
      this.close()
    }
  },
  mounted(){
    console.log()
    console.log()
    console.log(moment().toLocaleString())
  }
}
</script>

<style>

</style>