<template lang="pug">
v-dialog(max-width="360px" v-model="show")
  v-card.c-dialog-hub-detail
    v-card-title.d-block
      .text-center  CAMBIAR CONTRASEÑA
    v-card-text 
      v-form(v-model="valid" ref="form"  )
        v-row
          v-col(cols="12")
            v-text-field(label="Contraseña" @keyup="validateForm" :rules="[$rules.required, rules.passwordsMatch]" outlined dense v-model="item.pwd1")
          v-col(cols="12")
            v-text-field(label="Repita la contraseña" @keyup="validateForm" :rules="[$rules.required, rules.passwordsMatch]"  outlined dense v-model="item.pwd2")    
        v-col.text-center(cols="12")
          v-btn(color="info" :loading="loading" outlined @click="show = false") Salir
          v-btn.ml-15(color="primary" :loading="loading" :disabled="!valid" @click="save" ) Guardar 

</template>

<script>
export default {

  data() {
    return {
      show: false,
      loading: false,
      valid: false,
      userId: null,
      rules: {
        required: v => !!v || 'Llene este campo',
        passwordsMatch: () => (this.item.pwd1 === this.item.pwd2) || 'Las contraseñas no coinciden'
      },
      item:{
        pwd1: '',
        pwd2: ''
      }
    };
  },
  methods:{
    async save(){
      this.loading = true
      await this.$store.dispatch('patchAuthUserPassword', {id: this.userId, pwd: this.item.pwd1})
      this.loading = false
      this.show = false
      this.$emit('done')
    },
    open(item){
      this.loading = false
      this.show = true
      this.userId = item.id
    },
    validateForm() {
      this.$refs.form.validate();
    }

  },
  mounted(){
    

  }
};
</script>

<style lang="scss">
.c-dialog-hub-detail{
  .pac-target-input{
    border: 1px solid gray;
    padding: 8px 12px;
    border-radius: 8px;
    background: white;
    position: absolute;
    left: 220px;
    top: 1.5rem;
    z-index: 4;
    width: 250px;
  }
}
</style>
