<template lang="pug">
v-row 
  v-col(cols="4")
    v-autocomplete(dense hide-details :rules="[$rules.required]" outlined @change="changeProduct" label="Producto relacionado" :items="products" item-value="sku" item-text="name" v-model="item.sku")
  //v-col(cols="3")
    v-text-field(dense hide-details :rules="[$rules.required]" outlined label="Nombre del producto" v-model="item.name")
  v-col(cols="2")
    v-currency-field(dense outlined :rules="[$rules.required,$rules.gt(item.sell_price,0)]" prefix="S/" hide-details label="Precio de venta" v-model="item.sell_price")
  v-col(cols="2" style="display:flex; flex-direction: column; justify-content: center;")
    small Margen: {{ marginEarning }}%
    small Costo Unitario: S/ {{ inventoryCost }}
  //v-col(cols="2")
    v-autocomplete(dense hide-details :rules="[$rules.required]" outlined label="Unidad venta" :items="$enums.UNITS" v-model="item.unit")
  //v-col(cols="1")
    v-text-field(dense hide-details outlined :suffix="item.unit_base" label="Equiv. Inventario" v-model.number="item.equiv_amount")
  v-col(cols="1")
    v-btn(icon @click="$emit('delete',item)") 
      v-icon mdi-delete
</template>

<script>
export default {
  props:{
    item: {
      type: Object,
      required: true
    }
  },
  computed:{
    products(){
      return this.$store.state.finalProducts.finalProducts
    },
    marginEarning(){
      let index = this.products.findIndex(p => p.sku === this.item.sku)
      if(index !== -1){
        const product = this.products[index]
        if(this.item.sell_price > 0.0){
          const total_cost = (Number(product.cost_unit).toFixed(2))
          const profit = this.item.sell_price - total_cost; // Calcula la ganancia real
          console.log(`total cost: ${total_cost}`)
          console.log(`profit: ${profit}`)
          return ((profit / total_cost) * 100).toFixed(2); // Retorna el margen de ganancia como un porcentaje

        }
      }
      return null
    },
    inventoryCost(){
      let index = this.products.findIndex(p => p.sku === this.item.sku)
      if(index !== -1){
        const product = this.products[index]
        const total_cost = Number((product.cost_unit).toFixed(2))
        return total_cost

      }
      return null
    }
  },
  data(){
    return {
      valid: false,
      rules: {
        business_name: [
          v => !!v || 'Ingrese el nombre de la empresa',
          v => v.length <= 100 || 'Ingrese máximo 100 caracteres'
        ],
        razon_social: [
          v => !!v || 'Ingrese la razón social',
          v => v.length <= 100 || 'Ingrese máximo 100 caracteres'
        ],
        phone: [
          v => !!v || 'Ingrese su número de teléfono',
          v => v.length <= 9 || 'Ingrese máximo 9 caracteres'
        ],
        email: [
          v =>
            this.validateEmail(v) ||
            'Ingrese un formato de correo electrónico correcto'
        ],
        doc_number: [
          v => !!v || 'Ingrese el RUC',
          v => v.length <= 11 || 'Ingrese máximo 11 caracteres'
        ],
      },
    }
  },
  methods:{
    changeProduct(item){
      const index = this.products.findIndex((p)=>p.sku === item)
      if(index !== -1){
        const product = this.products[index]
        this.item.name = product.name
        // this.item.sell_price = product.sell_price
        this.item.sunat_code = product.sunat_code
        this.item.unit = product.unit
        this.item.unit_base = product.unit
      }
    }
  }
}
</script>

<style lang="scss">

</style>
