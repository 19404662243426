<template>
  <quenco-drawer
    ref="quencoDrawer"
    :title="title"
    class="category-form"
    :actionButton="actionButton"
    @actionPerformed="saveCategory"
    :disableActionButton="!validForm"
  >
    <div slot="content" class="form-container">
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="px-0 py-0">
            <v-text-field
              label="Nombre de la categoría"
              outlined
              v-model="category.name"
              :loading="loading"
              counter="50"
            />
          </v-col>
          <input
            accept="image/x-png,image/gif,image/jpeg"
            type="file"
            @change="changeFile"
            ref="imageSelector"
            class="d-none"
          />
          <v-col cols="12" class="px-0 py-0">
            <div>Foto de la categoría</div>
            <div
              class="dragzone mt-5"
              @click="$refs.imageSelector.click()"
              @dragover.prevent
              @drop.stop.prevent="dropImage"
            >
              <div v-if="imagePreview == null" class="noImage">
                <v-icon color="#B9C3C2" size="64px">mdi-plus</v-icon>
                <div class="mt-4">Arrastre y suelte la foto aquí</div>
                <div>o</div>
                <div class="text-select">
                  SUBE UNO DE TUS ARCHIVOS
                </div>
              </div>
              <v-img
                contain
                min-width="300px"
                min-height="300px"
                max-width="300px"
                max-height="300px"
                aspect-ratio="1"
                style="border-radius:20px;"
                v-else
                :src="imagePreview"
              >
              </v-img>
            </div>
            <div class="hint mt-4">
              Las imágenes deben ser en formato .jpeg y no debe pasar los 3M.
            </div>
            <div class="red--text text-center mt-8">
              {{ errorMessage }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </quenco-drawer>
</template>

<script>
import QuencoDrawer from '../shared/QuencoDrawer.vue'
const MAX_SIZE_FILE = 3000000
export default {
  components: { QuencoDrawer },
  data () {
    return {
      action: '',
      errorMessage: '',
      newImage: null,
      newImageFile: null,
      loading: false,
      category: {
        id: this.$uid(),
        name: '',
        photo: null,
        num_products: 0,
        num_products_warning: 0,
        num_products_roos: 0,
        subcategories: []
      }
    }
  },
  methods: {
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('categorias',permissions)
    },
    reset () {
      this.$refs.imageSelector.value = null
      this.category = this.generateNewCategory()
      this.action = ''
      this.errorMessage = ''
      this.newImage = null
      this.loading = false
      this.newImageFile = null
      this.$refs.quencoDrawer.stopLoading()
    },
    async saveCategory () {
      this.loading = true
      try {
        if (this.action == this.$enums.ACTIONS.CREATE) {
          await this.$store.dispatch('createCategory', {
            category: this.category,
            newImageFile: this.newImageFile
          })
          this.$toast.success('Categoría creada satisfactoriamente')
        }
        if (this.action == this.$enums.ACTIONS.EDIT) {
          await this.$store.dispatch('updateCategory', {
            category: this.category,
            newImageFile: this.newImageFile
          })
          this.$toast.success('Categoría editada satisfactoriamente')
        }
        
        this.$refs.quencoDrawer.setVisible(false)
        this.$emit('actionDone')
        this.reset()
      } catch (error) {
        this.errorMessage = error.response.data.message
        this.$refs.quencoDrawer.stopLoading()
        this.loading = false
      }
    },
    generateNewCategory () {
      return {
        id: this.$uid(),
        name: '',
        photo: null,
        num_products: 0,
        num_products_warning: 0,
        num_products_roos: 0,
        subcategories: []
      }
    },

    imageToBase64 (file) {
      return new Promise((resolve, reject) => {
                var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = e => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          resolve(e.target.result)
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(file)
      })
    },

    open (action, category) {
      this.reset()
      this.$refs.quencoDrawer.setVisible(true)
      this.action = action
      if (action == this.$enums.ACTIONS.CREATE) {
        this.category = this.generateNewCategory()
      }
      if (action == this.$enums.ACTIONS.EDIT) {
        this.category = JSON.parse(JSON.stringify(category))
      }
    },
    async changeFile (event) {
      let file = event.target.files[0]
      let response = this.fileValid(file)
      if (response.valid) {
        this.newImageFile = file
        this.newImage = await this.imageToBase64(file)
      } else {
        this.errorMessage = response.message
      }
    },
    async dropImage (event) {
      event.preventDefault()
      if (event.dataTransfer.files.length > 0) {
        let file = event.dataTransfer.files[0]
        let response = this.fileValid(file)
        if (response.valid) {
          this.newImageFile = file
          this.newImage = await this.imageToBase64(file)
        } else {
          this.errorMessage = response.message
        }
      }
    },
    fileValid (file) {
      const fileType = file['type']
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']
      if (!validImageTypes.includes(fileType)) {
        return {
          valid: false,
          message: 'El archivo no es una imagen válida'
        }
      }
      if (file.size > MAX_SIZE_FILE) {
        return {
          valid: false,
          message: 'El archivo debe pesar menos de 3MB'
        }
      }
      return {
        valid: true,
        message: ''
      }
    }
  },
  computed: {
    title () {
      if (this.$enums.ACTIONS.CREATE == this.action) {
        return 'CREAR CATEGORÍA'
      } else if (this.$enums.ACTIONS.EDIT == this.action) {
        return 'EDITAR CATEGORÍA'
      }
      return ''
    },
    validForm(){
      if(this.category.name == ""){
        return false
      }
      if(this.newImage == null && this.category.photo == null){
        return false
      }
      if(this.category.name.length>50){
        return false
      }
      return true
    },
    imagePreview () {
      if (this.newImage) {
        return this.newImage
      }
      if (this.category.photo) {
        return this.category.photo.photo_url
      }
      return null
    },
    actionButton () {
      if (this.$enums.ACTIONS.CREATE == this.action) {
        return 'CREAR CATEGORÍA'
      } else if (this.$enums.ACTIONS.EDIT == this.action) {
        return 'GUARDAR CAMBIOS'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.category-form {
  .form-container {
    height: 93%;
    padding: 40px 30px 0 30px;
    background-color: #f4f9f7;
    .hint {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #818685;
    }
  }
  .dragzone {
    width: 300px;
    height: 300px;
    background: #ffffff;
    border: 2px dashed #b9c3c2;
    border-radius: 20px;
    &:hover {
      cursor: pointer;
    }
    .noImage {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      font-size: 14px;
      line-height: 24px;
      text-align: center;

      color: #818685;
      .text-select {
        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: #561fdb;
      }
    }
  }
}
</style>
