<template>
  <v-col cols="12" class="order-card py-0">
    <div class="new-order-tag">
      <span class="timer">{{ parseOrderDate(order.ordered_at.toDate()) }}</span>
    </div>
    <v-row>
      <v-col
        cols="2"
        class="mt-3 pl-5 justify-center align-start d-flex flex-column"
      >
        <div class="business-name">{{ order.billing.business_name }}</div>
        <div class="normal-text mt-2 mr-3">
          RUC/DNI {{ order.billing.doc_number }}
        </div>
        <div class="mt-2" @click="openWhatsapp(order.customer.phone)">
          <v-icon color="primary" class="mr-1" small>mdi-whatsapp</v-icon>
          <span class="primary--text link text-decoration-underline"
            >CONTACTAR CLIENTE</span
          >
        </div>
      </v-col>
      <v-col cols="3">
        <icon-text
          icon="mdi-google-maps"
          text="Dirección de envio"
          class="mt-0"
        />
        <div
          class="normal-text primary--text ml-7 text-decoration-none"
        >
          {{ order.shipping_info.shipping_address }}
        </div>
        <icon-text icon="mdi-shopping-outline" text="Productos" class="mt-3" />
        <span class="normal-text ml-5 mr-3"
          >{{ order.products.length }} productos</span
        >
        <span class="link" @click="seeProducts">Ver productos</span>
      </v-col>
      <v-col cols="2">
        <icon-text icon="mdi-credit-card" text="Método de pago" />
        <span class="normal-text ml-7 mr-3 text-uppercase">{{
          $decoders.paymentMethods(order.payment_method)
        }}</span>
        <icon-text icon="mdi-clock" text="Fecha y hora de entrega" class="mt-3" />
        <small>{{order.shipping_info | __shippingTime}} | {{order.shipping_info.start_minutes | __minutesToTime}} - {{order.shipping_info.end_minutes | __minutesToTime}}</small>
      </v-col> 
      <v-col cols="2">
        <div class="total-price mt-5">S/ {{ order.total_cost.toFixed(2) }}</div>
      </v-col>
      <v-col cols="3" align-self="center" class="text-center">
        <v-btn
          :loading="loading"
          color="primary"
          @click="changeStatus('accepted')"
          >ACEPTAR PEDIDO</v-btn
        >

        <div></div>
        <v-btn
          :loading="loading"
          outlined
          color="primary"
          class="mt-8"
          @click="cancelOrder"
          >RECHAZAR PEDIDO</v-btn
        >
      </v-col>
    </v-row>
    <see-products-dialog ref="productsDialog" />
    <cancel-order-dialog ref="cancelOrderDialog" />
  </v-col>
</template>

<script>
import IconText from '../tiny/IconText'
import SeeProductsDialog from '../dialogs/SeeProductsDialog'
import moment from 'moment'
import CancelOrderDialog from '../dialogs/CancelOrderDialog.vue'
let timerId = null
export default {
  components: { IconText, SeeProductsDialog,CancelOrderDialog },
  data () {
    return {
      loading: false,
      timeUntilCancel: 10 * 60
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  mounted () {},
  beforeDestroy () {},
  computed: {},
  methods: {
    openWhatsapp (phone) {
      window.open('https://wa.me/51' + phone, '_blank')
    },
    cancelOrder(){
      this.$refs.cancelOrderDialog.open(this.order)
    },
    parseOrderDate (value) {
      console.log(value)
      return moment(value)
        .format('DD/MM HH:mm')
        .toUpperCase()
    },
    seeProducts () {
      this.$refs.productsDialog.open(this.order)
    },
    showMap () {
      let url = `https://www.google.com/maps/search/?api=1&query=${this.order.customer_latitude},${this.order.customer_longitude}`
      window.open(url, '_blank')
    },
    async changeStatus (status) {
      try {
        this.loading = true
        await this.$store.dispatch('changeStatus', {
          order_id: this.order.id,
          status: status
        })
        this.loading = false
          this.$toast.open({
            message: 'Aceptó la orden exitósamente',
            type: 'success'
          })
        
      } catch (error) {
        this.$toast.open({
          message: 'Hubo un error y no se realizó la operación',
          type: 'error'
        })

        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss"></style>
