<template>
  <div class="subcategory-item__container">
      <div class="subcategory-item__info-container">
          <v-icon v-if="!editing">mdi-drag</v-icon>
          <span v-if="!editing" @click="editSubcategory" class="ml-2">{{subcategory.name}}</span>
          <v-text-field v-if="editing" @keypress.enter="editing = false" v-model="subcategory.name" hide-details dense  outlined/>
      </div>
      <template v-if="hasSectionPermissions('delete')">
            <v-icon v-if="!loadingDelete" @click="deleteCategory">mdi-delete</v-icon>
            <v-progress-circular class="mt-3" color="primary" v-else indeterminate/>
      </template>
  </div>
</template>

<script>
export default {
    props:{
        subcategory:{
            type: Object,
            required: true
        }
    },
    data(){
        return {
            editing: false,
            loadingDelete:false,
        }
    },
    methods:{
        hasSectionPermissions(...permissions){
            return this.$store.getters.hasSectionPermissions('categorias',permissions)
        },
        editSubcategory(){
            this.editing = !this.editing
            this.subcategory.edited = true
        },
        async deleteCategory(){
            this.loadingDelete = true
            let data = await this.$store.dispatch('checkSubcategoryDelete',this.subcategory.id)
            if(data.has_error){
                this.loadingDelete = false
                this.$emit('showErrorDelete',this.subcategory)
            }
            else{
                this.$emit('deleteSubcategory',this.subcategory)
            }
        }
    }
}
</script>

<style lang="scss">
    .subcategory-item__container{
        display: flex;
        justify-content: space-between;
        height: 60px;
        margin-left: 30px;
        margin-right: 30px;
        border-bottom: 1px solid #E0E0E0;
        .subcategory-item__info-container{
            display: flex;
            align-items: center;
            align-content: center;
            span{
                &:hover{
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }
    
</style>