<template>
  <dialog-base ref="dialogBase" >
    <div style="width:100%;" class="d-flex justify-center align-center text-center" slot="actions">
      <v-btn @click="close" color="primary" primary>ENTENDIDO</v-btn>
    </div>
  </dialog-base>
</template>

<script>
import DialogBase from './DialogBase.vue'
export default {
  components: { DialogBase },
  data () {
    return {
      show: false,
      title: '',
      content: ''
    }
  },
  methods: {
    open (title, content) {
      this.$refs.dialogBase.open(title,content)
    },
    close () {
      this.$refs.dialogBase.close()
    }
  }
}
</script>

<style lang="scss">
.dialog-base-card {
    border-radius: 12px !important;
  .v-card__title {
    font-size: 24px !important;
    line-height: 36px;
    color: #1f3333;
    display: flex;
    justify-content: center;
    padding-top: 40px !important;
  }
  .content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1f3333 !important;
  }
  .v-card__actions {
    padding-bottom: 40px !important;
  }
}
</style>
