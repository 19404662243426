<template lang="pug">
v-container.px-10.px-lg-15.product-view(fluid)
  v-overlay(color='white' :value="generalLoading")
    v-progress-circular(color='primary' indeterminate size='64')
  v-row(wrap)
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title
          v-icon(@click='checkOrBack') mdi-arrow-left
          span.ml-4 {{ title }}
        v-spacer
        //template(v-if="action == $enums.ACTIONS.SHOW")
          v-btn(color='primary' @click='action = $enums.ACTIONS.EDIT' outlined='' v-if="hasSectionPermissions('edit')")
            v-icon mdi-pencil
            | Editar
          v-btn.ml-4(color='primary' outlined='' v-if="hasSectionPermissions('delete')" @click='openDeleteDialog')
            v-icon mdi-delete
            | Eliminar
      v-divider.ml-10(style='border-color: #B9C3C2;')  
    v-col.pl-12(cols='12')
      v-row.mb-15
        //v-col(cols='4')
          input(
            @change="changeFile"
            accept="image/x-png,image/gif,image/jpeg"
            type="file"
            ref="inputFile"
            class="d-none"
          )
          v-img.product-image.hover(:src='currentImage' aspect-ratio="1" contain @click='openFileSelector')
            .d-flex.justify-center.align-center(style="height:100%;")
              v-icon(x-large=''  v-if='currentImage == null' color='green') mdi-plus
          .hint.mt-3
            | Las imagen deben ser en formato .jpeg y no deben pasar los 3M.
          .red--text.mt-3(style='font-size:14px;')
            | {{ errorMessage }}
        v-col(cols='8' v-if='$enums.ACTIONS.SHOW != action')
          v-form(v-model='valid' ref='form' style='width:100%')
            v-row
              v-col.py-0(cols="12")
                v-text-field(label='Nombre del producto' :disabled='loading' outlined='' v-model='product.name' :rules='rules.name' counter='100')
              v-col.py-0(cols="12")
                v-select(label='Categoría' :items='categories' v-model='categoryID' item-text='name' item-value="id" outlined='')
              v-col.py-0(cols="12")
                v-select(label='Subcategoría' :items='subcategories' v-model='product.subcategory' item-text='name'  return-object='' outlined='' :rules='rules.subcategory')
              v-col.py-0(cols="12")
                v-select(label='Unidad de medida' :items='$enums.UNITS' v-model='product.unit' :rules='rules.unit' outlined='')
              v-col.py-0(cols="12")
                v-checkbox(label="¿El producto tiene IGV?" v-model="product.use_igv" dense hide-details)
            v-row 
              v-col(cols="12").d-flex.align-center
                h3 Materia prima
                v-btn(color="primary" @click="addRawProduct" icon)
                  v-icon mdi-plus 
            v-row(v-for="component in product.components" :key="component.id")
              v-col(cols="5")
                v-autocomplete(label="Producto" dense outlined item-text="name" item-value="sku" :items="rawProducts" v-model="component.sku")
              v-col(cols="5")
                v-text-field(label="Cantidad" type="number" dense outlined :suffix="findRawProductUnit(component.sku)" v-model.number="component.qty_equiv")
              //v-col.py-0(cols="12")
                v-text-field.mb-2(v-model.number='product.min_buy' type='number' label='Pedido mínimo' :disabled='loading' outlined='' :suffix='product.unit')
        //v-col(cols='6')
          .product-item__info(style='border-top: 1px solid #B9C3C2;')
            .info-field
              span Nombre del producto
            .value-field(style='text-transform:uppercase;')
              | {{ product.name }}
          .product-item__info
            .info-field
              span Subcategoría
            .value-field(v-if='product.subcategory != null')
              | {{ product.subcategory.name }}
          .product-item__info
            .info-field
              span Unidad de medida
            .value-field
              | {{ $decoders.units(product.unit) }}
          .product-item__info
            .info-field
              span Pedido m&iacute;nimo
            .value-field
              | {{ product.min_buy }} {{ product.unit }}
        //v-col(cols="12")
          .d-flex.justify-space-between
            h3 Variantes
            v-btn.ml-5(small color="primary" v-if="hasSectionPermissions('edit') && (this.action === $enums.ACTIONS.EDIT || this.action === $enums.ACTIONS.CREATE)" @click="$refs.dialogVariant.open(product,$enums.ACTIONS.CREATE)" outlined)
              v-icon mdi-plus
              | Nueva variante
          v-simple-table.mt-3
            template(v-slot:default='')
              thead
                tr
                  th(width="20px")
                  th.text-left
                    | Nombre
                  th.text-left
                    | SKU
                  //th.text-left
                    | Precio
                  th.text-left
                    | Stock
                  th.text-left
                    | Activo
                  th
                  
              
              draggable(:list="product.variants" tag="tbody")
                tr(v-for='(item,index) in product.variants' :key='index')
                  td.hover
                    v-icon(small) mdi-arrow-all
                  td {{ item.name }}
                  td {{ item.sku || 'Por asignar' }}
                  // td S/ {{ item.sell_price }}
                  td {{ item.stock }} {{item.unit}}
                  td 
                    v-switch(v-model="item.active" :disabled="action === $enums.ACTIONS.SHOW")
                  td 
                    template(v-if="action === $enums.ACTIONS.EDIT")
                      v-btn.mr-5(small v-if="hasSectionPermissions('edit')" icon @click="$refs.dialogVariant.open(product,$enums.ACTIONS.EDIT,item,index)")
                        v-icon mdi-pencil
                      v-btn(small icon v-if="hasSectionPermissions('delete')" @click="openDeleteVariantDialog(item,index)")
                        v-icon mdi-delete

  dialog-info(ref='dialogInfo')
  dialog-confirm(@actionPressed='confirmDelete' ref='dialogConfirm')
  //dialog-confirm(@actionPressed='confirmDeleteVariant' ref='dialogConfirmVariant')
  dialog-confirm-edit(ref='dialogConfirmEdit' @exit='back' @saveAndExit='saveProduct')
  // dialog-variant(ref="dialogVariant" @done="doneVariant")
  .footer-products(v-if="\
  (action == $enums.ACTIONS.CREATE || action == $enums.ACTIONS.EDIT) &&\
  (hasSectionPermissions('edit') || hasSectionPermissions('delete'))\
  ")
    v-btn(color='primary' :loading='loading' @click='back' primary='' outlined='' small='') CANCELAR
    v-btn.ml-6(color='primary' :loading='loading' @click='saveProduct' small='' :disabled='!valid') {{ actionText }}

</template>

<script>
import DialogConfirm from "../../components/shared/DialogConfirm.vue";
import DialogConfirmEdit from "../../components/shared/DialogConfirmEdit.vue";
import DialogInfo from "../../components/shared/DialogInfo.vue";
import DialogVariant from "../../components/admin/products/ModalVariant.vue";
import draggable from "vuedraggable";

const MAX_SIZE_FILE = 3000000;
import moment from "moment";
export default {
  components: {
    DialogInfo,
    DialogConfirm,
    DialogConfirmEdit,
    draggable,
    DialogVariant,
  },
  async mounted() {
    this.generalLoading = true;
    let productID = this.$route.params.productID;
    this.action = this.$route.query.action;
    this.categories = []
    let data = await this.$store.dispatch('listCategories') 
    this.rawProducts = await this.$store.dispatch("loadProductsSimple"); 
    console.log("raw products")
    console.log(this.rawProducts)
    this.categories = data
    if (this.$enums.ACTIONS.CREATE == this.action) {
      this.product = this.generateProduct();
    }
    if (
      this.$enums.ACTIONS.EDIT == this.action ||
      this.$enums.ACTIONS.SHOW == this.action
    ) {
      this.product = await this.$store.dispatch("findFinalProduct", productID);
      console.log('oli')
      this.copyOfProduct = JSON.stringify(this.product);
      this.categoryID = this.product.subcategory.category_id
      console.log('end mounted')
    }
    this.generalLoading = false;
  },
  computed: {
    currentImage() {
      if (this.imageProductURL != null) {
        return this.imageProductURL;
      }
      if (this.product.photo) {
        return this.product.photo.photo_url;
      }
      return null;
    },
    subcategories(){
      let category = this.categories.find((c)=>c.id === this.categoryID)
      console.log('subtegories')
      console.log(category)
      if(!category){
        return []
      }
      let subcategories = category.subcategories;
      subcategories.unshift({
        id: null,
        category_id: this.categoryID,
        name: "Sin subcategoría",
      });
      if(this.product.subcategory != null){
        let idxSubcategory = subcategories.findIndex((s)=>s.id == this.product.subcategory.id)
        if(idxSubcategory == -1){
          this.product.subcategory = null
        }
      }
      return subcategories
    },  
    validForm() {
      this.key;
      if (!this.valid) {
        return false;
      }
      return true;
    },

    actionText() {
      if (this.$enums.ACTIONS.CREATE == this.action) {
        return "CREAR PRODUCTO";
      }
      if (this.$enums.ACTIONS.EDIT == this.action) {
        return "GUARDAR CAMBIOS";
      }
      return "";
    },
    title() {
      console.log(this.action);
      if (this.$enums.ACTIONS.CREATE == this.action) {
        return "Crear producto";
      }
      if (this.$enums.ACTIONS.EDIT == this.action) {
        return "Editar producto";
      }
      if (this.$enums.ACTIONS.SHOW == this.action) {
        return "Detalles del producto";
      }
      return "";
    },
  },
  data() {
    return {
      loading: false,
      action: "",
      generalLoading: false,
      skuLoaded: false,
      key: 0,
      variantIndexDelete: 0,
      imageProductFile: null,
      imageProductURL: null,
      valid: false,
      errorMessage: "",
      menu1: false,
      menu2: false,
      copyOfProduct: null,
      categoryID: null,
      categories: [],
      filterItems: [
        { text: "Todos", value: null },
        { text: "Activos", value: true },
        { text: "Inactivos", value: false },
      ],
      search: {
        text: "",
        active: null,
        page: 1,
        docsPerPage: 20,
      },
      rules: {
        name: [
          (v) => !!v || "Debe ingresar un nombre del producto",
          (v) => v.length <= 100 || "Máximo 100 caracteres",
        ],
        sku: [
          (v) => !!v || "Debe ingresar un SKU",
          (v) => v.length <= 20 || "Máximo 20 caracteres",
        ],
        description: [(v) => !!v || "Debe ingresar una descripción"],
        subcategory: [(v) => {
          console.log('subcategory RULE')
          console.log(v)
          return !!v || "Debe seleccionar una subcategoría"
        }],
        unit: [(v) => !!v || "Debe seleccionar una unidad de medida"],
        stock: [(v) => v > 0 || "Debe ingresar un stock válido"],
        sell_price: [
          (v) => {
            return v > 0 || "Debe ingresar un precio válido";
          },
        ],
        start_offer_date: [
          (v) => {
            if (this.product.offer_active) {
              let offerMoment = moment(this.product.start_offer_date);
              return offerMoment.isValid() || "Ingrese una fecha válida";
            }
            return true;
          },
        ],
        end_offer_date: [
          (v) => {
            if (this.product.offer_active) {
              let endMoment = moment(this.product.end_offer_date);
              let startMoment = moment(this.product.start_offer_date);
              if (startMoment.isSameOrBefore(endMoment)) {
                return true;
              }
              return "La fecha de fin debe ser mayor que la fecha de inicio";
            }
            return true;
          },
        ],
        offer_type: [
          (v) => {
            if (this.product.offer_active) {
              return !!v || "Seleccione un tipo de promoción";
            }
            return true;
          },
        ],
      },
      product: {
        id: "",
        name: "",
        sku: '',
        subcategory: null,
        unit: "",
        use_igv: false,
        components: []
      },
      rawProducts: []
    };
  },
  methods: {
    hasSectionPermissions(...permissions) {
      return this.$store.getters.hasSectionPermissions(
        "productos",
        permissions
      );
    },
    checkOrBack() {
      if (this.productHasChanged()) {
        this.$refs.dialogConfirmEdit.open();
      } else {
        this.back();
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    addRawProduct(){
      this.product.components.push({
        id: this.$uid(),
        sku: null,
        qty_equiv: 0,
      })
    },
    productHasChanged() {
      if (this.action == this.$enums.ACTIONS.EDIT) {
        let currentObject = JSON.stringify(this.product);
        if (currentObject == this.copyOfProduct) {
          return false;
        }
        return true;
      }
      return false;
    },
    findRawProductUnit(sku){
      let data = this.rawProducts.find(p => p.sku === sku)
      return data ? data.unit : ''
    },
    async confirmDelete() {
      try {
        await this.$store.dispatch("deleteFinalProduct", this.product);
        this.$refs.dialogConfirm.stopLoading();
        this.$refs.dialogConfirm.close();
        this.$toast.success("Producto eliminado satisfactoriamente");
        this.back();
      } catch (error) {
        this.$refs.dialogInfo(
          "Error al eliminar el producto",
          error.response.data.error_message
        );
      }
    },
    openDeleteDialog() {
      this.$refs.dialogConfirm.open(
        "Eliminar producto",
        "Si elimina el producto su información se borrará permanentemente del sistema."
      );
    },
    async saveProduct() {
      this.loading = true;
      this.generalLoading = false;
      try {
        if (this.action == this.$enums.ACTIONS.CREATE) {
          await this.$store.dispatch("createFinalProduct", this.product);
          this.$toast.success("Producto creado satisfactoriamente");
        } else if (this.action == this.$enums.ACTIONS.EDIT) {
          await this.$store.dispatch("editFinalProduct", this.product);
          this.$toast.success("Producto editado satisfactoriamente");
        }
        this.$refs.dialogConfirmEdit.close();
        this.back();
      } catch (error) {
        console.log(error);
        this.errorMessage = error.response.data.error_message;
      }
      this.loading = false;
      this.generalLoading = false;
    },
    back() {
      this.$router.push(`/producto-final`);
    },
    generateProduct() {
      let emptyValue = {
        id: this.$uid(),
        name: "",
        subcategory: null,
        unit: "",
        sku: '',
        use_igv: false,
        components: []
      };
      return emptyValue;
    },
  },
};
</script>

<style lang="scss">
.product-view {
  .igv-info {
    width: 100px;
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    color: #818685;
  }
  .sku--text {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #1f3333;
    margin-left: 10px;
  }
  .product-item__info {
    height: 44px;

    border-bottom: 1px solid #b9c3c2;
    display: flex;
    align-items: center;
    .info-field {
      width: 140px;
      font-size: 12px;
      line-height: 18px;
      color: #818685;
    }
    .value-field {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 24px;
      color: #343837;
      .append-content {
        color: #561fdb;
      }
    }
  }
  .product-image {
    border: 1px solid #b9c3c2;
    width: 100%;
  }
  .thumbnail {
    border: 1px solid #b9c3c2;
    width: 64px;
    height: 64px;
    display: inline-block;
    margin-right: 20px;
    margin-top: 20px;
    &.active {
      border: 1px solid #561fdb;
    }
    &:hover {
      border: 1px solid #561fdb;
      cursor: pointer;
    }
  }
  .igv-info-value {
    font-size: 12px;
    line-height: 18px;
    color: #343837;
  }
  .footer-products {
    height: 72px;
    display: flex;
    justify-content: flex-end;
    padding-right: 85px;
    align-items: center;
    border-top: 2px solid #f2f3f2;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .hint {
    font-size: 12px;
    line-height: 18px;
    color: #818685;
  }
}
</style>
