<template lang="pug">
v-dialog(max-width="800px" v-model="show")
  v-card.c-dialog-hub-detail
    v-card-title  {{title}}
    v-card-text 
      v-form(v-model="valid" ref="form")
        v-row
          v-col(cols="4")
            v-text-field(label="Nombre del almacén" :rules="[rules.required]" outlined dense hide-details v-model="item.name")
          v-col(cols="4")
            v-text-field(label="Dirección" :rules="[rules.required]" outlined dense hide-details v-model="item.address")
        v-row
          v-col(cols='4' md='4')
            v-autocomplete(label='Departamento' :rules="[rules.required]" dense outlined='' :items='departamentos' v-model='departamento' item-text='name' item-value='id')
          v-col(cols='4' md='4')
            v-autocomplete(label='Provincia' :rules="[rules.required]" dense :items='provincias' v-model='provincia' item-text='name' outlined='' item-value='id')
          v-col(cols='4' md='4')
            v-autocomplete(label='Distritos' :rules="[rules.required]" dense :items='distritos' v-model='item.ubigeo' hide-details item-text='name' outlined='' item-value="district_id" :disabled="provincia == ''")
          v-col(cols="12" style="position: relative;")
            GmapAutocomplete(@place_changed='setMarker')
            GmapMap(
              :center='center'
              :zoom='zoom'
              style="width:100%;  height: 400px;")
              GmapMarker(
                v-if="businessPosition"
                :position="businessPosition"
                :clickable="true"
                :draggable="true"
                @dragend="dragendMarker($event.latLng)")
        v-col.text-center(cols="12")
          v-btn(color="info" :loading="loading" outlined @click="show = false") Salir
          v-btn.ml-15(color="primary" :loading="loading" :disabled="!valid" @click="save" ) Guardar 

</template>

<script>
import departamentosJSON from '../../../ubigeo/departamentos.json'
import provinciasJSON from '../../../ubigeo/provincias.json'
import distritosJSON from '../../../ubigeo/distritos.json'
export default {
  props:{
    businessId:{
      type: String
    }
  },
  data() {
    return {
      show: false,
      center: {lat: 0.0,lng: 0.0},
      zoom: 14,
      loading: false,
      valid: false,
      departamento: '15',
      provincia: '1501',
      rules: {
        required: v => !!v || 'Llene este campo'
      },
      item:{
        id: null,
        name: '',        
        location:{
          type: 'Point',
          coordinates:[],
        },
        active: true,
        departamento: '',
        provincia: '',
        distrito: '',
        urbanizacion: '',
        ubigeo: '',
      }
    };
  },
  computed:{
    businessPosition(){
      return  {
        lng: this.item.location.coordinates[0],
        lat: this.item.location.coordinates[1],
      }
    },
    title(){
      return this.action === 'edit'?'Editar almacén':'Nuevo almacén'
    },
    departamentos () {
      return departamentosJSON
    },
    provincias () {
      if (this.departamento == '') {
        return []
      }
      return provinciasJSON.filter(p => p.department_id == this.departamento)
    },
    distritos () {
      if (this.provincia == '') {
        return []
      }
      return distritosJSON
        .filter(d => d.province_id == this.provincia)
    }
  },
  methods:{
    async save(){
      this.loading = true
      const departamento = this.departamentos[this.departamentos.findIndex((v)=>v.id == this.departamento)].name.trim()
      const provincia = this.provincias[this.provincias.findIndex((v)=>v.id == this.provincia)].name.trim()
      const distrito = this.distritos[this.distritos.findIndex((v)=>v.district_id == this.item.ubigeo)].name.trim()
      this.item.departamento = departamento
      this.item.provincia = provincia
      this.item.distrito = distrito
      await this.$store.dispatch('putWarehouse', this.item)
      this.loading = false
      this.show = false
      this.$emit('done')
    },
    dragendMarker(data){
      const marker = {
          lng: data.lng(),
          lat: data.lat(),
        };
        this.center = marker;
        this.item.location.coordinates = [marker.lng, marker.lat]
    },
    setMarker(currentPlace) {
      try {
        if (currentPlace) {
          const marker = {
            lng: currentPlace.geometry.location.lng(),
            lat: currentPlace.geometry.location.lat(),
          };
          this.center = marker;
          this.item.location.coordinates = [marker.lng, marker.lat]
          this.zoom = 16
        }
      } catch (error) {
        console.log(error)
      }
    },
    open(action,item){
      this.loading = false
      this.show = true
      this.action = action
      if(action === 'create'){
        this.item = {
          id: this.$uid(),
          name: '',       
          active: true, 
          location:{
            type: 'Point',
            coordinates:[],
          },
          departamento: '',
          provincia: '',
          distrito: '',
          urbanizacion: '',
          ubigeo: '',
        }
        this.departamento = "15"
        this.provincia = "1501"
        navigator.geolocation.getCurrentPosition(position => {
          this.center = {
            lng: position.coords.longitude,
            lat: position.coords.latitude,
          };
          this.item.location.coordinates = [position.coords.longitude, position.coords.latitude]
          this.zoom = 16
        });
      } else {
        this.item = item
        this.departamento = this.departamentos[this.departamentos.findIndex( (v) => v.id == item.ubigeo.substring(0,2))].id
        this.provincia = this.provincias[this.provincias.findIndex( (v) => v.id == item.ubigeo.substring(0,4))].id
        if(this.item.location.coordinates.length>0){
          this.center = {
            lng: this.item.location.coordinates[0],
            lat: this.item.location.coordinates[1],
          };
          this.zoom = 16
        }
      }
      
    }
  },
  mounted(){
    

  }
};
</script>

<style lang="scss">
.c-dialog-hub-detail{
  .pac-target-input{
    border: 1px solid gray;
    padding: 8px 12px;
    border-radius: 8px;
    background: white;
    position: absolute;
    left: 220px;
    top: 1.5rem;
    z-index: 4;
    width: 250px;
  }
}
</style>
