import utilities from "./utilities";
import Axios from "axios";
export default {
  actions: {
    async listDebts({}, filter) {
      const copyFilter = { ...filter };
      copyFilter.start_date = copyFilter.start_date + "T00:00:00Z";
      copyFilter.end_date = copyFilter.end_date + "T23:59:59Z";
      return await utilities.wrapResponseAndGetData(
        await Axios.get("v1/admin/debt/business-orders", {
          params: copyFilter,
        })
      );
    },
    async patchExpectedPaymentDate({}, payload) {
      return await utilities.wrapResponseAndGetData(
        await Axios.patch(`v1/admin/debt/change-expected-payment-date`, payload )
      );
    },
    async patchDebtStatus({}, payload) {
      return await utilities.wrapResponseAndGetData(
        await Axios.patch(`v1/admin/debt/change-debt-status`,  payload )
      );
    },
  }
};
