<template>
  <div>
    <v-icon color="primary" class="mr-1">{{ icon }}</v-icon>
    <span class="icon-text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      required: true,
      type: String
    },
    text: {
      required: true,
      type: String
    }
  }
}
</script>

<style>
.icon-text {
  font-family: Roboto;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #818685;
}
</style>
