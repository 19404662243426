<template lang="pug">
v-container.px-8.c-business-order-action
  dialog-info(ref="dialogInfo")
  input(type="file" v-show="false" @change="uploadTemplateExcel" ref="input" accept=".xlsx, .xls, .csv")
  v-form(v-model="valid" ref="form")
    v-row
      v-col(cols='12')
        v-toolbar(flat)
          .d-flex.align-center
            v-btn(icon @click="$router.push('/corporativo/ordenes')")
              v-icon mdi-chevron-left
            v-toolbar-title.title {{title}}
          v-spacer 
          v-btn(color="primary"  :loading="loading" @click="saveOrder" :disabled="!valid") {{porEmitir?'Guardar y emitir':'Guardar'}}
        v-divider
    v-overlay(color='white' :value='generalLoading')
      v-progress-circular(color='primary' indeterminate size='64')
    v-row(wrap)
      v-col.d-flex.align-center.justify-space-between(cols="12")
        v-autocomplete(item-text="business_name" style="width: 300px; max-width: 300px;" :disabled="!canEdit" dense outlined @change="changeBusiness" v-model="item.business_id" item-value="id" label="Seleccione una empresa" hide-details :items="businesses")

        v-btn.text-decoration-underline(color="green" plain v-if="item.business_id"  @click="downloadTemplate") Descargar Plantilla
    v-row(wrap)
      v-col.py-1(cols="12")
        h4 Información de entrega
      v-row
        v-col.py-2(cols="4")
          v-checkbox.mt-1(v-model="item.has_delivery_info"  :disabled="!canEdit" @change="revalidateForm" dense outlined label="¿Tendrá una ruta asignada?" hide-details)
        v-col.py-2.align-center.d-flex(cols="4")
          //h5 {{item.route_id == null ? 'SIN RUTA ASIGNADA':'CON RUTA ASIGNADA'}}
          v-select(:items="routes"  v-if="canEdit" v-model="item.route_id" :rules="[rules.required_with_delivery]" dense outlined item-text="name" :disabled="!item.has_delivery_info || !canEdit" item-value="id" :item-text="routeTextFunction" hide-details label="Seleccione una ruta de entrega")
          h5(v-else-if="item.route") Ruta: {{routeTextFunction(item.route)}}
        v-col.py-2.align-center.d-flex(cols="4")
          //h5(v-if="hubWasDeleted  ") Hub: {{item.hub.name}}
          v-select(:items="hubs" v-if="canEdit" v-model="item.hub_id" @change="changeGlosaName" :rules="[rules.required_with_delivery]" dense outlined item-text="name" :disabled="!item.has_delivery_info || !canEdit" item-value="id" hide-details label="Seleccione un hub de entrega")
          h5(v-else-if="item.hub") Hub: {{item.hub.name}}
        v-col.py-3(cols="4")
          v-text-field(type="number" v-model="item.peso_bruto" :rules="[rules.required_with_delivery]" label="Peso bruto (kilos)" :disabled="!item.has_delivery_info || !canEdit" hide-details dense outlined)
        //v-col.py-1.d-flex.align-center(cols="8")
          span(v-if="item.delivery_date_suggested") Fecha de entrega deseada: {{ formatDate(item.delivery_date_suggested) }}
        v-col.py-3(cols="4")
          v-text-field(type="date" v-model="item.delivery_date_suggested" :rules="[$rules.required]" :disabled="!canEdit" label="Fecha de entrega deseada" hide-details dense outlined)
        v-col.py-3(cols="4")
          v-text-field(type="date" v-model="item.dispatch_date"  label="Fecha de despacho" hide-details dense outlined)
    v-row(wrap)
      v-col.py-1(cols="12")
        h4 Información de facturación
    v-row
      v-col.py-1.align-center.d-flex(cols="3")
        //h5 {{item.route_id == null ? 'SIN RUTA ASIGNADA':'CON RUTA ASIGNADA'}}
        v-select(:items="pay_ways"  v-model="item.way_pay" :rules="[rules.required]" dense outlined :disabled="!canEdit" hide-details label="Forma de pago")
      v-col.py-1.align-center.d-flex(cols="3")
        v-select(:items="payment_methods"  v-model="item.payment_method" :rules="rules.payment_method" dense outlined :disabled="!canEdit" hide-details label="Método de pago")
      v-col.py-1.align-center.d-flex(cols="3" v-if="item.payment_method === 'CREDITO'")
        v-text-field(outlined type="number" rounded hide-details dense :rules='rules.days_of_credit' :disabled="!canEdit" v-model.number='item.days_of_credit' label='Días de crédito')
      //v-col.py-1(cols="4")
        v-text-field(type="number" v-model="item.peso_bruto" :rules="[rules.required_with_delivery]" label="Peso bruto (kilos)" :disabled="!item.has_delivery_info || !canEdit" hide-details dense outlined)
      v-col.py-1(cols="3")
        v-text-field(v-model="item.business_order_number" hide-details   label="Orden de compra asociado" :disabled="!canEdit" dense outlined)
      v-col.py-1(cols="12")
        v-radio-group(v-model='item.use_custom_guia_remision' :disabled="!canEdit" row='')
          v-radio(label='Usar guía de remisión de la ruta asignada' :value='false')
          v-radio(label='Usar datos de otra guía de remisión' :value='true')
        v-row(v-if="item.use_custom_guia_remision")
          v-col(cols="3")
            v-text-field(dense outlined :disabled="!canEdit" :rules="[rules.required]" v-model="item.custom_guia_remision_serie" label="Serie de la guía..." )
          v-col(cols="3")
            v-text-field(dense outlined :disabled="!canEdit" :rules="[rules.required]" v-model="item.custom_guia_remision_numero" label="Número de la guía...")
    v-row(v-if="currentHub")
      v-col.py-1.align-center.d-flex(cols="3")
        //h5 {{item.route_id == null ? 'SIN RUTA ASIGNADA':'CON RUTA ASIGNADA'}}
        v-checkbox.mt-1(v-model="item.use_hub_as_glosa"  :disabled="!canEdit" @change="changeGlosaName" dense outlined label="¿Usar el nombre del HUB como glosa?" hide-details)
      v-col.py-1.align-center.d-flex(cols="3")
        v-text-field(outlined type="text" rounded hide-details dense counter="80" :disabled="item.use_hub_as_glosa" v-model.number='item.glosa' label='Glosa')
    v-row 
      v-checkbox(label="Ignorar precio de venta en la orden de compra" v-model="item.ignore_pricing_on_new_order")
    v-row
      v-col
        h4 Indicación del pedido
        v-textarea.mt-4(label="Indicación..." hide-details no-resize rows="4" v-model="item.obs" counter="1000" outlined dense)
    v-divider.my-5.mb-10
    v-row(wrap)
        v-col.py-1.d-flex.align-center.justify-space-between(cols="12")
          h4 Productos
          v-btn(@click="$refs.input.click()" v-if="item.business_id && canEdit" color="green" plain small)
            v-icon(small) mdi-file-excel
            .text-decoration-underline Subir plantilla
        v-col
          .d-flex
            div
              v-text-field(outlined type="text" label="Buscador" style="width:400px;" placeholder="Buscar productos..." rounded hide-details dense v-model="search")
            v-checkbox.ml-10(label="Mostrar solo productos con una cantidad ordenada" hide-details dense v-model="show_only_products_ordered")
          v-simple-table
            template(v-slot:default='')
              thead
                tr
                  th.text-left
                    | Producto
                  th.text-center(style="width:200px")
                    | P. Unitario
                  th.text-center(style="width:200px")
                    | Cantidad
                  th.text-center(style="width:200px")
                    | Total
              tbody
                tr(v-for='(item,i) in item.products' :key='i')
                  template(v-if="showItemRow(item)")
                    td.text-left {{ item.name }}
                    td.text-center(v-if="!hidePrice") S/ {{ item.sell_price.toFixed(2) }} x {{item.unit}}
                    td.text-center(v-if="porEmitir")
                      v-currency-field( outlined prefix="S/" :min="0" hide-details dense :rules="[$rules.numeric,$rules.minValue(item.sell_price,0)]" label="Precio unitario" v-model.number="item.sell_price")
                    td.text-center(v-else) --
                    td
                      v-text-field(hide-details min="0" label="Cantidad" type="number" v-model.number="item.qty_ordered" :disabled="!canEdit" dense outlined)
                    td.text-center(v-if="!hidePrice || porEmitir") S/ {{ (Number(item.sell_price*item.qty_ordered).toFixed(2)) }}
                  tr
                    td
                    td
                    td
                    td.text-center.font-weight-bold(v-if="!hidePrice || porEmitir") S/ {{totalSum}}
</template>

<script>
import DialogConfirm from "../../../components/shared/DialogConfirm.vue";
import DialogInfo from "../../../components/shared/DialogInfo.vue";
import moment from "moment";

export default {
  components: { DialogInfo, DialogConfirm },
  data() {
    return {
      show_only_products_ordered: false,
      payment_methods: [
        { value: "CONTADO", text: "Contado" },
        { value: "CREDITO", text: "Crédito" },
      ],
      pay_ways: [
        { value: "DEPOSITO_CUENTA", text: "Depósito en cuenta" },
        { value: "TRANSFERENCIA_FONDOS", text: "Transferencia bancaria" },
        { value: "TARJETA_DEBITO", text: "Tarjeta de débito" },
        { value: "EFECTIVO", text: "Efectivo" },
        { value: "OTROS_MEDIO_PAGO", text: "Otros medios de pago" },
      ],
      loading: false,
      generalLoading: false,
      loadingTable: false,
      businesses: [],
      template: [],
      hubs: [],
      text: "",
      routes: [],
      valid: false,
      action: "",
      search: "",
      rules: {
        required_with_delivery: (v) => {
          if (this.item.has_delivery_info) {
            return !!v || "Campo requerido";
          } else {
            return true;
          }
        },
        required: (v) => !!v || "Ingrese este campo",
        payment_method: [(v) => !!v || "Ingrese la forma de pago"],
        days_of_credit: [
          (v) => v > 0 || "Ingrese la cantidad de días del crédito",
        ],
      },
      item: {
        id: null,
        order_number: 0,
        business_order_number: "",
        business: null,
        delivery_cost: 0,
        total_cost: 0,
        payment_method: "",
        products: [],
        dispatch_date: null,
        way_pay: "",
        status: "",
        declared_at: null,
        tefacturo_response: null,
        created_at: null,
        assigned_at: null,
        reviewed_at: null,
        completed_at: null,
        use_hub_as_glosa: false,
        delivery_date_suggested: null,
        glosa: "",
        has_delivery_info: false,
        route_id: null,
        hub_id: null,
        peso_bruto: "",
        days_of_credit: 0,
        use_custom_guia_remision: false,
        custom_guia_remision_serie: "",
        custom_guia_remision_numero: "",  

        ignore_pricing_on_new_order: false,
        obs: "",
      },
    };
  },
  methods: {
    revalidateForm() {
      this.$refs.form.validate();
    },
    changeGlosaName() {
      if (this.item.use_hub_as_glosa && this.currentHub) {
        this.item.glosa = "Hub " + this.currentHub.name;
      } else {
        this.item.glosa = "";
      }
    },
    showItemRow(item) {
      const has_qty_ordered = item.qty_ordered > 0;
      const match_search =
        this.search.trim() === ""
          ? true
          : item.name.toLowerCase().includes(this.search.trim());
      if (this.show_only_products_ordered && !has_qty_ordered) {
        return false;
      }
      return match_search;
    },
    async changeBusiness(value) {
      this.generalLoading = true;
      this.template = await this.$store.dispatch("listBusinessTemplate", value);
      this.hubs = await this.$store.dispatch("listBusinessHubs", value);
      this.item.use_hub_as_glosa = false;
      this.item.glosa = "";
      let products = [];
      this.template.products.forEach((t) => {
        const product = {};
        product.id = t.id;
        product.sell_price = t.sell_price;
        product.sku = t.sku;
        product.sunat_code = t.sunat_code;
        product.unit = t.unit;
        product.qty_ordered = 0;
        product.qty_rejected = 0;
        product.qty_received = 0;
        product.obs = "";
        product.name = t.name;
        product.equiv_amount = t.equiv_amount;
        product.unit_base = t.unit_base;
        products.push(product);
      });
      this.item.products = products;
      this.item.payment_method = this.currentBusiness.payment_method;
      this.item.days_of_credit = this.currentBusiness.days_of_credit;
      console.log(this.item.products);
      this.generalLoading = false;
    },
    async downloadTemplate(){
      this.generalLoading = true
      const response = await this.$store.dispatch('downloadExcelTemplateForBusiness', this.item.business_id)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const now = moment(this.date).format("DD-MM-YYYY")
      link.setAttribute('download', `plantilla_${this.currentBusiness.business_name}_${now}.xlsx`); //or any other extension
      link.click();
      // this.$emit('actionPressed')
      this.generalLoading = false
    },
    async uploadTemplateExcel(e){
      this.generalLoading = true
      const file = e.target.files[0]
      if(file){
        try {          
          const {data} = await this.$store.dispatch('uploadExcelBusinessTemplate', file)
          let products = JSON.parse(JSON.stringify(this.item.products))
          products.forEach(p => {
            p.qty_ordered = 0
          })
          data.forEach((p)=>{
            let findProduct = products.find(tp => tp.sku === p.sku)
            findProduct.qty_ordered = p.qty
            console.log(findProduct)
          })
          this.$set(this.item, "products", products)
          //this.template.products = products
        } catch (error) {
          console.log(error)
          this.$refs.dialogInfo.open(
            'No se pudo completar la operación',
            error.response.data.error_message
          )
        }
      }
      e.target.value = null
      this.generalLoading = false
    },
    async saveOrder() {
      this.generalLoading = true;
      try {
        this.item.total_cost = Number(this.totalSum);
        if (this.action === "create") {
          this.item.products.forEach((i) => (i.qty_received = i.qty_ordered));
          if (!this.item.has_delivery_info) {
            this.item.route_id = null;
          }
          await this.$store.dispatch("postBusinessOrder", this.item);
        }
        if (this.action == "edit") {
          if (this.canEdit) {
            this.item.products.forEach((i) => (i.qty_received = i.qty_ordered));
            if (!this.item.has_delivery_info) {
              this.item.route_id = null;
            }
          }
          await this.$store.dispatch("putBusinessOrder", this.item);
          if (this.porEmitir) {
            const response = await this.$store.dispatch(
              "postBusinessOrderEmitirFactura",
              this.item.id
            );
            let index = this.items.findIndex((o) => o.id === this.item.id);
            await this.$store.dispatch(
              "postDownloadFactura",
              response.ok_response
            );
          }
        }
        this.$router.push("/corporativo/ordenes");
      } catch (error) {
        console.log(error);
        this.$router.push("/corporativo/ordenes");
      }
      this.generalLoading = false;
    },
    routeTextFunction(value) {
      return `${value.carrier.fullname} - ${moment(value.init_time).format(
        "DD/MM HH:mm"
      )}`;
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  async beforeMount() {
    this.action = this.$route.query.action;
    this.generalLoading = true;

    //await this.loadItems(true)
    let result = await this.$store.dispatch("listBusinesses");
    this.businesses = result;
    let data = await this.$store.dispatch("listRoutesSimple", {
      docsPerPage: 1000,
      page: 1,
      status: "available",
    });
    this.routes = data.items.filter((r) => r.carrier !== null);
    if (this.action === "edit") {
      let item = await this.$store.dispatch(
        "getBusinessOrder",
        this.$route.params.id
      );
      this.item = item;
      this.hubs = await this.$store.dispatch(
        "listBusinessHubs",
        item.business_id
      );
    }
    this.generalLoading = false;
  },
  computed: {
    title() {
      return this.action === "create" ? "NUEVA ORDEN" : "EDITAR ORDEN";
    },
    hidePrice() {
      const porEmitir = this.$route.query.por_emitir;
      if (this.action === "create") {
        return this.item.ignore_pricing_on_new_order;
      }
      if (this.action === "edit") {
        if (porEmitir) {
          return true;
        }
        return this.item.ignore_pricing_on_new_order;
      }
      return false;
    },
    porEmitir() {
      return this.$route.query.por_emitir;
    },
    totalSum() {
      let sum = 0;
      this.item.products.forEach((i) => (sum += i.qty_ordered * i.sell_price));
      return Number(sum).toFixed(2);
    },
    canEdit() {
      if (this.item.has_billing) {
        return false;
      }
      if (this.item.route == null) {
        return true;
      }
      if (
        this.item.route.status === "available" ||
        this.item.route.status === "assigned"
      ) {
        return true;
      }
      return false;
    },
    hubWasDeleted() {
      if (this.item.hub) {
        return this.hubs.findIndex((b) => b.id === this.item.hub_id) === -1;
      }
      return false;
    },
    currentHub() {
      if (this.item.hub) {
        return this.item.hub;
      }
      if (this.item.hub_id) {
        const index = this.hubs.findIndex((b) => b.id === this.item.hub_id);
        return this.hubs[index];
      }
      return null;
    },
    currentBusiness() {
      if (this.item.business_id) {
        const index = this.businesses.findIndex(
          (b) => b.id === this.item.business_id
        );
        return this.businesses[index];
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.c-business-order-action {
  .v-input--selection-control {
    margin-top: 0 !important;
  }
}
</style>
