<template>
  <v-container fluid class="px-10">
    <v-row wrap>
      <v-col cols="12">
        <v-toolbar flat>
          <v-toolbar-title class="title">Categorías de productos</v-toolbar-title>
          <v-spacer />
          <v-btn
            color="primary"
            @click="$refs.categoryForm.open($enums.ACTIONS.CREATE)"
            elevation="4"
            v-if="!loading && categories.length > 0 && hasSectionPermissions('edit')"
            >CREAR CATEGORÍA</v-btn
          >
        </v-toolbar>
        <v-divider />
      </v-col>
      <v-col v-if="loading" cols="4">
        <v-skeleton-loader :loading="loading" type="card">
            </v-skeleton-loader>    
      </v-col>
      
      <v-col v-else-if="hasSectionPermissions('list')" cols="4" lg="3" v-for="category in categories" :key="category.id">
        <category-card
          @showSubcategories="showSubcategories"
          @editCategory="editCategory"
          @forceReload="reloadCategories"
          :category="category"
        />
    </v-col>
    <v-col cols="12" class="d-flex flex-column align-center" v-if="!loading && categories.length == 0 && hasSectionPermissions('edit')">
      <img width="250px" height="200px" :src="require('../../assets/EmptyInbox.png')" />
      <div>Aun no tiene categorías en la lista, empieza a </div>
      <div>crearlos de manera simple y rápida.</div>
      <v-btn @click="$refs.categoryForm.open($enums.ACTIONS.CREATE)" color="primary" width="200" class="mt-5"> CREAR CATEGORÍA </v-btn>
    </v-col>
    </v-row>
    <category-form @actionDone="reloadCategories" ref="categoryForm" />
    <subcategories-form ref="subcategoriesForm" @forceReload="reloadCategories" />
    <dialog-info ref="dialogInfo" />
  </v-container>
</template>

<script>
import CategoryCard from '../../components/admin/categories/CategoryCard.vue'
import CategoryForm from '../../components/admin/categories/CategoryForm.vue'
import SubcategoriesForm from '../../components/admin/categories/SubcategoriesForm.vue'
import DialogInfo from '../../components/shared/DialogInfo.vue'
export default {
  components: { CategoryForm, CategoryCard, SubcategoriesForm, DialogInfo },
  data () {
    return {
      categories: [],
      loading: false
    }
  },
  methods: {
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('categorias',permissions)
    },
    async reloadCategories () {
      try {
        this.loading = true
        this.categories = []
        let data = await this.$store.dispatch('listCategories')
        this.categories = data
        this.loading = false
      } catch (error) {
          this.$refs.dialogInfo.open('Error',error.response.data.error_message)
      }
    },
    showSubcategories (category) {
      this.$refs.subcategoriesForm.open(category)
    },
    editCategory(category){
      this.$refs.categoryForm.open(this.$enums.ACTIONS.EDIT,category)
    }
  },
  async beforeMount () {
    await this.reloadCategories()
  }
}
</script>

<style></style>
