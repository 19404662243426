<template lang="pug">
  v-container.second-container
    v-row.mx-0.pr-4
      v-col.px-0(cols='12')
        v-toolbar(flat color='transparent')
          v-toolbar-title.form-title.px-0
            | {{ title }}
          v-spacer
          slot(name='top-buttons')
    v-row.mx-0.pr-4(style='min-height: 80vh !important;')
      slot(name='content')
      .form-footer.px-12.pt-5(v-if='showFooter')
        slot(name='footer-content')
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    showFooter:{
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.second-container {
  background: #F4F9F7;;
  min-height: 100vh;
  position: relative;
  padding-bottom: 75px;
  .form-title {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    color: #1f3333;
  }
  .form-footer{
    height: 75px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    border: 2px solid #F2F3F2;
  }
  .v-toolbar__content{
    padding: 0;
  }
}
</style>
