<template>
  <div class="fill-height">
    <login v-if="!Boolean(user)" />
    <init v-else />
  </div>
</template>

<script>
import Login from './Login'
import Init from './Main'
export default {
  components: { Login, Init },
  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },
  
}
</script>

<style></style>
