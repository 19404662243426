import Axios from 'axios';
import utilities from './utilities'
export default {
  state:{
    finalProducts: [],
  },
  mutations:{
    
    setFinalProducts(state, products){
      state.finalProducts = products
    }
  },
  actions: {
    async listFinalProducts({commit}, search) {
      let data = await utilities.wrapResponseAndGetData(await Axios.get("v1/admin/final-products"))
      commit('setFinalProducts', data)
      return data
    },
    async createFinalProduct({},product){
      await Axios.post("v1/admin/final-products",product)
    },
    async editFinalProduct({},product){
      await Axios.put(`v1/admin/final-products`,product)
    },
    async deleteProduct({},data){
      await Axios.delete(`v1/admin/final-products/${data.id}`)
    },
    async findFinalProduct({}, id){
      return utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/final-products/${id}`)) 
    }
  },
};
