<template lang="pug">
v-container.px-8
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title(v-if="!generalLoading")
          v-btn(icon text @click="$router.push('/corporativo/empresas')")
              v-icon mdi-chevron-left
          | Pickers
          
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row(wrap)
    v-col.d-flex.justify-space-between.align-center(cols='12')
      v-text-field(hide-details style='width:450px; display:inline-block;' v-model="text" outlined label='Buscar picker...')
      v-spacer 
      v-btn(color="primary" @click="$refs.actionDrawer.open('create')") Agregar picker
    v-col(cols='12')
      v-data-table(:headers='headers' :items='items' :loading='loadingTable' locale='es-ES' no-data-text='Aún no hay pickers registrados' loading-text="'Cargando...'")
        template(v-slot:[`item.fullname`]='{ item }')
          span {{ item.first_name }} {{ item.last_name }}
        template(v-slot:[`item.auth_type`]='{ item }')
          span {{ $enums.AUTH_TYPE_PARSER(item.auth_type) }}
        template(v-slot:[`item.auth_data`]='{ item }')
          span(v-if="item.auth_type === 'email'") {{ item.email}}
          span(v-if="item.auth_type === 'username'") {{ item.username }}
        template(v-slot:[`item.user_role`]='{ item }')
          span(v-if="item.businesses.length>0") {{ parsePermissions(item.businesses[0].permissions) }}
        template(v-slot:[`item.actions`]='{ item }')
          v-menu(offset-y='')
            template(v-slot:activator='{ on, attrs }')
              v-btn(elevation='0' icon small='' v-bind='attrs' v-on='on')
                v-icon(color='#1F3333') mdi-dots-vertical
            v-list
              v-list-item(@click="openEdit(item)")
                v-list-item-title Editar
              v-list-item(@click="openChangePwd(item)")
                v-list-item-title Cambiar contraseña
              v-list-item(@click='moveToUsers(item)')
                v-list-item-title Eliminar
              
  dialog-info(ref='dialogInfo') 
  action-picker-user-drawer(ref='actionDrawer' @done='loadItems')
  change-user-password(ref="changeUserPassword" )
</template>

<script>
import ActionPickerUserDrawer from '../../components/admin/business/ActionPickerUserDrawer.vue'
import ChangeUserPassword from '../../components/admin/business/ChangeUserPassword.vue'
import DialogConfirm from '../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../components/shared/DialogInfo.vue'

export default {
  components: { DialogInfo, DialogConfirm, ActionPickerUserDrawer, ChangeUserPassword },
  data () {
    return {
      loading: false,
      generalLoading: false,
      dataTableOptions: {},
      loadingTable: false,
      items: [],
      toDelete: null,
      totalItems: 0,
      business: {},
      headers: [
        {
          text: 'Nombre completo',
          align: 'start',
          sortable: false,
          width: '250px',
          value: 'fullname',
          class: 'primary--text'
        },
        {
          text: 'Tipo ingreso',
          align: 'start',
          sortable: false,
          value: 'auth_type',
          class: 'primary--text'
        },
        {
          text: 'Email/Usuario',
          align: 'start',
          sortable: false,
          value: 'auth_data',
          class: 'primary--text'
        },
        {
          text: 'Rol',
          align: 'start',
          sortable: false,
          value: 'user_role',
          class: 'primary--text'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'actions',
          class: 'primary--text'
        }
      ],
      text: '',
    }
  },
  methods: {
    openEdit (item) {
      this.$refs.actionDrawer.open('edit',item)
    },
    parsePermissions(permissions){
      return permissions.map((i)=>this.$enums.USER_ROLES_PARSER(i)).join(',')
    },
    openChangePwd(item){
      this.$refs.changeUserPassword.open(item)
    },
    async loadItems (firstLoad) {
      if (!this.loadingTable) {
        this.loadingTable = true
        try {
          this.loadingTable = true
          let result = await this.$store.dispatch('getAuthUsers', 'picker')
          this.items = result
          this.loadingTable = false
          this.generalLoading = false
        } catch (error) {
          this.$refs.dialogInfo.open(
            'No se pudo listar los pickers',
            error.response.data.error_message
          )
          this.loadingTable = false
          this.generalLoading = false
        }
      }
    }
  },
  async beforeMount () {
    this.generalLoading = true
    await this.loadItems(true)
    this.generalLoading = false
  }
}
</script>

<style lang="scss">
.customer-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>
