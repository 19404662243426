<template lang="pug">
  v-container.px-10.category-view(fluid='')
    v-overlay(color='white' :value='generalLoading')
      v-progress-circular(color='primary' indeterminate='' size='64')
    v-row(wrap='')
      v-col(cols='12')
        v-toolbar(flat='')
          v-toolbar-title.title
            span.ml-4 Proveedores
          v-spacer
          v-btn( color='primary' v-if="hasSectionPermissions('edit')" elevation='4' @click='moveToCreate') CREAR PROVEEDOR
        v-divider(style='border-color: #B9C3C2;')
      v-col.d-flex.justify-space-between.align-center(cols='12' v-if="hasSectionPermissions('list')")
        v-row
          v-col
            v-text-field(v-debounce:200ms="rebote" label="Buscar por razón social o RUC" append-icon="mdi-magnify"  outlined hide-details)
          v-col
            v-select(outlined multiple label="Productos" no-data-text="No tiene productos agregados" :items="products" item-text="name"  @change="loadItems"  v-model="search.product_ids"   item-value="id")
        
      v-col(cols='12' v-if="hasSectionPermissions('list')")
        v-data-table(:options.sync='dataTableOptions' :headers='headers' :server-items-length='totalItems' :items='items' :loading='loadingTable' @update:page='updatePage' @update:items-per-page='updateItems' locale='es-ES' @click:row='clickOnRow' no-data-text='Aún no ha creado un proveedor' loading-text='Cargando...' :footer-props="{\
          'items-per-page-text': 'Proveedores por página',\
          'items-per-page-options': [20, 50, 100],\
          }")
          template(v-slot:[`item.cellphone`]='{ item }')
            .text-decoration-underline.primary--text.hover(@click="openWhatsapp(item)") {{item.cellphone}}
          template(v-slot:[`item.status`]='{ item }')
            span {{item.status | __normalizeKeyValue}}
          template(v-slot:[`item.origin`]='{ item }')
            span {{item.origin | __normalizeKeyValue}}
          template(v-slot:[`item.actions`]='{ item }')
            v-btn(icon='' v-if="hasSectionPermissions('edit')" @click.stop='openEdit(item)')
              v-icon mdi-pencil
            v-btn(icon='' v-if="hasSectionPermissions('delete')" @click.stop='openDeleteDialog(item)')
              v-icon mdi-delete
    dialog-info(ref='dialogInfo')
    dialog-confirm(@actionPressed='confirmDelete' ref='dialogConfirm')

</template>

<script>
import DialogConfirm from "../../../components/shared/DialogConfirm.vue";
import DialogInfo from "../../../components/shared/DialogInfo.vue";
export default {
  components: { DialogInfo, DialogConfirm },
  computed:{
    products(){
      return this.$store.state.products.productsSimple
    }
  },
  data() {
    return {
      loading: false,
      generalLoading: false,
      dataTableOptions: {},
      loadingTable: false,
      items: [],
      toDelete: null,
      totalItems: 0,
      headers: [
        {
          text: "Razon social",
          align: "start",
          sortable: false,
          value: "razon_social",
          class: "primary--text",
        },
        {
          text: "Número de Documento",
          align: "start",
          sortable: false,
          value: "doc_number",
          class: "primary--text",
        },
        {
          text: "Celular",
          align: "start",
          sortable: false,
          value: "cellphone",
          class: "primary--text",
        },
        {
          text: "Correo electrónico",
          align: "start",
          sortable: false,
          value: "email",
          class: "primary--text",
        },
        {
          text: "Estado",
          align: "start",
          sortable: false,
          value: "status",
          class: "primary--text",
        },
        {
          text: "Fuente",
          align: "start",
          sortable: false,
          value: "origin",
          class: "primary--text",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "actions",
          class: "primary--text",
        },
      ],
      search: {
        text: "",
        product_ids: [],
        docsPerPage: 20,
        page: 1,
      },
    };
  },
  methods: {
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('proveedores',permissions)
    },
    updatePage(value) {
      this.search.page = value;
      this.loadItems();
    },
    updateItems(items) {
      this.search.docsPerPage = items;
      this.loadItems();
    },
    clickOnRow(item) {
      this.$router.push({
        path: `/proveedores/${item.id}`,
        query: { action: this.$enums.ACTIONS.SHOW },
      });
    },
    openEdit(item) {
      this.$router.push({
        path: `/proveedores/${item.id}`,
        query: { action: this.$enums.ACTIONS.EDIT },
      });
    },
    async confirmDelete() {
      try {
        this.generalLoading = true;
        await this.$store.dispatch("removeSupplier", this.toDelete);
        this.$refs.dialogConfirm.close();
        await this.loadItems()
        this.$toast.success("Proveedor eliminado satisfactoriamente");
        this.generalLoading = false
      } catch (error) {
        this.generalLoading = false
        this.$refs.dialogInfo.open(
          "Error eliminando proveedor",
          error.response.data.error_message
        );
      }
    },
    openDeleteDialog(item) {
      this.$refs.dialogConfirm.open(
        "Eliminar proveedor",
        "Si elimina el proveedor su información se borrará permanentemente del sistema."
      );
      this.toDelete = item;
    },
    openWhatsapp(supplier) {
      window.open(`https://wa.me/51${supplier.cellphone}`, "_blank");
    },
    openMail(supplier) {
      window.open(`mailto:${supplier.email}`, "_blank");
    },
    moveToCreate() {
      this.$router.push({
        path: `/proveedores/${this.$uid()}`,
        query: { action: this.$enums.ACTIONS.CREATE },
      });
    },
    async rebote(text) {
      this.search.text = text;
      await this.loadItems();
    },
    async loadItems(firstLoad) {
      if (!this.loadingTable) {
        try {
          this.loadingTable = true;
          this.items = [];
          let result = await this.$store.dispatch("listSuppliers", {
            text: this.search.text,
            product_ids: this.search.product_ids.join(","),
            docsPerPage: this.search.docsPerPage,
            page: this.search.page,
          });
          this.totalItems = result.total_items;
          this.items = result.items;
          this.loadingTable = false;
          this.generalLoading = false;
        } catch (error) {
          this.$refs.dialogInfo.open(
            "No se pudo listas los proveedores",
            error.response.data.error_message
          );
          this.loadingTable = false;
          this.generalLoading = false;
        }
      }
    },
  },
  async beforeMount() {
    this.generalLoading = true;
    await this.$store.dispatch("loadProductsSimple");
    await this.loadItems();
    this.generalLoading = false;
  },
};
</script>

<style lang="scss">
.category-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>
